import http from "../baseURL/httpcommon";
import authHeader from "../baseURL/authHeader";

const getNotification = (workforceId) => {
  return http.get(`/notification/worker/${workforceId}`, {
    headers: {
      ...authHeader(),
    },
  });
};
const NotificaionServices = {
  getNotification,
};

export default NotificaionServices;
