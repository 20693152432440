import { useEffect, useState } from "react";
import PlanApi from "./services";
import { Button, Form, Modal } from "react-bootstrap";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import {
  isValidName,
  isValidNumber,
  isValidPhoneNo,
} from "../../../../helper/regex";

const AdminPlans = () => {
  const [getPricePlane, setGetPricePlane] = useState([]);
  const [changesMade, setChangesMade] = useState(true);

  const [load, setLoad] = useState(false);
  function getPlane() {
    PlanApi.pricePlann()
      .then((respon) => {
        console.log(respon.data.data);
        setGetPricePlane(respon.data.data);
        setLoad(true);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  useEffect(() => {
    getPlane();
  }, []);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");

  const handleClosemessage = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const dataJson = {
    planName: "",
    price: "",
    priceOfPlan: "",
    shareCount: "",
    validityDays: "",
  };

  const [show, setShow] = useState(false);
  const [planData, setPlanData] = useState(dataJson);
  const handleClose = () => setShow(false);

  function handleChange(e) {
    debugger;
    const { name, value } = e.target;
    // Function to validate input based on field name
    const isValidInput = (name, value) => {
      switch (name) {
        case "planName":
          return isValidName(value);
        case "price":
        case "priceOfPlan":
        case "shareCount":
        case "validityDays":
          return isValidNumber(value);
        default:
          return true; // If no specific validation, consider it valid
      }
    };

    // Validate the input and return early if invalid
    if (!isValidInput(name, value)) {
      return;
    }
    setPlanData({
      ...planData,
      [name]: value,
    });
    setChangesMade(false);
  }

  const handleShow = (data) => {
    setPlanData(data);
    setShow(true);
    setTimeout(() => {
      console.log(planData);
    }, 500);
  };

  function updateData() {
    console.log(planData);
    PlanApi.updatepricePlann(planData.planId, planData).then((respon) => {
      console.log(respon);
      if (respon.data.status == true) {
        setOpen(true);
        setMessage(respon.data.message);
        setSeverity("success");
        setShow(false);
        getPlane();
        setChangesMade(true);
      }
    });
  }

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevents the default form submission behavior

    const form = event.currentTarget;

    // Checking form validity
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      // Checking if all required fields are filled
      if (
        planData?.planName?.trim() !== "" &&
        planData.price !== "" &&
        planData.priceOfPlan !== "" &&
        planData.shareCount !== "" &&
        planData.validityDays !== ""
      ) {
        updateData();
      } else {
        setOpen(true);
        setMessage("Fill all required fields");
        setSeverity("error");
      }
    }

    setValidated(true);
  };

  return (
    <>
      <div className="card radius-10 p-4 px-3">
        <div className="card-header bg-transparent border-0">
          <div className="row g-3 align-items-center">
            <div className="col-6 col-md-6 col-lg-6">
              <h5 className="mb-0">Plans</h5>
            </div>
            <div className="col-6 col-md-6 col-lg-6 text-right"></div>
          </div>
        </div>
        <div className="card-body">
          {getPricePlane.map((item) => (
            <div className="row g-3 align-items-center">
              <div className="col-12 col-md-7 col-lg-7">
                <div className="border p-4 mb-4 border-20">
                  <div className="d-flex mb-3 xs-block">
                    <label className="form-label text-dark font-18 xs-block p_0">
                      {item.planName}
                    </label>{" "}
                    <span className="text-gray font-14 ps-4 xs-block p_0">
                      ({item.shareCount} User free)
                    </span>
                    <div className="ms-auto xs-block p_0">
                      <span className="mb-0 font-18">${item.price}</span>
                      <span className="text-gray font-14">/month</span>
                    </div>
                  </div>
                  <div className="d-flex mb-0 mt-3">
                    <button
                      type="button"
                      className="btn btn-primary py-1 font-14"
                      onClick={() => handleShow(item)}
                    >
                      Edit Plan
                    </button>
                    {/* <button type="button" className="btn btn-danger py-1 font-14 ms-4">Delete Plan</button> */}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated}>
            <Form.Group controlId="validationCustom01" className="mb-3">
              <Form.Label>Plan name</Form.Label>
              <Form.Control
                required
                type="text"
                name="planName"
                maxLength={25}
                value={planData.planName}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Enter plan name
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCustom01" className="mb-3">
              <Form.Label>Price ($)</Form.Label>
              <Form.Control
                required
                type="text"
                name="price"
                maxLength={5}
                value={planData.price}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Enter price
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCustom01" className="mb-3">
              <Form.Label>Price of Plan</Form.Label>
              <Form.Control
                required
                type="text"
                maxLength={5}
                name="priceOfPlan"
                value={planData.priceOfPlan}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Enter plan price
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCustom01" className="mb-3">
              <Form.Label>Share Count</Form.Label>
              <Form.Control
                required
                type="text"
                name="shareCount"
                maxLength={6}
                value={planData.shareCount}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Enter share count
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCustom01" className="mb-3">
              <Form.Label>Validity Days</Form.Label>
              <Form.Control
                required
                type="text"
                name="validityDays"
                maxLength={3}
                value={planData.validityDays}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Enter validity date
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            disabled={changesMade}
            variant="primary"
            onClick={handleSubmit}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleClosemessage}
        >
          <Alert
            onClose={handleClosemessage}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default AdminPlans;
