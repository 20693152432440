import { Form } from "react-bootstrap";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import SettingApi from "./services";
import { useEffect, useState } from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
const LanguagePreference = () => {
  const { t } = useTranslation();
  const [validated, setValidated] = useState(false);
  function changeLang(e) {
    localStorage.setItem("lang", e);
    i18n.changeLanguage(localStorage.getItem("lang"));
  }
  return (
    <>
      <div className="card radius-10 p-4 px-3">
        <div className="card-header bg-transparent border-0">
          <div className="row g-3 align-items-center">
            <div className="col-12 col-md-12 col-lg-12">
              <h5 className="mb-0">{t("Developer Setting")}</h5>
            </div>
          </div>
        </div>
        <div className="card-body profile">
          <Form noValidate validated={validated}>
            <div className="row">
              <div className="col-12 col-md-4 col-lg-3 pt-2">
                <label>{t("Language Preference")}</label>
              </div>
              <div className="col-12 col-md-4 col-lg-4 py-2">
                <Form.Select
                  aria-label="Default select example"
                  //value={}
                  onClick={(e) => changeLang(e.target.value)}
                >
                  <option
                    value="en"
                    selected={localStorage.getItem("lang") == "en"}
                  >
                    English
                  </option>
                  <option
                    value="fr"
                    selected={localStorage.getItem("lang") == "fr"}
                  >
                    French
                  </option>
                  <option
                    value="se"
                    selected={localStorage.getItem("lang") == "se"}
                  >
                    Swedish
                  </option>
                </Form.Select>
              </div>
            </div>
          </Form>
        </div>
      </div>
      {/* <Stack spacing={2} sx={{ width: "100%" }}>
                <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    onClose={handleClose}
                >
                    <Alert
                        onClose={handleClose}
                        severity={severity}
                        sx={{ width: "100%" }}
                    >
                        {message}
                    </Alert>
                </Snackbar>
            </Stack> */}
    </>
  );
};

export default LanguagePreference;
