import http from "../../../../baseURL/httpcommon";
import authHeader from "../../../../baseURL/authHeader";

const getWorkerList = (supervisorId) => {
  return http.get(`/workforce/worker/list/${supervisorId}`);
};

const getWorkerTasksList = (
  workforceId,
  userId,
  priority,
  pageNumber,
  pageSize
) => {
  return http.get(
    `/task/worker/list?workforceId=${workforceId}&userId=${userId}&eTaskStatus=${priority}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=id&sortDir=desc`
  );
};
const WorkerServices = {
  getWorkerList,
  getWorkerTasksList,
};

export default WorkerServices;
