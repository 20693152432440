import { useEffect } from "react";
import CompaniesApi from "./services";
import { useState } from "react";
import { Button, Chip, Switch } from "@mui/material";
import ServerSideTable from "../../../common components/serverSideTable";
import { Link as RouterLink } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import ViewDetails from "./viewDetails";
import { t } from "i18next";

const AdminCompanies = () => {
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState("");
  const [data, setData] = useState([]);
  const [viewRequestsData, setViewRequestsData] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");

  function getCompanies(e) {
    setLoading(true);
    if (e !== undefined) {
      console.log("getUsers", e, page, pageSize);
      //   console.log("getUsers", page, pageSize)
      setPage(e.page == undefined ? 0 : e.page);
      setPageSize(e.pageSize == undefined ? 10 : e.pageSize);
    }
    CompaniesApi.getCompanies(page, pageSize).then((respons) => {
      const { totalPages, totalElements, currentPage, content } =
        respons.data.data;
      // const updatedContent = content.map((company) => {
      //   return { ...company, id: company.companyId };
      // });
      setCount(totalPages);
      setTotalItems(totalElements);
      setData(content);
      setLoading(false);
    });
  }

  function viewRequest() {
    CompaniesApi.viewRequests().then((respons) => {
      setViewRequestsData(respons.data.data);
    });
  }

  useEffect(() => {
    getCompanies();
    viewRequest();
  }, []);
  useEffect(() => {
    getCompanies();
  }, [page, pageSize]);

  const columns = [
    { field: "companyId", headerName: "Company Id", width: 100 },
    { field: "companyName", headerName: "Company Name", width: 150 },
    { field: "email", headerName: "Email Address", width: 200 },
    {
      field: "currentPaymentStatus",
      headerName: "Payment Status",
      width: 120,
      renderCell: (param) => {
        return (
          <Chip
            label={param.row.currentPaymentStatus}
            color={
              param.row.currentPaymentStatus == "RECEIVED"
                ? "success"
                : param.row.currentPaymentStatus == "PENDING"
                ? "warning"
                : param.row.currentPaymentStatus == "CANCELLED"
                ? "error"
                : null
            }
          />
        );
      },
    },
    {
      field: "registrationNo",
      headerName: "Registration No",
      width: 120,
      align: "center",
      headerAlign: "center",
      renderCell: (param) => {
        return param.row.registrationNo === null
          ? "-"
          : param.row.registrationNo;
      },
    },
    {
      field: "taxAgencyNo",
      headerName: "Tax Agency No",
      width: 120,
      align: "center",
      headerAlign: "center",
      renderCell: (param) => {
        console.log("params", param);
        return param.row.taxAgencyNo === null ? "-" : param.row.taxAgencyNo;
      },
    },
    {
      field: "active",
      headerName: "Is Enabled?",
      width: 100,
      renderCell: (param) => {
        function changeStatus(email, event) {
          console.log("status", !event);
          CompaniesApi.changeCompanyStatus(email, event).then((respons) => {
            setOpen(true);
            setMessage(respons.data.message);
            setSeverity("success");
            getCompanies();
          });
        }
        return (
          <Switch
            lable="test"
            color="success"
            checked={param.row.active}
            onChange={(e) => changeStatus(param.row.email, e.target.checked)}
          />
        );
      },
    },
    {
      field: "view",
      headerName: "Action",
      renderCell: (param) => {
        return <ViewDetails data={param.row} />;
      },
      width: 120,
    },
  ];

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-12 col-xl-12">
          <div className="card radius-10 w-100">
            <div className="card-body px-4">
              <div className="row row-cols-1 row-cols-lg-2 g-3 align-items-center pb-3 mb-3">
                <div className="col-12 col-lg-6 col-xl-6">
                  <h5 className="mb-0 font-18">{t("Companies")}</h5>
                </div>
                <div className="col-12 col-lg-6 col-xl-6 text-right">
                  <Button
                    variant="outlined"
                    component={RouterLink}
                    to="/dashboard/admin/companies/viewrequests"
                    state={{ data: viewRequestsData }}
                    load={viewRequest}
                  >
                    <span className="badge bg-danger rounded-circle me-2">
                      {viewRequestsData.length}
                    </span>{" "}
                    {t("View Requests")}
                  </Button>
                </div>
              </div>
              <ServerSideTable
                pageSize={10}
                page={0}
                count={count}
                functionName={getCompanies}
                data={data}
                columns={columns}
                loading={loading}
                idname="companyId"
              />
            </div>
          </div>
        </div>
      </div>

      {/* <div
        className="modal fade"
        id="exampleModal2"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body font-20 p-0 text-dark">
              <div className="row">
                <div className="col-12 col-lg-12 col-xl-12 mb-3">
                  <div className="px-4 pt-4 text-center">
                    <p className="font-20 text-dark  text-center">
                      Company Details
                    </p>
                    <img src="/assets/images/imgred.png" />
                  </div>
                  <div className="px-lg-4 pt-5 px-3">
                    <div className="row">
                      <div className="col-md-6 col-12  mb-3">
                        <div className="border-bottom2 height-50">
                          <span className="font-14 text-dark w_50">
                            {" "}
                            Company name
                          </span>
                          <span className="font-14 text-gray text-right w_50">
                            {" "}
                            Mitaway AB
                          </span>
                        </div>
                      </div>
                      <div className="col-md-6 col-12  mb-3">
                        <div className="border-bottom2 height-50">
                          <span className="font-14 text-dark w_50">
                            {" "}
                            Phone number
                          </span>
                          <span className="font-14 text-gray text-right w_50">
                            {" "}
                            03145672929
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-12  mb-3">
                        <div className="border-bottom2 height-50">
                          <span className="font-14 text-dark w_50">
                            {" "}
                            Corporate Reg no.
                          </span>
                          <span className="font-14 text-gray text-right w_50">
                            {" "}
                            778892
                          </span>
                        </div>
                      </div>
                      <div className="col-md-6 col-12  mb-3">
                        <div className="border-bottom2 height-50">
                          <span className="font-14 text-dark w_50"> Email</span>
                          <span className="font-14 text-gray text-right w_50">
                            {" "}
                            John Doe@yahoo.com
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-12  mb-3">
                        <div className="border-bottom2 height-50">
                          <span className="font-14 text-dark w_50">
                            {" "}
                            Tax Agency no.
                          </span>
                          <span className="font-14 text-gray text-right w_50">
                            {" "}
                            778892
                          </span>
                        </div>
                      </div>
                      <div className="col-md-6 col-12  mb-3">
                        <div className="border-bottom2 height-50">
                          <span className="font-14 text-dark w_50">
                            {" "}
                            Password
                          </span>
                          <span className="font-14 text-gray text-right w_50">
                            {" "}
                            ********
                          </span>
                        </div>
                      </div>
                    </div>
                    <label for="exampleDataList" className="form-label mb-3">
                      Requested Package
                    </label>
                    <div className="row m">
                      <div className="col-md-6 col-12  mb-3">
                        <div className="border-bottom2 height-50">
                          <span className="font-14 text-dark w_50">
                            {" "}
                            Startup Plan
                          </span>
                          <span className="font-14 text-gray text-right w_50">
                            {" "}
                            778892
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default AdminCompanies;
