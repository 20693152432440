import { jwtDecode } from "jwt-decode";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const SideNav = () => {
  const token = localStorage.getItem("token");
  const jwt = token ? jwtDecode(token) : null;
  //const jwt = jwtDecode(localStorage.getItem("token"));
  const role = jwt?.role[0]?.name;
  const menuSetting = useRef(false);
  const { t } = useTranslation();
  useEffect(() => {
    if (!menuSetting.current) {
      var script = document.createElement("script");
      var menuCode = `$(document).ready(function(){
        $("#menu").metisMenu()
      });`;
      script.innerHTML = menuCode;
      document.body.appendChild(script);
      menuSetting.current = true;
    }
  }, []);

  const workerMenu = [
    {
      path: "/dashboard/supervisor/home",
      title: t("Home"),
      icon: "bi bi-house-door",
    },

    {
      path: "/dashboard/supervisor/tasks",
      title: t("Tasks"),
      icon: "fadeIn animated bx bx-file",
    },
    //report navigation
    {
      path: "",
      title: t("Reports"),
      icon: "fadeIn animated bx bx-spreadsheet",
      child: [
        {
          path: "/dashboard/supervisor/taskreport",
          title: t("Task Reports"),
          icon: "bi bi-journal-check",
        },
        {
          path: "/dashboard/supervisor/generalreport",
          title: t("General Reports"),
          icon: "bi bi-file-earmark-text",
        },
      ],
    },
    {
      path: "/dashboard/supervisor/workers",
      title: t("Workers"),
      icon: "fadeIn animated bx bx-user-plus",
    },
    {
      path: "/dashboard/supervisor/timesheet",
      title: t("Timesheet"),
      icon: "fadeIn animated bx bx-time-five",
    },
    {
      path: "/dashboard/supervisor/request",
      title: t("Requests"),
      icon: "fadeIn animated bx bx-bell",
    },
    {
      path: "/dashboard/supervisor/settings",
      title: t("Settings"),
      icon: "lni lni-cog",
    },
    {
      path: "/dashboard/supervisor/messages",
      title: t("Messages"),
      icon: "fadeIn animated bx bx-message-square-detail",
    },
    {
      path: "/dashboard/supervisor/myProfile",
      title: t("My Profile"),
      icon: "fadeIn animated bx bx-user-circle",
    },

    {
      path: "/supervisor",
      title: t("Logout"),
      icon: "fadeIn animated bx bx-log-out-circle",
    },
  ];

  const companyMenu = [
    {
      path: "/dashboard/company/home",
      title: t("Home"),
      icon: "bi bi-house-door",
    },
    {
      path: "/dashboard/company/sites",
      title: t("Sites"),
      icon: "fadeIn animated bx bx-buildings",
    },
    // {
    //   path: "/",
    //   title: "Billing and Payment",
    //   icon: "fadeIn animated bx bx-credit-card",
    // },
    {
      path: "/dashboard/company/wokers",
      title: t("Workers"),
      icon: "fadeIn animated bx bx-user-plus",
    },
    // {
    //   path: "/",
    //   title: "NFC Cards",
    //   icon: "fadeIn animated bx bx-mobile",
    // },
    // {
    //   path: "/dashboard/company/reports",
    //   title: "Reports",
    //   icon: "fadeIn animated bx bx-spreadsheet",
    // },
    {
      path: "/dashboard/company/messages",
      title: "Messages",
      icon: "fadeIn animated bx bx-message-square-detail",
    },
    // {
    //   path: "/dashboard/company/subscriptions",
    //   title: "Subscriptions",
    //   icon: "fadeIn animated bx bx-file-blank",
    // },
    {
      path: "/dashboard/company/requests",
      title: t("Requests"),
      icon: "fadeIn animated bx bx-bell",
    },
    {
      path: "/dashboard/company/workforce",
      title: t("Workforce"),
      icon: "lni lni-users",
    },
    {
      path: "/dashboard/company/settings",
      title: t("Settings"),
      icon: "lni lni-cog",
    },
    {
      path: "/dashboard/company/myProfile",
      title: t("My Profile"),
      icon: "fadeIn animated bx bx-user-circle",
    },
    // {
    //   path: "/",
    //   title: "Logout",
    //   icon: "fadeIn animated bx bx-log-out-circle",
    // },
  ];

  const adminMenu = [
    {
      path: "/dashboard/admin/home",
      title: t("Home"),
      icon: "bi bi-house-door",
    },
    {
      path: "/dashboard/admin/companies",
      title: t("Companies"),
      icon: "fadeIn animated bx bx-buildings",
    },
    {
      path: "/dashboard/admin/plans",
      title: t("Plans"),
      icon: "fadeIn animated bx bx-file-blank",
    },
    // {
    //   path: "/",
    //   title: t("Messages"),
    //   icon: "fadeIn animated bx bx-message-square-detail",
    // },
    // {
    //   path: "/",
    //   title: t("Billing and Payment"),
    //   icon: "fadeIn animated bx bx-credit-card",
    // },

    {
      path: "",
      title: t("Settings"),
      icon: "lni lni-cog",
      child: [
        {
          path: "/dashboard/admin/settings/smtp",
          title: "SMTP",
        },
        {
          path: "/dashboard/admin/settings/SettingCompany",
          title: t("Company Setting"),
        },
        {
          path: "/dashboard/admin/settings/devloperSetting",
          title: t("Developer Setting"),
        },
        // {
        //   path: "/dashboard/admin/settings/dateFormat",
        //   title: t("Date Format"),
        // },
        {
          path: "/dashboard/admin/settings/language",
          title: t("Language Preference"),
        },
        {
          path: "/dashboard/admin/settings/appSetting",
          title: t("Application Settings"),
        },
        {
          path: "/dashboard/admin/settings/workerskill",
          title: "Worker Skills",
        },
      ],
    },

    // {
    //   path: "/",
    //   title: "Logout",
    //   icon: "fadeIn animated bx bx-log-out-circle",
    // },
  ];
  return (
    <>
      {jwt !== null ? (
        <aside className="sidebar-wrapper" data-simplebar="true">
          <div className="sidebar-header">
            <div>
              <img
                src="/assets/images/brand-logo-2.png"
                className="logo-icon"
                alt="logo icon"
              />
            </div>
          </div>

          <ul className="metismenu" id="menu">
            {role === "ROLE_SUPERADMIN"
              ? adminMenu.map((item, i) => (
                  <li key={i}>
                    {item.child && item.child.length > 0 ? (
                      <a href="javascript:;" className="has-arrow">
                        <div className="parent-icon">
                          <i className={item.icon}></i>
                        </div>
                        <div className="menu-title">{item.title}</div>
                      </a>
                    ) : (
                      <NavLink to={item.path}>
                        <div className="parent-icon">
                          <i className={item.icon}></i>
                        </div>
                        <div className="menu-title">{item.title}</div>
                      </NavLink>
                    )}

                    {item.child ? (
                      <ul>
                        {item.child.map((child) => (
                          <li key={child.path}>
                            <NavLink to={child.path}>{child.title}</NavLink>
                          </li>
                        ))}
                      </ul>
                    ) : null}
                  </li>
                ))
              : role === "ROLE_ADMIN"
              ? companyMenu.map((item, i) => (
                  <li key={i}>
                    <NavLink to={item.path}>
                      <div className="parent-icon">
                        <i className={item.icon}></i>
                      </div>
                      <div className="menu-title">{item.title}</div>
                    </NavLink>
                  </li>
                ))
              : role === "ROLE_WORKER" || role === "ROLE_SUPERVISOR"
              ? workerMenu.map((item, i) => (
                  <li key={i}>
                    {item.child && item.child.length > 0 ? (
                      <a href="javascript:;" className="has-arrow">
                        <div className="parent-icon">
                          <i className={item.icon}></i>
                        </div>
                        <div className="menu-title">{item.title}</div>
                      </a>
                    ) : (
                      <NavLink to={item.path}>
                        <div className="parent-icon">
                          <i className={item.icon}></i>
                        </div>
                        <div className="menu-title">{item.title}</div>
                      </NavLink>
                    )}

                    {item.child ? (
                      <ul>
                        {item.child.map((child) => (
                          <li key={child.path}>
                            <NavLink to={child.path}>
                              <div className="parent-icon">
                                <i
                                  className={child.icon}
                                  style={{
                                    fontFamily: "boxicons !important",
                                    marginLeft: "10px",
                                    height: "20px",
                                  }}
                                ></i>
                              </div>
                              <div className="menu-title">{child.title}</div>
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    ) : null}
                  </li>
                ))
              : null}
          </ul>
        </aside>
      ) : null}
    </>
  );
};

export default SideNav;
