import { useEffect, useState } from "react";
import SettingApi from "./services";
import { Form, Modal } from "react-bootstrap";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import {
  truncateDescription,
  truncateName20,
} from "../../../../helper/StringSlicer";
import { isValidName } from "../../../../helper/regex";

const WorkerSkills = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const dataJson = {
    name: "",
    description: "",
    status: true,
  };
  const [data, setData] = useState([]);
  const [updateData, setupdateData] = useState(dataJson);

  function setValue(e) {
    debugger;
    const { name, value } = e.target;

    if (name === "name" && !isValidName(value)) {
      return; // If the input does not match the regex, return early
    }
    setupdateData({
      ...updateData,
      [name]: value,
    });
  }

  function workerskillGet() {
    SettingApi.workerskill().then((respons) => {
      const responsData = respons.data.data;
      setData(responsData);
      console.log(responsData);
    });
  }
  const [show, setShow] = useState(false);

  const handleCloses = () => setShow(false);

  const handleShow = () => {
    setShow(true);
    // setupdateData([]);
    setValidated(false);
  };

  function workSkillUpdate() {
    if (
      updateData?.name?.trim() !== "" &&
      updateData?.description?.trim() !== ""
    ) {
      debugger;
      SettingApi.workerskillUpdate(updateData)
        .then((response) => {
          console.log(response);
          // workerskillGet();
          if (response.data.status == true) {
            setMessage(response.data.message);
            setSeverity("success");
            setData((prevData) => [...prevData, response.data.data]);
            setOpen(true);
            setShow(false);
          }
          setupdateData({
            ...updateData,
            name: "",
            description: "",
          });
        })
        .catch((error) => {
          if (error.response.status == 500) {
            setMessage("Server error");
            setSeverity("error");
            setOpen(true);
          } else {
            console.log("error: - " + error);
            setMessage("Error while adding records");
            setSeverity("error");
            // setOpen(true);
          }
        });
    }
  }

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    debugger;
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      if (updateData.name !== "" && updateData.description !== "") {
        debugger;
        workSkillUpdate();
      } else {
        debugger;
        setMessage("Fill all the require fields");
        setSeverity("error");
        setOpen(true);
      }
    }

    setValidated(true);
  };

  useEffect(() => {
    workerskillGet();
  }, []);
  return (
    <>
      <div class="card radius-10 w-100">
        <div class="card-body px-4">
          <div class="row row-cols-1 row-cols-lg-2 g-3 align-items-center pb-3 mb-3">
            <div class="col-12 col-lg-6 col-xl-6">
              <h5 class="mb-0 font-18">Worker Skills</h5>
            </div>
            <div class="col-12 col-lg-6 col-xl-6 text-right">
              <Button variant="outlined" type="button" onClick={handleShow}>
                Add New Skill
              </Button>
            </div>
          </div>
          <div className="d-flex flex-wrap">
            {data?.map((item) => (
              <div
                class="card radius-15 w-20 mr-5"
                style={{ width: "30.2%", marginRight: "15px" }}
              >
                <div class="card-body border radius-10">
                  <div class="row align-items-center d-flex">
                    <div class="col-12 col-lg-12 col-xl-12">
                      <p class="mb-0 text-left">
                        <b>{item.name}</b>
                      </p>
                      <p className="text-left">
                        {truncateDescription(item.description)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleCloses}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Skill</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated}>
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12 mb-4">
                <Form.Group controlId="validationCustom01">
                  <Form.Label>Skill name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Enter skill name"
                    value={updateData.name}
                    maxLength={35}
                    name="name"
                    onChange={setValue}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter skill name
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-12 col-md-12 col-lg-12 mb-4">
                <Form.Group controlId="validationCustom01">
                  <Form.Label>Skill description</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    as="textarea"
                    placeholder="Enter skill description"
                    value={updateData.description}
                    name="description"
                    maxLength={50}
                    onChange={setValue}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter description
                  </Form.Control.Feedback>
                </Form.Group>
                {/* {updateData.name}
                {updateData.description} */}
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="me-2"
            color="inherit"
            variant="contained"
            onClick={handleCloses}
          >
            Close
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};
export default WorkerSkills;
