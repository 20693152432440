import React, { useEffect, useState, useMemo } from "react";
import { jwtDecode } from "jwt-decode";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import MuiAlert from "@mui/material/Alert";
import ServerSideTable from "../../../common components/serverSideTable";
import { Modal, Button, Form, FormControl, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
// import CircularProgress from "@material-ui/core/CircularProgress";

import TasksServices from "./services";
import MoreMenu from "./moreMenu";
import { CircularProgress } from "@mui/material";

const Index = () => {
  const token = localStorage.getItem("token");
  const decodetoken = jwtDecode(token);
  const supervisorId = decodetoken.id;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [count, setCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalItems, setTotalItems] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [isAssigned, setIsAssigned] = useState("false");
  const [taskType, setTaskType] = useState(true);
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const [errors, setErrors] = useState({
    name: "",
    description: "",
    startDate: "",
    dueDate: "",
    priority: "",
  });
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    startDate: "",
    dueDate: "",
    priority: "",
  });

  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");

  const columns = [
    {
      field: "taskId",
      headerName: t("Task ID"),
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "name",
      headerName: t("Task Name"),
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "etaskStatus",
      headerName: t("Status"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Button
          variant="contained"
          style={{
            backgroundColor:
              params.value === "COMPLETED"
                ? "#008000"
                : params.value === "IN_PROGRESS" || params.value === "PENDING"
                ? "#AFAFAF"
                : "#E5B304",
            width: "100px",
            fontSize: "12px",
          }}
          size="medium"
        >
          {params.value}
        </Button>
      ),
    },
    {
      field: "employeeId",
      headerName: t("Worker ID"),
      flex: 1,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "dueDate",
      headerName: t("Due Date"),
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "priority",
      headerName: t("Priority"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Button
          variant="contained"
          style={{
            backgroundColor:
              params.value === "High"
                ? "#FF0000"
                : params.value === "Low"
                ? "#0ACF83"
                : "#E5B304",
            width: "100px",
            fontSize: "12px",
          }}
          size="small"
        >
          {params.value}
        </Button>
      ),
    },
    {
      field: "",
      width: 55,
      headerAlign: "center",
      align: "center",
      renderCell: (param) => {
        console.log("param".param);
        return (
          <>
            {param && param.row && (
              <MoreMenu
                data={param.row}
                taskId={param.taskId}
                stateProp={taskType}
                load={getAllTasks}
                onActionComplete={handleActionComplete}
              />
            )}
          </>
        );
      },
    },
  ];

  function getCompanies(e) {
    setLoading(true);
    if (e !== undefined) {
      console.log("getUsers", e, page, pageSize);
      //   console.log("getUsers", page, pageSize)
      setPage(e.page == undefined ? 0 : e.page);
      // setPage((prevData) => {
      //   if (prevData !== e.page) {
      //     return e.page == undefined ? 0 : e.page
      //   }

      // });
      setPageSize(e.pageSize == undefined ? 10 : e.pageSize);
    }
  }
  // Filter the columns based on the condition
  const filteredColumns =
    isAssigned === "true"
      ? columns
      : columns.filter((column) => column.field !== "employeeId");

  const setValues = (field, value) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: "",
    }));

    setFormData({
      ...formData,
      [field]: value,
    });
  };

  // Set the message and severity for the SnackBar
  const handleActionComplete = (message, severity) => {
    debugger;
    console.log("action_Message", message);
    setMessage(message);
    setSeverity("success");
    setOpen(true);
  };

  //get all tasks
  const getAllTasks = async () => {
    debugger;
    try {
      setLoading(true);
      const response = await TasksServices?.getTasksList(
        supervisorId,
        isAssigned,
        page,
        pageSize
      );
      const { currentPage, totalPages, content, totalElements } =
        response.data.data;
      setCount(totalPages);
      setTotalItems(totalElements);
      setData(content);
      setLoading(false);
    } catch (error) {
      if (error.response) {
        const { status, data } = error.response;
        if (status === 401 || status === 400) {
          setMessage(data.message);
        } else {
          setMessage("An error occurred while logging in");
        }
      }
      setSeverity("error");
      setOpen(true);
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    getCompanies();
    window.scrollTo(0, 100);
  }, []);

  useEffect(() => {
    debugger;
    // Setting the task type based on isAssigned
    if (isAssigned === "true") {
      setTaskType(false);
    } else {
      setTaskType(true);
    }
    if (page !== undefined) {
      getAllTasks();
    }
  }, [page, pageSize, isAssigned]);

  //------------- Create new task--------------
  // const handleSubmit = (event) => {
  //   const form = event.currentTarget;
  //   if (form.checkValidity() === false) {
  //     event.preventDefault();
  //     event.stopPropagation();
  //   }
  //   handleCreateTask();
  //   setValidated(false);
  // };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    let isValid = true;

    // Add custom validation for name and description fields
    if (!formData.name || formData.name.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: "Name cannot be empty",
      }));
      isValid = false;
    } else {
      setErrors((prevErrors) => {
        const { name, ...rest } = prevErrors;
        return rest;
      });
    }

    if (!formData.description || formData.description.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        description: "Description cannot be empty",
      }));
      isValid = false;
    } else {
      setErrors((prevErrors) => {
        const { description, ...rest } = prevErrors;
        return rest;
      });
    }

    if (isValid) {
      handleCreateTask();
    }

    setValidated(true);
  };

  ///Handle task create
  const handleCreateTask = () => {
    debugger;

    const newTaskData = {
      name: formData.name,
      description: formData.description,
      startDate: formData.startDate,
      dueDate: formData.dueDate,
      priority: formData.priority,
      assigned: true,
    };
    setLoad(true);
    TasksServices.postTaskCreate(newTaskData)
      .then((response) => {
        debugger;
        if (response.status === 200) {
          const data = response.data;
          setMessage(data.message);
          console.log("data_message", data.message);
          setSeverity("success");
          setOpen(true);
          setShow(false);
          setErrors({});
          setFormData({});
          getAllTasks();
          setLoad(false);
        } else if (response.status === 400) {
          setErrors({});
          setOpen(false);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          setMessage(error.response.data.message);
          setSeverity("error");
          setOpen(true);
        } else if (error.response && error.response.status === 400) {
          const errorResponse = error.response.data;
          if (errorResponse.errorState) {
            // Clear any previous errors
            setErrors({});
            console.log(
              "errorResponse_errorState :- " + errorResponse.errorState
            );

            // Handle error messages from the server for each field
            Object.keys(errorResponse.errorState).forEach((key) => {
              const errorMessage = errorResponse.errorState[key][0];
              setErrors((prevErrors) => ({
                ...prevErrors,
                [key]: errorMessage,
              }));
            });
          } else {
            setMessage(errorResponse.message);
            setSeverity("error");
            setOpen(true);
            // Clear any previous errors
            setErrors({});
          }
        }
      })
      .finally(() => {
        // Re-enable the button after API call is complete
        setLoad(false);
        setLoading(false);
      });
  };

  //modal calls
  const handleShowModal = () => {
    setShow(true);
    setFormData({});
    setErrors({});
  };

  //close modal popup and snackmessage when  user click on closebutton

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShow(false);
    setOpen(false);
  };

  return (
    <>
      <div className="card radius-10 p-4 px-3">
        <div className="card-header bg-transparent border-0">
          <div className="row g-3 align-items-center">
            <div className="col-12 col-md-2 col-lg-2">
              <h5 className="mb-0">{t("Active Tasks")}</h5>
            </div>

            <div className="col-12 col-md-7 col-lg-7">
              {/* <form className="searchbar ms-auto me-3">
                <div className="position-absolute top-50 translate-middle-y search-icon ms-3">
                  <i className="bi bi-search"></i>
                </div>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Search for tasks"
                />
              </form> */}
            </div>

            <div className="col-12 col-md-3 col-lg-3 d-flex">
              <div className="d-flex">
                <Form.Select
                  className="outline-secondary"
                  aria-label="Default select example"
                  onChange={(e) => setIsAssigned(e.target.value)}
                >
                  <option value="false">{t("Unassigned")}</option>
                  <option value="true">{t("Assigned")}</option>
                </Form.Select>

                <button
                  className="btn btn-outlineStyled-secondary btn-blue font-14 ms-2"
                  onClick={handleShowModal}
                  type="button"
                >
                  {t("Add")}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body">
          {count > 0 ? (
            <ServerSideTable
              pageSize={10}
              page={0}
              count={count}
              functionName={getCompanies}
              data={data}
              loading={loading}
              columns={filteredColumns}
              idname="taskId"
            />
          ) : (
            // <p>No Task Found</p>
            <ServerSideTable
              pageSize={10}
              page={0}
              count={count}
              functionName={getCompanies}
              data={data}
              loading={loading}
              columns={filteredColumns}
              idname="taskId"
            />
          )}
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header
          className="text-center border-0"
          closeButton
        ></Modal.Header>
        <Modal.Title className="text-center">
          {t("Create New Task")}
        </Modal.Title>
        <Modal.Body>
          <Form noValidate validated={validated}>
            <Form.Group className="mb-3" controlId="formTaskName">
              <Form.Label>{t("Task Name")}</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Enter task name"
                name="name"
                value={formData.name}
                onChange={(e) => setValues("name", e.target.value)}
                isInvalid={!!errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formTaskDescription">
              <Form.Label>{t("Description")}</Form.Label>
              <Form.Control
                required
                as="textarea"
                rows={4}
                placeholder="Enter Description..."
                name="description"
                value={formData.description}
                onChange={(e) => setValues("description", e.target.value)}
                isInvalid={!!errors.description}
              />
              <Form.Control.Feedback type="invalid">
                {errors.description}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formStartDate">
              <Form.Label>{t("Start Date")}</Form.Label>
              <Form.Control
                required
                type="date"
                placeholder="Start Date"
                name="startDate"
                value={formData.startDate}
                onChange={(e) => setValues("startDate", e.target.value)}
                min={new Date().toISOString().split("T")[0]}
                isInvalid={!!errors.startDate}
              />
              <Form.Control.Feedback type="invalid">
                {errors.startDate}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formDueDate">
              <Form.Label>{t("Due Date")}</Form.Label>
              <Form.Control
                required
                error
                type="date"
                placeholder="Due Date"
                name="dueDate"
                value={formData.dueDate}
                onChange={(e) => setValues("dueDate", e.target.value)}
                min={new Date().toISOString().split("T")[0]}
                isInvalid={!!errors.dueDate}
              />
              <Form.Control.Feedback type="invalid">
                {errors.dueDate}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formPriority">
              <Form.Label>{t("Select Priority")}</Form.Label>
              <Form.Select
                required
                name="priority"
                value={formData.priority}
                onChange={(e) => setValues("priority", e.target.value)}
                isInvalid={!!errors.priority}
              >
                <option value="">{t("Select Priority")}</option>
                <option value="Low">Low</option>
                <option value="Medium">Medium</option>
                <option value="High">High</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.priority}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
          <div className="text-center w-75 mx-auto mt-5 mb-4">
            {load ? (
              <CircularProgress />
            ) : (
              <Button
                variant="primary"
                onClick={handleSubmit}
                className="w-100"
                disabled={load} // Disable the button when loading is true
              >
                {t("Create Task")}
              </Button>
            )}
          </div>
        </Modal.Body>
      </Modal>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleClose}
        >
          <MuiAlert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </MuiAlert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default Index;
