import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import HomeApi from "./services";
import { CircularProgress } from "@mui/material";
import { color } from "d3";
const CompanyHome = () => {
  const [totalSupervisors, setTotalSupervisors] = useState("");
  const [totalWorkers, setTotalWorkers] = useState("");
  const [totalSites, setTotalSites] = useState("");
  const [site, setSite] = useState([]);
  const [checkInOutRequests, setCheckInOutRequests] = useState("");
  const [workforces, setWorkforces] = useState("");
  const [load, setLoad] = useState(false);
  const [loadCount, setLoadCount] = useState(false);
  const [siteLoad, setsiteLoad] = useState(false);
  const [checkLoad, setCheckLoad] = useState(false);
  const [workforceLoad, setWorkforceLoad] = useState(false);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [count, setCount] = useState(0);

  const { t } = useTranslation();
  const jwt = jwtDecode(localStorage.getItem("token"));
  const role = jwt.userType;
  const companyId = jwt.companyId;
  const navigate = useNavigate();
  useEffect(() => {
    if (role === "SUPERADMIN") {
      navigate("/dashboard/admin/home");
    } else if (role === "SUPERVISOR") {
      navigate("/dashboard/supervisor/home");
    }
    // else {
    //   navigate("/dashboard/company/home")
    // }
    console.log("Role", role);
  }, []);

  //GET DASHBORAD DATA
  function homeStatics() {
    setLoad(true);
    HomeApi.getCompanyStatics(companyId)
      .then((respons) => {
        console.log("getCompanyStatics", respons.data.data);
        const data = respons.data.data;
        if (data.length > 0) {
          setTotalSupervisors(data.countTotalSupervisors);
          setTotalWorkers(data.countTotalWorkers);
          setTotalSites(data.countTotalSites);
          setLoadCount(1);
        }
        setLoadCount(0);
        setLoad(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setMessage(error.response.data.message);
          setSeverity("error");
          setOpen(true);
        } else if (error.response.status === 500) {
          setMessage(error.response.data.message);
          setSeverity("error");
          setOpen(true);
        } else if (error.response.status === 400) {
          setMessage(error.response.data.message);
          setSeverity("error");
          setOpen(true);
        } else {
          setMessage("Error while getting response");
          setSeverity("error");
          setOpen(true);
        }
      });
  }
  function companySite() {
    setsiteLoad(true);
    HomeApi.getCompanySite(companyId).then((respons) => {
      console.log("companySite", respons.data.data);
      setSite(respons.data.data);
      setsiteLoad(false);
    });
  }
  const handleEditClick = () => {
    // Redirect the user to the desired page
    navigate("/dashboard/company/myProfile");
  };

  useEffect(() => {
    homeStatics();
    companySite();
    getCheckInCheckOutRequests();
    getAllWorkforces();
  }, []);

  //get all checkInOut requests
  const getCheckInCheckOutRequests = () => {
    setCheckLoad(true);
    // for testing set companyId to 53 to get the records
    const companyId = 53;
    HomeApi.AllCheckINOUTRequests(companyId)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.data;
          console.log("resp_data", data);
          setCheckInOutRequests(data);
          if (data.length > 0) {
            setCount(1);
          } else {
            setCount(0);
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setMessage(error.message);
        setSeverity("error");
        setOpen(true);
      });
    setOpen(false);
    setCheckLoad(false);
  };

  //get all the workforces
  const getAllWorkforces = () => {
    setWorkforceLoad(true);
    debugger;
    HomeApi.getAllWorkforces(companyId)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.data.content;
          console.log("worforce_data_content", data);
          setWorkforces(data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setMessage(error.message);
        setSeverity("error");
        setOpen(true);
      });
    setOpen(false);
    setWorkforceLoad(false);
  };

  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-7 col-xl-7">
          <h3 className="text-blue mb-4">
            {t("Hey")}, TAPP ME{" "}
            <span className="font-18 text-gray">
              {t(" Welcome to the portal!")}
            </span>
          </h3>
          <div className="card radius-10 w-100">
            <div className="card-body px-4">
              <div className="row g-3 align-items-center pt-3">
                <div className="col-12 col-md-12 col-lg-12">
                  <h5 className="mb-0">{t("Organization Details")}</h5>
                </div>
              </div>
              <div className="row g-3 align-items-center mt-1">
                <div className="col-9 col-md-9 col-lg-9 d-flex align-items-center">
                  <img
                    className="Rectangle32 mb-2"
                    src="/assets/images/profile.png"
                  />
                  <span className="ps-3">
                    <span className="">TAPP ME</span>
                    <br />
                    <a href="#" className="mt-2">
                      {t("Change Profile picture")}
                    </a>
                  </span>
                </div>
                <div className="col-3 col-md-3 col-lg-3 text-right">
                  <button
                    className="btn btn-outline-secondary btn-blue font-14 toggle"
                    type="button"
                    onClick={handleEditClick}
                  >
                    <i className="fadeIn animated bx bx-pencil"></i> {t("Edit")}
                  </button>
                </div>
              </div>
              <div className="row row-cols-1 row-cols-md-3 row-cols-lg-3 row-cols-xl-3 home-box mt-4">
                <div className="col">
                  <div className="card radius-10">
                    <div className="card-body py-4">
                      <div className="align-items-center">
                        <div>
                          <p className="mb-3 text-white font-14">
                            {t("Total Supervisors")}
                          </p>
                          <h2 className="my-1 text-white">
                            {loadCount > 0 ? (
                              load ? (
                                <CircularProgress color="inherit" />
                              ) : (
                                totalSupervisors
                              )
                            ) : (
                              0
                            )}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card radius-10">
                    <div className="card-body py-4">
                      <div className="align-items-center">
                        <div>
                          <p className="mb-3 text-white font-14">
                            {t("Total workers")}
                          </p>
                          <h2 className="my-1 text-white">
                            {loadCount > 0 ? (
                              load ? (
                                <CircularProgress color="inherit" />
                              ) : (
                                totalWorkers
                              )
                            ) : (
                              0
                            )}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card radius-10">
                    <div className="card-body py-4">
                      <div className="align-items-center">
                        <div>
                          <p className="mb-3 text-white font-14">
                            {t("Total Sites")}
                          </p>
                          <h2 className="my-1 text-white">
                            {loadCount > 0 ? (
                              load ? (
                                <CircularProgress color="inherit" />
                              ) : (
                                totalSites
                              )
                            ) : (
                              0
                            )}{" "}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-5 col-xl-5">
          <div className="card radius-10 w-100">
            <div className="card-body px-4">
              <div className="row  row-cols-1 row-cols-lg-2 g-3 align-items-center pb-3 mb-3">
                <div className="col-lg-8 col-12">
                  <h5 className="mb-0 font-18">
                    {/* {t("Check-In & Check-Out Timings")} */}
                    {t("Workforces")}
                  </h5>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="d-flex align-items-center justify-content-end gap-2 cursor-pointer">
                    <a
                      // href="/dashboard/company/requests"
                      href="/dashboard/company/workforce"
                      className="text-blue font-14 ms-2 mb-0"
                    >
                      {t("View all")}
                    </a>
                  </div>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-12 col-md-7 col-lg-12">
                  <div>
                    {/* {checkLoad ? (
                      <CircularProgress color="inherit" />
                    ) : count > 0 ? (
                      checkInOutRequests.map((value, index) => (
                        <ul className="ps-2 mb-0" key={index}>
                          <li>
                            {value.name}{" "}
                            <span className="text-success">
                              {t("check-in")}
                            </span>{" "}
                            <span className="text-primary">AT {} </span>
                          </li>
                        </ul>
                      ))
                    ) : (
                      <p className="ms-4">No Request Found</p>
                    )} */}
                    {workforceLoad ? (
                      <CircularProgress color="inherit" />
                    ) : workforces.length > 0 ? (
                      workforces.slice(0, 5).map((item, index) => (
                        <p key={index}>
                          <span style={{ color: "#008000" }}>
                            {item.supervisorName}
                          </span>{" "}
                          worker working in{" "}
                          <span style={{ color: "#1E73FF" }}>{item.name}</span>{" "}
                          workforce and{" "}
                          <span style={{ color: "#1E73FF" }}>
                            {item.siteName}
                          </span>{" "}
                          site. <br />
                        </p>
                      ))
                    ) : (
                      <p className="ms-3">No Records Found</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg-7 col-xl-7">
          <div className="card radius-10 w-100">
            <div className="card-body px-4">
              <div className="row row-cols-1 row-cols-lg-2 g-3 align-items-center pb-3 mb-3">
                <div className="col">
                  <h5 className="mb-0 font-18">{t("Construction Sites")}</h5>
                </div>
              </div>
              {siteLoad ? (
                <CircularProgress color="inherit" />
              ) : (
                site.slice(0, 3).map((item, index) => (
                  <div className="card radius-15" key={index}>
                    <div className="card-body border radius-10">
                      <div className="row align-items-center">
                        <div className="col-12 col-lg-8 col-xl-8">
                          <p className="mb-0">{item.siteName}</p>
                        </div>
                        <div className="col-12 col-lg-4 col-xl-4 pxx-2">
                          <button
                            className="btn btn-outline-secondary btn-blue font-14 ms-2 w-100"
                            type="button"
                          >
                            {item.workerCount} worker assigned
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}

              <div className="row">
                <div className="col-12 col-lg-12 col-xl-12 px-2 text-center mt-4">
                  <a
                    href="/dashboard/company/sites"
                    className="text-blue font-14 ms-2 mb-0 cursor-pointer"
                  >
                    {t("View all")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-12 col-lg-5 col-xl-5">
          <div className="card radius-10 w-100">
            <div className="card-body px-4">
              <div className="row row-cols-1 row-cols-lg-2 g-3 align-items-center pb-3 mb-3">
                <div className="col">
                  <h5 className="mb-0 font-18"> {t("Workforces")} </h5>
                </div>
                <div className="col">
                  <div className="d-flex align-items-center justify-content-end gap-3 cursor-pointer">
                    <a
                      href="/dashboard/company/workforce"
                      className="text-blue font-14 ms-2 mb-0"
                    >
                      {t("View all")}
                    </a>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="card radius-15">
                    <div className="card-body border radius-10">
                      {workforceLoad ? (
                        <CircularProgress color="inherit" />
                      ) : workforces.length > 0 ? (
                        workforces.slice(0, 5).map((item, index) => (
                          <p key={index}>
                            <span style={{ color: "#008000" }}>
                              {item.supervisorName}
                            </span>{" "}
                            worker working in{" "}
                            <span style={{ color: "#1E73FF" }}>
                              {item.name}
                            </span>{" "}
                            workforce and{" "}
                            <span style={{ color: "#1E73FF" }}>
                              {item.siteName}
                            </span>{" "}
                            site. <br />
                          </p>
                        ))
                      ) : (
                        <p className="ms-3">No Records Found</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default CompanyHome;
