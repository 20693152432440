import React from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";
const TermConditions = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="mt-4 d-flex align-items-center">
        <h5 className="mb-0">Terms and Conditions</h5>
        <Link
          className="btn btn-outline-secondary btn-blue font-14 ms-5"
          to="/dashboard/supervisor/settings"
        >
          {t("Back")}
        </Link>
      </div>
      <p className="mt-5">
        Welcome to [XYZ]! These terms and conditions outline the rules and
        regulations for the use of [XYZ]'s Website, located at [Website URL]. By
        accessing this website, we assume you accept these terms and conditions.
        Do not continue to use [Construction Company Name] if you do not agree
        to take all of the terms and conditions stated on this page. The
        following terminology applies to these Terms and Conditions, Privacy
        Statement and Disclaimer Notice and all Agreements: "Client", "You", and
        "Your" refers to you, the person log on this website and compliant to
        the Company's terms and conditions. "The Company", "Ourselves", "We",
        "Our", and "Us", refers to our Company. "Party", "Parties", or "Us",
        refers to both the Client and ourselves. All terms refer to the offer,
        acceptance, and consideration of payment necessary to undertake the
        process of our assistance to the Client in the most appropriate manner
        for the express purpose of meeting the Client's needs in respect of
        provision of the Company's stated services, in accordance with and
        subject to, prevailing law of Netherlands. Any use of the above
        terminology or other words in the singular, plural, capitalization,
        and/or he/she or they, are taken as interchangeable and therefore as
        referring to same.
      </p>
      <p>
        <strong>Cookies:</strong>
      </p>
    </>
  );
};

export default TermConditions;
