import http from "../../../../baseURL/httpcommon";
import authHeader from "../../../../baseURL/authHeader";

const pricePlann = (data) => {
    return http.get("subscription/allSubscriptionPlan");
};
const updatepricePlann = (id, data) => {
    return http.put(`subscription/subscription/update/${id}`, data);
};

const PlanApi = {
    pricePlann,
    updatepricePlann
};

export default PlanApi;
