import React, { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { Modal, Button } from "react-bootstrap";
import ServerSideTable from "../../../../common components/serverSideTable";
import ReportServices from "./services";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

const TaskReport = () => {
  const token = localStorage.getItem("token");
  const decodetoken = jwtDecode(token);
  const workforceId = decodetoken.workforceId;

  // defining the States
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [status, setStatus] = useState("All");
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [dateTime, setDateTime] = useState([]);
  const [page, setPage] = useState(0);
  const [totalItems, setTotalItems] = useState("");
  const { t } = useTranslation();

  //table columns
  const columns = [
    {
      field: "reportId",
      headerName: t("Report ID"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => {
        const reportResponse = params?.row?.workerReportResponse;
        if (reportResponse && reportResponse.reportId) {
          return reportResponse.reportId;
        }
        return "num";
      },
    },
    {
      field: "name",
      headerName: t("Name"),
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "employeeId",
      headerName: t("Employee ID"),
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "dueDate",
      headerName: t("Due Date"),
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Button
          variant="contained"
          size="medium"
          style={{
            backgroundColor: "#1E73FF",
            color: "#FFFFFF",
            width: "100px",
          }}
          onClick={() => handleModalOpen(params)}
        >
          {t("View")}
        </Button>
      ),
    },
  ];

  const handleModalOpen = (param) => {
    setModalData(param.row);
    console.log("modal_data", param.row);

    // getDataAndTime();
    setShow(true);
  };

  const handleClose = () => setShow(false);

  const getReportsByStatus = async (status) => {
    debugger;
    setStatus(status);
    setPageNumber(0);
  };

  const getAllAllocation = () => {
    getReportsByStatus("All");
  };
  const getBeforeAllocation = () => {
    getReportsByStatus("Before");
  };

  const getAfterAllocation = () => {
    getReportsByStatus("After");
  };

  const getDuringAllocation = () => {
    getReportsByStatus("During");
  };

  const getAllReports = async () => {
    debugger;
    setLoading(true);
    try {
      let eTaskReportStatus = "";
      if (status === "All") {
        eTaskReportStatus = "";
      } else {
        eTaskReportStatus = status;
      }
      const response = await ReportServices.getReportsList(
        workforceId,
        eTaskReportStatus,
        pageNumber,
        pageSize
      );
      const { currentPage, totalPages, content, totalElements } =
        response.data.data;
      setTotalItems(totalElements);
      setData(content);
      console.log("general_report", content);
      setCount(totalPages);
      setOpen(true);
    } catch (error) {
      if (error.response) {
        let errorMessage = "";
        if (error.response.status === 401) {
          errorMessage = error.response.data.message;
        } else if (
          error.response.status === 400 ||
          error.response.status === 500
        ) {
          errorMessage = error.response.data.message;
        }
        setMessage(errorMessage);
        setSeverity("error");
        setOpen(true);
      }
      setCount(0);
    } finally {
      setLoading(false);
    }
  };

  const handleImageHover = (imageUrl) => {
    const popupImage = document.getElementById("popupImage");
    popupImage.src = imageUrl;
    const imagePopup = document.getElementById("imagePopup");
    imagePopup.style.display = "block";

    const imageLinks = document.getElementsByClassName("image-link");
    for (let i = 0; i < imageLinks.length; i++) {
      if (imageLinks[i].getAttribute("href") !== imageUrl) {
        imageLinks[i].style.opacity = "0.5";
      }
    }
  };

  const handleImageLeave = () => {
    const imagePopup = document.getElementById("imagePopup");
    imagePopup.style.display = "none";

    const imageLinks = document.getElementsByClassName("image-link");
    for (let i = 0; i < imageLinks.length; i++) {
      imageLinks[i].style.opacity = "1";
    }
  };

  useEffect(() => {
    if (page !== undefined) {
      getAllReports();
    }
  }, [page, pageSize, status]);

  function getCompanies(e) {
    setLoading(false);
    if (e !== undefined) {
      setPage(e.page == undefined ? 0 : e.page);
      setPageSize(e.pageSize == undefined ? 10 : e.pageSize);
    }
  }

  const getRowId = (row) => {
    return row.workerReportResponse.reportId;
  };

  const handleDownloadReport = async () => {
    try {
      const reportID = modalData?.reportId;

      // Constructing static URL
      const downloadUrl = `http://62.171.153.83:8080/tappme-api-development/general/report/download/pdf/${reportID}`;

      // Create an anchor element
      const link = document.createElement("a");

      // Set the href attribute to the download URL
      link.href = downloadUrl;

      // Set the download attribute to specify the filename
      link.download = "case_report.pdf";

      // Simulate a click on the anchor element
      link.click();
    } catch (error) {
      console.error("Error downloading report:", error);
    }
  };
  return (
    <>
      <div className="card radius-10 p-4 px-3">
        <div className="card-header bg-transparent border-0">
          <div className="row g-3 align-items-center">
            <div className="col-12 col-md-2 col-lg-2">
              <h5 className="mb-0">{t("Task Reports")}</h5>
            </div>
            <div className="col-9 col-md-9 col-lg-9">
              <div className="text-right">
                <span className="dropdown1">
                  <button
                    className="btn btn-outlineStyle-secondary dropdown-toggle btn-blue font-14 ml-1"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {status}
                  </button>
                  <ul className="dropdown-menu dropdown-menu-end border-b ">
                    <li>
                      <a
                        className={`dropdown-item ${
                          status === "All" && "active"
                        }`}
                        onClick={getAllAllocation}
                      >
                        {t("All")}
                      </a>
                    </li>
                    <li>
                      <a
                        className={`dropdown-item ${
                          status === "Before" && "active"
                        }`}
                        onClick={getBeforeAllocation}
                      >
                        {t("Before")}
                      </a>
                    </li>
                    <li>
                      <a
                        className={`dropdown-item ${
                          status === "After" && "active"
                        }`}
                        onClick={getAfterAllocation}
                      >
                        {t("After")}
                      </a>
                    </li>
                    <li>
                      <a
                        className={`dropdown-item ${
                          status === "During" && "active"
                        }`}
                        onClick={getDuringAllocation}
                      >
                        {t("During")}
                      </a>
                    </li>
                  </ul>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="card-body">
          {loading ? (
            <ServerSideTable
              pageSize={10}
              page={0}
              count={count}
              functionName={getCompanies}
              data={[]}
              columns={columns}
              loading={true} // Set loading to true when data is loading
              idname="reportId"
            />
          ) : (
            <ServerSideTable
              pageSize={10}
              page={0}
              count={count}
              functionName={getCompanies}
              data={count > 0 ? data : []}
              columns={columns}
              loading={false}
              idname="reportId"
            />
          )}
        </div>
      </div>

      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Header
          // closeButton
          className="border-0 mb-0 p-3 pb-0"
        ></Modal.Header>
        <Modal.Title className="font-20 text-dark text-center  mt-2">
          Report Details
        </Modal.Title>

        <Modal.Body className="font-20 text-dark">
          <div className="px-5">
            <div className="border p-4 mb-4 border-20">
              <div className="d-flex mb-3">
                <label htmlFor="exampleDataList" className="form-label">
                  Report ID :
                </label>
                {/* <Button variant="primary3" className="ms-4">
                  #{modalData.taskId} -
                  {modalData?.workerReportResponse?.reportId}
                </Button> */}
                <label
                  className="ms-4"
                  style={{
                    backgroundColor: "rgba(109, 164, 255, 1)",
                    color: "white",
                    fontSize: "13px",
                    padding: "5px",
                  }}
                >
                  #{modalData.taskId} -{" "}
                  {modalData?.workerReportResponse?.reportId}
                </label>
              </div>
              <div className="d-flex mb-3">
                <label className="text-blue pe-3 mb-0">
                  <i className="fadeIn animated bx bx-time-five"></i>
                </label>
                <label className="form-label mb-0">
                  {/* setting date and time format */}
                  Submitted at {modalData.startDate} <br />
                  at 02:10 PM
                </label>
              </div>
              <div className="d-flex mb-0">
                <label className="text-blue pe-3">
                  <i className="fadeIn animated bx bx-map"></i>
                </label>
                <label className="form-label">
                  {modalData?.workerReportResponse?.location}
                </label>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-12 col-md-5">
                <div className="d-flex">
                  <label className="form-label">Task ID :</label>
                  <label className="form-label ms-4">{modalData.taskId}</label>
                </div>
              </div>
              <div className="col-12 col-md-7">
                <div className="d-flex">
                  <label className="form-label">Employee ID :</label>
                  <label className="form-label ms-4">
                    {modalData.employeeId}
                  </label>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-12 col-md-7">
                <div className="d-flex">
                  <label className="form-label">Description:</label>
                  <label className="form-label ms-4 text-light">
                    {modalData?.workerReportResponse?.description}
                  </label>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-12 col-md-8">
                <div className="d-flex">
                  <label className="form-label">Report submitted :</label>
                  <label className="form-label ms-4 text-light">
                    {modalData?.workerReportResponse?.etaskReportStatus} work
                  </label>
                </div>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-12 col-md-12">
                <label className="form-label mb-3">Images attached:</label>
                <section className="gallery_sec">
                  {modalData?.workerReportResponse?.workerReportDocResponses?.map(
                    (doc, index) => (
                      <a
                        data-fancybox="gallery"
                        href={doc?.documentUrl}
                        key={index}
                        className="image-link"
                        onMouseEnter={() => handleImageHover(doc.documentUrl)}
                        onMouseLeave={handleImageLeave}
                      >
                        <img
                          src={doc?.documentUrl}
                          alt={"file"}
                          className="zoom-img"
                        />
                      </a>
                    )
                  )}
                </section>
              </div>
            </div>
            <div id="imagePopup" style={{ display: "none" }}>
              <img id="popupImage" src="" alt="popup" />
            </div>

            <div className="border-0">
              <div className="col-12 mb-3">
                <a
                  class="btn btn-primary py-3 px-4"
                  onClick={handleDownloadReport}
                >
                  Download Report
                </a>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TaskReport;
