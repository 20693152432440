import http from '../../../../baseURL/httpcommon'
import authHeader from '../../../../baseURL/authHeader'

const supervisors = (companyId) => {
    return http.get(`user/inactive/supervisors?CompanyId=${companyId}`)
}
const worker = (companyId) => {
    return http.get(`user/inactive/workers?CompanyId=${companyId}`)
}
const assignedWorker = (companyId) => {
    return http.get(`workforce/workforce/${companyId}`)
}
const removeWorker = (WorkerForceId, WorkerId,) => {
    return http.post(`workforce/remove/worker?workForceId=${WorkerForceId}&userId=${WorkerId}`)
}
const updateWorker = (data) => {
    return http.put(`workforce/assign/worker`, data)
}
const getworkerForce = (page, pageSize, companyId) => {
    return http.get(`workforce/allWorkforce?companyId=${companyId}&pageNumber=${page}&pageSize=${pageSize}&sortBy=name&sortDir=asc`)
}
const addWorkerForce = (data) => {
    return http.post(`workforce/save`, data, {
        headers: {
            ...authHeader(),
        },
    })
}
const updateWorkerForce = (data) => {
    return http.put(`workforce/update`, data, {
        headers: {
            ...authHeader(),
        },
    })
}

const WorkForceApi = {
    supervisors,
    worker,
    removeWorker,
    updateWorker,
    assignedWorker,
    getworkerForce,
    addWorkerForce,
    updateWorkerForce
}

export default WorkForceApi