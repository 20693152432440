import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import MuiAlert from "@mui/material/Alert";
import { CircularProgress } from "@mui/material";
import TasksServices from "./services";
import swal from "sweetalert";
import { Modal, Button, Form, FormControl, InputGroup } from "react-bootstrap";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";

const ITEM_HEIGHT = 48;

export default function MoreMenu(prop) {
  console.log("prop_data", prop.stateProp);

  const [showModal, setShowModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editId, setEditId] = useState();
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [validated, setValidated] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [taskIdToUnassign, setTaskIdToUnassign] = useState(null);
  const { t } = useTranslation();
  const show = Boolean(anchorEl);

  const editData = {
    name: prop.data.name,
    description: prop.data.description,
    startDate: prop.data.startDate,
    dueDate: prop.data.dueDate,
    priority: prop.data.priority,
  };

  const [data, setData] = useState(editData);

  const setValues = (field, value) => {
    debugger;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: "", // Clearing the error message for the specific field
    }));
    setData({
      ...data,
      [field]: value,
    });
  };
  //handle edit link click
  const handleEditClick = (taskId) => {
    debugger;
    handleClose(); // Close the menu items
    setEditId(taskId);
    setShowEditModal(true);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const token = localStorage.getItem("token");
  const decodetoken = jwtDecode(token);
  console.log("decodetoken", decodetoken);
  const userId = decodetoken.id;

  const handleClose = () => {
    setAnchorEl(null);
    setShowEditModal(false);
    setOpenModal(false);
    setErrors({});
    setValidated(false);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleUnassignConfirmation = (taskId) => {
    debugger;
    setAnchorEl(null);
    setTaskIdToUnassign(taskId);
  };

  const handleConfirmation = (confirmed) => {
    debugger;
    if (confirmed && taskIdToUnassign) {
      handleUnassignTask(taskIdToUnassign);
    } else {
      setShowModal(false);
    }
  };

  //handle unAssign Task
  const handleUnassignTask = (taskId) => {
    debugger;
    console.log("prop_data_1", prop);
    const data = {
      userId: userId,
      status: "false",
    };
    setLoading(true); // Set loading state to true before making the request
    TasksServices.postUnassignTask(taskId, data.userId, data.status)
      .then((response) => {
        const data = response.data.data;
        console.log("resp_data", data);
        // setMessage(response.data.message);
        // setSeverity("success");
        prop.onActionComplete(response.data.message, "success");
        setShowModal(false);
        prop.load();
      })
      .catch((error) => {
        console.log("error", error);
        setMessage(error.message);
        setSeverity("error");
        // setOpen(true);
        setErrors({});
      })
      .finally(() => {
        setLoading(false); // Set loading state to false after the request is completed (whether successful or not)
      });
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  //handle delete task
  const handleDeleteTask = (taskId) => {
    swal({
      title: "Are you sure?",
      text: "You want to delete this task",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        TasksServices.handleDeleteTask(taskId)
          .then((response) => {
            if (response.status === 200 || response.status === 204) {
              swal("Success", {
                icon: "success",
              });
              setMessage("Deleted Successfully");
              setSeverity("success");
              setOpen(true);
              prop.load(); // Call the load function from props
            }
          })
          .catch((error) => {
            debugger;
            if (error.response.status === 400) {
              setMessage(error.response.data.message);
              console.log("message_400", error.response.data.message);
              prop.onActionComplete(error.response.data.message, "error");
              setSeverity("error");
              setOpen(true);
            } else {
              prop.onActionComplete(error.message, "error");
              setMessage(error.message);
              setSeverity("error");
              setOpen(true);
            }
          });
      }
    });
  };

  //handle Edit task
  const handleEditTask = (editId) => {
    debugger;
    setLoading(true);
    TasksServices.handleEditTask(editId, data)
      .then((response) => {
        if (response.status === 200) {
          const responseData = response.data;
          prop.onActionComplete(responseData.message, "success");
          setShowEditModal(false);
          prop.load();
          setAnchorEl(null);
          setErrors({});
          setLoading(false);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          setMessage(error.response.data.message);
          setSeverity("error");
          setErrors({});
          setOpen(true);
        } else if (error.response && error.response.status === 400) {
          debugger;
          const errorResponse = error.response.data;
          if (errorResponse.errorState) {
            setErrors({});
            setLoading(false);

            Object.keys(errorResponse.errorState).forEach((key) => {
              const errorMessage = errorResponse.errorState[key][0];
              setErrors((prevErrors) => ({
                ...prevErrors,
                [key]: errorMessage,
              }));
            });
          } else {
            prop.onActionComplete(errorResponse.message, "error");
            setErrors({});
            setLoading(false);
            setMessage("The request is invalid.");
            setSeverity("error");
            setOpen(true);
          }
        } else {
          setMessage("An error occurred while LogIn");
          setSeverity("error");
          setOpen(true);
        }
        // Reset the data to the initial values on error or cancellation
      });
  };

  //handle submit form
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      // handleEditTask(editId);
    }
    handleEditTask(editId);
    setValidated(true); // Update the validated state
  };

  return (
    <>
      <div>
        <button type="button" className="btn" onClick={handleClick}>
          <MoreVertIcon sx={{ width: "20px" }} />
        </button>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={show}
          onClose={handleClose}
        >
          <MenuItem onClick={handleShowModal}>
            <Link
              style={{ fontSize: 16, color: "#1E73FF", marginRight: "5px" }}
              component="button"
              to={
                prop.stateProp ? "/dashboard/supervisor/assigntask" : undefined
              }
              onClick={() => {
                if (!prop.stateProp) {
                  handleUnassignConfirmation(prop.data.taskId);
                }
              }}
            >
              {console.log("state_prop_taskMenu - " + prop.stateProp)}
              {prop.stateProp ? t("Assign Task") : t("Unassign Task")}
            </Link>
          </MenuItem>
          <MenuItem
            style={{ color: "#1E73FF" }}
            onClick={() => handleEditClick(prop.data.taskId)}
          >
            <EditIcon
              style={{ fontSize: 16, color: "#1E73FF", marginRight: "5px" }}
            />
            <span style={{ color: "#1E73FF" }}>{t("Edit")}</span>
          </MenuItem>
          <MenuItem
            style={{ color: "#1E73FF" }}
            onClick={() => handleDeleteTask(prop.data.taskId)}
          >
            <DeleteIcon
              style={{ fontSize: 16, color: "#1E73FF", marginRight: "5px" }}
            />
            <span style={{ color: "#1E73FF" }}>{t("Delete")}</span>
          </MenuItem>
        </Menu>
      </div>
      {/* edit modal */}
      <Modal show={showEditModal} onHide={handleClose} centered>
        <Modal.Header
          closeButton
          className="text-center border-0"
        ></Modal.Header>
        <Modal.Title className="text-center mb-2">{t("Edit Task")}</Modal.Title>
        <Modal.Body>
          <Form noValidate validated={validated}>
            <Form.Group className="mb-3" controlId="formTaskName">
              <Form.Label>{t("Task Name")}</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Enter task name"
                name="name"
                value={data.name}
                onChange={(e) => setValues("name", e.target.value)}
                isInvalid={!!errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formTaskDescription">
              <Form.Label>{t("Description")}</Form.Label>
              <Form.Control
                required
                as="textarea"
                rows={4}
                placeholder="Enter Description..."
                name="description"
                value={data.description}
                onChange={(e) => setValues("description", e.target.value)}
                isInvalid={!!errors.description}
              />
              <Form.Control.Feedback type="invalid">
                {errors.description}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formStartDate">
              <Form.Label>{t("Start Date")}</Form.Label>
              <Form.Control
                required
                type="date"
                placeholder="Start Date"
                name="startDate"
                value={data.startDate}
                onChange={(e) => setValues("startDate", e.target.value)}
                min={new Date().toISOString().split("T")[0]}
                isInvalid={!!errors.startDate}
              />
              <Form.Control.Feedback type="invalid">
                {errors.startDate}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formDueDate">
              <Form.Label>{t("Due Date")}</Form.Label>
              <Form.Control
                required
                type="date"
                placeholder="Due Date"
                name="dueDate"
                value={data.dueDate}
                onChange={(e) => setValues("dueDate", e.target.value)}
                min={new Date().toISOString().split("T")[0]}
                isInvalid={!!errors.dueDate}
              />
              <Form.Control.Feedback type="invalid">
                {errors.dueDate}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formPriority">
              <Form.Label>{t("Select Priority")}</Form.Label>
              <Form.Select
                required
                name="priority"
                value={data.priority}
                onChange={(e) => setValues("priority", e.target.value)}
                isInvalid={!!errors.priority}
              >
                <option value="Low">Low</option>
                <option value="Medium">Medium</option>
                <option value="High">High</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.priority}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
          <div className="text-center w-75 mx-auto mt-5 mb-4">
            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                variant="primary"
                onClick={handleSubmit}
                className="w-100"
              >
                {t("Update Task")}
              </Button>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showModal} onHide={() => handleConfirmation(false)} centered>
        <Modal.Body
          className="d-flex flex-column align-items-center"
          style={{ minHeight: "200px" }}
        >
          <p
            style={{
              fontSize: "1.2rem",
              textAlign: "center",
              marginTop: "2rem",
            }}
          >
            {t("Are you sure you want to unassign the task?")}
          </p>
          <div className="d-flex justify-content-center mt-3">
            <Button
              variant="primary"
              className="cursor-pointer me-3"
              style={{
                fontSize: "1.2rem",
                minWidth: "120px",
                height: "3.5rem",
              }}
              onClick={() => handleConfirmation(true)}
            >
              {t("Yes")}
            </Button>
            <Button
              variant="danger"
              className="cursor-pointer"
              style={{
                fontSize: "1.2rem",
                minWidth: "120px",
                height: "3.5rem",
              }}
              onClick={() => handleConfirmation(false)}
            >
              {t("No")}
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/* 
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={() => setOpen(false)}
        >
          <MuiAlert
            onClose={() => setOpen(false)}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </MuiAlert>
        </Snackbar>
      </Stack> */}
    </>
  );
}
