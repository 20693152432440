import React, { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { CircularProgress, Paper, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import MuiAlert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";
import {
  DragDropContext,
  Droppable,
  Draggable,
  // DndProvider,
} from "react-beautiful-dnd";
import TasksServices from "./services";

// import { HTML5Backend } from "react-dnd-html5-backend";

const AssignTask = () => {
  const [tasks, setTasks] = useState([]);
  const [workers, setWorkers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isAssigned, setIsAssigned] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState("");

  // Get supervisor Id from token
  const token = localStorage.getItem("token");
  const decodetoken = jwtDecode(token);
  const supervisorId = decodetoken.id;
  const userId = decodetoken.id;
  const { t } = useTranslation();
  // Function to get the isAssigned Values
  const getAssignedTask = () => {
    setIsAssigned(true);
  };
  const getUnassignedTask = () => {
    setIsAssigned(false);
  };

  // Get Workers List
  const getAllWorkers = () => {
    setLoading(true);
    TasksServices.getAllTaskWorkers(supervisorId)
      .then((response) => {
        console.log("resp_data", response.data.data);
        const content = response.data.data;
        setWorkers(content);
      })
      .catch((error) => {
        console.error("error fetching error", error.message);
        setMessage(error);
        setSeverity("error");
      })
      .finally(() => {
        setLoading(false); // Set loading to false after the request is completed or errored
      });
  };

  // Get Task List
  const getAllTasks = async () => {
    try {
      setLoading(true);
      const response = await TasksServices.getAssignTaskList(
        supervisorId,

        isAssigned
      );
      const { content } = response.data.data;
      const updatedContent = content.map((task) => ({
        ...task,
        id: task.taskId,
      }));
      setTasks(updatedContent);
      console.log("task_data", updatedContent);
    } catch (error) {
      console.error("Error fetching tasks:", error);
      setMessage(error);
      setSeverity("error");
    } finally {
      setLoading(false);
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    // setErrors({});
    // setShow(false);
    setOpen(false);
  };

  useEffect(() => {
    getAllTasks();
  }, [isAssigned]);

  useEffect(() => {
    getAllWorkers();
  }, [supervisorId]);

  // Define DraggableItem
  const DraggableItem = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    margin: theme.spacing(3),
    cursor: "grab",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "120%",
  }));

  // Handle drag and drop
  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    const { source, destination } = result;
    console.log("detination_for_task_drop", result);

    // Dropped within the worker zone
    if (destination.droppableId.includes("worker")) {
      const taskId = result.draggableId.split("-")[1];
      const workerId = destination.droppableId.split("-")[1];
      // Call API to assign task to worker
      try {
        debugger;
        setLoading(true);
        const data = {
          userId: userId,
          status: "true",
        };
        const response = await TasksServices.postAssignTask(
          taskId,
          workerId,
          data.status
        );
        // Update tasks list after successful assignment
        setMessage(response.data.message);
        setSeverity("success");
        setOpen(true);
        getAllTasks();
      } catch (error) {
        console.error("Error assigning task to worker:", error);
        setMessage(error);
        setSeverity("error");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <div className="card radius-10 p-4 px-3">
        <div className="">
          <div className="row g-3 align-items-center">
            <div className="col-12 col-md-9 col-lg-9">
              <h5 className="mb-0">
                {t("Assign Tasks")}
                {/* <span className="text-gray font-12 ps-4">
                  (Assign tasks by just simply drag and drop)
                </span> */}
              </h5>
            </div>
            <div className="col-12 col-md-3 col-lg-3 text-right">
              <Link
                to="/dashboard/supervisor/tasks"
                className="btn btn-outline-secondary btn-blue font-14 ms-5"
              >
                {t("Back")}
              </Link>
            </div>

            {/* <div className="col-12 col-md-3 col-lg-3 d-flex ">
              <div className="d-flex text-right">
                <button
                  className="btn btn-outline-secondary dropdown-toggle btn-blue font-14 ml-2"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {isAssigned ? "Assigned" : "Unassigned"}
                </button>
                <ul className="dropdown-menu dropdown-menu-end border-b">
                  <li>
                    <a
                      className={`dropdown-item ${
                        isAssigned === true && "active"
                      }`}
                      onClick={getAssignedTask}
                    >
                      Assigned
                    </a>
                  </li>
                  <li>
                    <a
                      className={`dropdown-item ${
                        isAssigned === false && "active"
                      }`}
                      onClick={getUnassignedTask}
                    >
                      Unassigned
                    </a>
                  </li>
                </ul>

                <button
                  className="btn btn-outline-secondary btn-blue font-14 ms-2"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal2"
                  type="button"
                >
                  Add
                </button>
                <span className="dropdown1 ms-2">
                  <button
                    className="btn btn-outline-secondary btn-blue font-14 toggle"
                    type="button"
                  >
                    <i className="fadeIn animated bx bx-filter font-20"></i>{" "}
                    Filter
                  </button>
                  <ul className="dropdown-menu1 list-group">
                    <li className="list-group-item">
                      <div className="row mb-2">
                        <div className="col-5 pt-1">
                          <label>Emp ID</label>
                        </div>
                        <div className="col-7 pl-0">
                          <input className="form-control" type="text" />
                        </div>
                      </div>

                      <div className="row mb-2">
                        <div className="col-5 pt-1">
                          <label>Task ID</label>
                        </div>
                        <div className="col-7 pl-0">
                          <input className="form-control" type="text" />
                        </div>
                      </div>

                      <div className="row mb-2">
                        <div className="col-12 pt-1">
                          <label>Site</label>

                          <input className="form-control" type="text" />
                        </div>
                      </div>

                      <div className="row mb-2">
                        <div className="col-12 pt-1">
                          <label>Supervisor</label>

                          <input className="form-control" type="text" />
                        </div>
                      </div>
                    </li>
                  </ul>
                </span>
              </div>
            </div> */}
          </div>
        </div>

        <DragDropContext onDragEnd={onDragEnd}>
          <div className="row assign-task">
            <div className="col-12 col-md-5 col-lg-5 py-2">
              <h5 className="mb-4 mt-5" style={{ fontWeight: "normal" }}>
                {t("Tasks")}
              </h5>
              {loading ? (
                <div>
                  <CircularProgress />
                </div>
              ) : tasks.length === 0 ? (
                <div>{t("No Tasks Found")}</div>
              ) : (
                <Droppable droppableId="task-list">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {tasks.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={`task-${item.id}`}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div className="card-body card-border d-flex justify-content-between align-items-center mb-3 mt-2">
                                <Typography variant="body1">
                                  {item.name}
                                </Typography>
                                <div className="text-dark w-20">
                                  <label
                                    className="btn btn-danger no-border btn-md px-3 py-2 font-14 float-right"
                                    style={{
                                      width: "100px",
                                      backgroundColor:
                                        item.priority === "Low"
                                          ? "#0ACF83"
                                          : item.priority === "High"
                                          ? "#FF0000"
                                          : "#E5B304",
                                      borderColor: "transparent",
                                      display: "inline-block", // Ensure label behaves as a block element
                                      cursor: "pointer", // Add pointer cursor to indicate interaction
                                    }}
                                  >
                                    <span>{item.priority}</span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              )}
            </div>
            <div className="col-12 col-md-2 col-lg-2 py-2"></div>

            <div className="col-12 col-md-5 col-lg-5 py-2">
              <h5 className="mb-4 mt-5" style={{ fontWeight: "normal" }}>
                {t("Workers")}
              </h5>

              {loading ? (
                <div>
                  <CircularProgress />
                </div>
              ) : workers.length === 0 ? (
                <div>{t("No Worker Found")}</div>
              ) : (
                workers.map((item) => (
                  //dropable area
                  <Droppable key={item.id} droppableId={`worker-${item.id}`}>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        <Paper
                          elevation={3}
                          className="card radius-15"
                          key={item.id}
                        >
                          <div className="card-body card-border align-items-center d-flex">
                            <p className="card-text pt-2 width-80">
                              {item.name}
                            </p>
                            <div className="text-dark w-20">
                              <button
                                type="button"
                                className="btn btn-primary px-3 font-14 float-right rounded-0"
                                style={{
                                  width: "120px",
                                }}
                              >
                                <span>
                                  {item.employeeId.length > 7
                                    ? `#${item.employeeId.substring(0, 7)}...`
                                    : `#${item.employeeId}`}
                                </span>
                              </button>
                            </div>
                          </div>
                        </Paper>
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                ))
              )}
            </div>
          </div>
        </DragDropContext>
      </div>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleClose}
        >
          <MuiAlert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </MuiAlert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default AssignTask;
