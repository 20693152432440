import React, { useEffect, useState } from "react";
import "./index.css";
import Modal from "react-bootstrap/Modal";
import HomeServices from "./services";
import { jwtDecode } from "jwt-decode";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SupervisorHome = () => {
  const [showModal, setShowModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(100);

  const [requests, setRequests] = useState([]); //to update the modal object
  const [flag, setFlag] = useState("");
  const [data, setData] = useState([]);
  const [approvereject, serApproveReject] = useState();
  const [newData, setNewData] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [count, setCount] = useState(0);
  const [load, setLoad] = useState(false);
  const [loadCount, setLoadCount] = useState(0);

  //------------ CheckOut/Check IN Request States---------------
  const [checkOutRequests, setCheckOutRequests] = useState([]);
  const [checkInRequests, setCheckInRequests] = useState([]);

  //--------- fetch supervisorId from token-----------
  const token = localStorage.getItem("token");
  const decodedToken = jwtDecode(token);
  console.log("tokendata", decodedToken);
  const supervisorId = decodedToken.id;
  const requestId = decodedToken.reqestId;
  console.log("reqestId", requestId);

  const { t } = useTranslation();

  //--------------- function to fetch Dashboard data based on the supervisorID---------------------
  const getHomeDashboard = () => {
    debugger;
    HomeServices.AllCheckINOUTRequests(supervisorId)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.data;
          console.log("resp_data", data);
          setRequests(data);
          // Separate CHECK-IN and CHECK-OUT requests
          const checkInRequest = data.filter(
            (request) => request.flag === "CHECK-IN"
          );
          const checkOutRequest = data.filter(
            (request) => request.flag === "CHECK-OUT"
          );
          //------------------fetch flag and update the state-------------------

          console.log("checkInRequests", checkInRequest);
          console.log("checkOutRequests", checkOutRequest);

          // Set state for CHECK-IN requests
          setCheckInRequests(checkInRequest);
          // Set state for CHECK-OUT requests
          setCheckOutRequests(checkOutRequest);
          setMessage(response.data.message);
          setSeverity("success");
          // setOpen(true);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 404) {
            console.log("data not found");
            setMessage(error.message);
            setSeverity("error");
          } else if (error.response.status === 500) {
            console.error("Server error", error);
            setMessage(error.message);
            setSeverity("error");
          } else if (error.response.status === 400) {
            console.error("bad request", error);
            setMessage(error.message);
            setSeverity("error");
          } else {
            setMessage(error.message);
            setSeverity("error");
            // setOpen(true);
          }
        } else {
          setMessage(error.message);
          setSeverity("error");
          // setOpen(true);
        }
      })
      .finally(() => {
        setOpen(false);
      });
  };

  const convertToPercentage = () => {
    debugger;
    console.log("data_map", data);
    const newData = data.map((item) => {
      const checkInDate = new Date(item.checkIn);
      console.log("checkInSeconds", checkInDate);
      const checkOutDate = new Date(item.checkOut);
      console.log("checkOutDate", checkOutDate);

      //////-----------------///////////
      const breakTimes = item.breakTime.map((value) => {
        // Convert start and end time strings to Date objects
        const startTime = new Date(value.startTime);
        const endTime = new Date(value.endTime);

        // Calculate break time in seconds
        const breakTimeSecond =
          startTime.getHours() * 3600 +
          startTime.getMinutes() * 60 +
          startTime.getSeconds() +
          startTime.getMilliseconds() / 1000;

        // Return an object containing the start and end times
        return {
          startTime: startTime.toLocaleTimeString(), // Convert to local time string
          endTime: endTime.toLocaleTimeString(), // Convert to local time string
          breakTimeSecond: breakTimeSecond.toFixed(2), // Convert to seconds
        };
      });

      console.log("breakTimes", breakTimes);

      const overTimeHours = new Date(item.overTimeHours);
      console.log("overTimeHours", overTimeHours);

      // Calculate total seconds since midnight for checkIn and checkOut
      const checkInSeconds =
        checkInDate.getHours() * 3600 +
        checkInDate.getMinutes() * 60 +
        checkInDate.getSeconds() +
        checkInDate.getMilliseconds() / 1000;
      console.log("checkInSeconds", checkInSeconds);

      const checkOutSeconds =
        checkOutDate.getHours() * 3600 +
        checkOutDate.getMinutes() * 60 +
        checkOutDate.getSeconds() +
        checkOutDate.getMilliseconds() / 1000;
      console.log("checkOutSeconds", checkOutSeconds);

      const overTimeSeconds =
        overTimeHours.getHours() * 3600 +
        overTimeHours.getMinutes() * 60 +
        overTimeHours.getSeconds() +
        overTimeHours.getMilliseconds() / 1000;
      console.log("overTimeSeconds", overTimeSeconds);

      // Calculate percentages
      const totalSecondsInDay = 24 * 3600;
      const checkInPercentage = (checkInSeconds / totalSecondsInDay) * 100;
      const checkOutPercentage = (checkOutSeconds / totalSecondsInDay) * 100;
      const overTimePercentage = (overTimeSeconds / totalSecondsInDay) * 100;
      const breakTimePercentage =
        (breakTimes.reduce(
          (acc, breakItem) => acc + parseFloat(breakItem.breakTimeSecond),
          0
        ) /
          totalSecondsInDay) *
        100;
      console.log("checkInPercentage", checkInPercentage);
      console.log("checkOutPercentage", checkOutPercentage);
      console.log("overTimePercentage", overTimePercentage);
      console.log("breakTimePercentage", breakTimePercentage);

      // Assign percentages to item properties
      return {
        ...item,
        checkInPercentage: checkInPercentage.toFixed(2),
        checkOutPercentage: checkOutPercentage.toFixed(2),
        overTimePercentage: overTimePercentage.toFixed(2),
        breakTimePercentage: breakTimePercentage.toFixed(2),
      };
    });

    setNewData(newData);
    console.log(newData); // Check the updated data array with percentages
  };

  //--- databoard count records-------
  useEffect(() => {
    getHomeDashboard();
    getHomeTimeSheet();
    getCountRecords();
  }, []);

  //get timesheet data
  const getHomeTimeSheet = () => {
    const supervisorId = decodedToken.id;
    const requestTypeEnum = "";
    const name = "";

    const date = new Date();
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const dd = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${yyyy}-${mm}-${dd}`;
    // const f_date = "2024-05-03";
    HomeServices.getHomeTimeSheet(
      supervisorId,
      name,
      formattedDate,
      requestTypeEnum,
      pageNumber,
      pageSize
    )
      .then((res) => {
        const homeTimeSheet = res;
        console.log("timesheet_res", homeTimeSheet);
        if (homeTimeSheet.status === 200) {
          console.log("timesheet_res", homeTimeSheet.data.data);
          const data = homeTimeSheet.data.data;
          setData(data);
          if (data.length > 0) {
            setCount(1);
          } else {
            setCount(0);
          }
          console.log("data_time", homeTimeSheet.data.data);
          convertToPercentage();
        }
      })
      .catch((error) => {
        if (error.response) {
          const status = error.response.status;
          if (status === 404) {
            console.error("Error 404: Resource not found");
          } else if (status === 500) {
            console.error("Error 500: Internal server error");
          } else if (status === 401) {
            console.error("Error 401: Unauthorized");
          } else {
            console.error("Unexpected error:", error);
          }
        } else if (error.request) {
          console.error("No response received:", error.request);
        } else {
          console.error("Error:", error.message);
        }
      });
  };
  const getCountRecords = () => {
    debugger;
    setLoad(true);
    const workforceId = decodedToken.workforceId;
    HomeServices.getDashboardCount(workforceId)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.data;
          console.log("resp_data", data);
          setTotalCount(data);
          setLoadCount(1);
          setLoad(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setMessage(error.message);
        setSeverity("error");
        // setOpen(true);
      });
  };

  //modal open functionlity
  const handleOpenModal = (flag) => {
    debugger;
    setFlag(flag); // Set the flag state based on the received flag parameter
    setShowModal(true); // Open the modal
  };

  //-------------------common function for (CEHCKIN/CHECKOUT) Approve/Reject-------------

  const handleApprove = (request, flag) => {
    debugger;
    console.log("request_data_approve", request);
    const requestType = flag;
    console.log("requestType", requestType);
    const requestId = request.requestId;
    console.log("requestId", requestId);
    const status = "APPROVED";
    console.log("status", status);
    HomeServices.ApproveRejectRequest(requestType, requestId, status)
      .then((response) => {
        const data = response.data.data;
        if (response.status === 200) {
          debugger;
          setCheckInRequests(data);
          setMessage(response.data.message);
          setSeverity("success");
          setOpen(true);
          getHomeDashboard(); //to get the updated data on Dashboard
        }
      })
      .catch((error) => {
        setMessage(error.message);
        setSeverity("error");
        // setOpen(true);
      });
    // setOpen(false);
  };

  const handleReject = (request, flag) => {
    debugger;
    console.log("request_data_approve", request);
    const requestType = flag;
    console.log("requestType", requestType);
    const requestId = request.requestId;
    console.log("requestId", requestId);
    const status = "DENIED";
    console.log("status", status);
    HomeServices.ApproveRejectRequest(requestType, requestId, status)
      .then((response) => {
        const data = response.data.data;
        if (response.status === 200) {
          setCheckInRequests(data);
          setMessage(response.data.message);
          setSeverity("success");
          setOpen(true);
          getHomeDashboard(); //to get the updated data on dashaboard
        }
      })
      .catch((error) => {
        setMessage(error.message);
        setSeverity("error");
        // setOpen(true);
      });
    // setOpen(false);
  };

  function formatDateTime(dateTimeString) {
    const dateTime = new Date(dateTimeString);

    // Check if the date is valid
    if (isNaN(dateTime.getTime())) {
      return "Invalid Date";
    }

    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      hourCycle: "h23",
    };
    let formattedDateTime = dateTime.toLocaleString("en-US", options);
    formattedDateTime = formattedDateTime.replace(/,\s*/, "-");
    // Convert AM/PM to uppercase
    formattedDateTime = formattedDateTime.replace(/\b(?:am|pm)\b/gi, (match) =>
      match.toUpperCase()
    );

    return formattedDateTime;
    // return dateTime.toLocaleString("en-GB", options);
  }

  const onHide = () => {
    setShowModal(false);
  };
  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setShowModal(false);
  };

  const jwt = jwtDecode(localStorage.getItem("token"));
  const role = jwt.userType;
  const navigate = useNavigate();
  useEffect(() => {
    if (role === "ADMIN") {
      navigate("/dashboard/company/home");
    } else if (role === "SUPERADMIN") {
      navigate("/dashboard/admin/home");
    }
    // else {
    //   navigate("/dashboard/company/home")
    // }
    console.log("Role", role);
  }, []);

  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <div class="row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-4 home-box">
        <div class="col">
          <div class="card radius-10">
            <div class="card-body py-5">
              <div class="align-items-center">
                <div>
                  <p class="mb-3 text-white font-20">
                    {t("Total Tasks Today")}
                  </p>
                  <h2 class="my-1 text-white">
                    {loadCount > 0 ? (
                      load ? (
                        <CircularProgress color="inherit" />
                      ) : (
                        totalCount.totalTasks
                      )
                    ) : (
                      0
                    )}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card radius-10">
            <div class="card-body py-5">
              <div class="align-items-center">
                <div>
                  <p class="mb-3 text-white font-20">{t("Tasks Completed")}</p>
                  <h2 class="my-1 text-white">
                    {loadCount > 0 ? (
                      load ? (
                        <CircularProgress color="inherit" />
                      ) : (
                        totalCount.totalCompletedTasks
                      )
                    ) : (
                      0
                    )}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card radius-10">
            <div class="card-body py-5">
              <div class="align-items-center">
                <div>
                  <p class="mb-3 text-white font-20">{t("Total Workers")}</p>
                  <h2 class="my-1 text-white">
                    {loadCount > 0 ? (
                      load ? (
                        <CircularProgress color="inherit" />
                      ) : (
                        totalCount.totalWorkers
                      )
                    ) : (
                      0
                    )}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card radius-10">
            <div class="card-body py-5">
              <div class="align-items-center">
                <div>
                  <p class="mb-3 text-white font-20">
                    {t("Workers Checked-in")}
                  </p>
                  <h2 class="my-1 text-white">
                    {loadCount > 0 ? (
                      load ? (
                        <CircularProgress color="inherit" />
                      ) : (
                        totalCount.totalCheckedInWorkerToday
                      )
                    ) : (
                      0
                    )}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-lg-6 col-xl-6">
          <div class="card radius-10 w-100">
            <div class="card-body px-4">
              <div class="row row-cols-1 row-cols-lg-2 g-3 align-items-center pb-3 mb-3">
                <div class="col">
                  <h5 class="mb-0 font-18">{t("Check-In Requests")}</h5>
                </div>
              </div>
              {Array.isArray(requests) && requests.length > 0
                ? requests
                    .filter((request) => request.flag === "CHECK-IN")
                    .slice(0, 2)
                    .map((request) => (
                      <div key={request.id} className="row">
                        <div className="col-7">
                          <ul className="ps-2 mb-0">
                            <li>
                              <span>
                                {request.name} requests to{" "}
                                <span style={{ color: "#008000" }}>
                                  {request.flag}
                                </span>{" "}
                                <span className="text-primary">
                                  AT {formatDateTime(request.requestTime)}
                                </span>
                              </span>
                            </li>
                          </ul>
                        </div>

                        <div className="col-5 text-right">
                          <button
                            type="button"
                            className="btn btn-success2 px-3 px-3"
                            onClick={() => handleApprove(request, "CHECK-IN")}
                            style={{ marginRight: "5px" }}
                          >
                            {t("Approve")}
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger2 px-3"
                            onClick={() => handleReject(request, "CHECK-IN")}
                          >
                            {t("Reject")}
                          </button>
                        </div>
                      </div>
                    ))
                : // <p>{t("No Request Found")}</p>
                  null}

              <hr />
              <div class="row text-center mt-4">
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() => handleOpenModal("CHECK-IN")}
                >
                  {t("View all Requests")}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 col-xl-6">
          <div class="card radius-10 w-100">
            <div class="card-body px-4">
              <div class="row row-cols-1 row-cols-lg-2 g-3 align-items-center pb-3 mb-3">
                <div class="col">
                  <h5 class="mb-0 font-18">{t("Check-Out Requests")}</h5>
                </div>
              </div>
              {Array.isArray(requests) && requests.length > 0
                ? requests
                    .filter((request) => request.flag === "CHECK-OUT")
                    .slice(0, 2)
                    .map((request) => (
                      // Display each request
                      <div key={request.id} className="row">
                        <div className="col-7">
                          <ul className="ps-2 mb-0">
                            <li>
                              <span>
                                {request.name} requests to{" "}
                                <span style={{ color: "#FF1C1C" }}>
                                  {request.flag}
                                </span>{" "}
                                <span className="text-primary">
                                  AT {formatDateTime(request.requestTime)}
                                </span>
                              </span>
                            </li>
                          </ul>
                        </div>

                        <div className="col-5 text-right">
                          <button
                            type="button"
                            className="btn btn-success2 px-3 px-3"
                            onClick={() => handleApprove(request, "CHECK-OUT")}
                            style={{ marginRight: "5px" }}
                          >
                            {t("Approve")}
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger2 px-3"
                            onClick={() => handleReject(request, "CHECK-OUT")}
                          >
                            {t("Reject")}
                          </button>
                        </div>
                      </div>
                    ))
                : // <p>{t("No Request Found")}</p>
                  null}

              <hr />
              <div class="row text-center mt-4">
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() => handleOpenModal("CHECK-OUT")}
                >
                  {t("View all Requests")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card radius-10 p-4 px-3">
        <div class="card-body height60">
          <div class="row">
            <div class="col-3 col-md-3 pt-2">
              <div class="d-flex">
                <label class="pe-3 width-40 text-gray">Employee ID</label>
                <label class="width-60 text-gray">Name</label>
              </div>
            </div>

            <div class="col-9 col-md-9">
              <div class="bar">
                <div class="child">8am</div>
                <div class="child">9am</div>
                <div class="child">10am</div>
                <div class="child">11am</div>
                <div class="child">12Pm</div>
                <div class="child">1pm</div>
                <div class="child">2pm</div>
                <div class="child">3pm</div>
                <div class="child">4pm</div>
                <div class="child">5pm</div>
                <div class="child">6pm</div>
                <div class="child">7pm</div>
                <div class="child">8pm</div>
                <div class="child">9pm</div>
                <div class="child">10pm</div>
                <div class="child">11pm</div>
                <div class="child">12pm</div>
                <div class="child">1am</div>
                <div class="child">2am</div>
                <div class="child">3am</div>
                <div class="child">4am</div>
                <div class="child">5am</div>
                <div class="child">6am</div>
                <div class="child">7am</div>
              </div>
            </div>
          </div>

          {newData.map((item, index) => (
            <div key={index} className="row pt-2">
              <div className="col-3 col-md-3 py-2 border-bottom">
                <div className="d-flex">
                  <div>
                    <label
                      className="pe-3 width-40"
                      style={{ marginLeft: "15px" }}
                    >
                      {item?.userId}
                    </label>
                    <label
                      className="width-60"
                      style={{ marginLeft: "3.5rem" }}
                    >
                      {item?.name}
                    </label>
                  </div>
                </div>
              </div>
              {count > 0 ? (
                <div className="col-9 col-md-9 py-2">
                  <div className="lable">
                    {/* checkIn time */}
                    <div
                      className="time"
                      style={{
                        width: `calc(${item?.checkInPercentage}% * 3)`,
                        marginLeft: `calc(${item?.checkInPercentage}%* 0)`,
                        background: `rgba(10, 207, 131, 0.20)`,
                      }}
                    >
                      <div className="time-btn">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                          strokeWidth="4"
                        >
                          <path
                            d="M13 7.5C13 10.5376 10.5376 13 7.5 13C4.46243 13 2 10.5376 2 7.5C2 4.46243 4.46243 2 7.5 2C10.5376 2 13 4.46243 13 7.5Z"
                            fill="#FFFEFF"
                            stroke="#2CDE9A"
                          />
                        </svg>
                        CIN
                      </div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="7"
                        height="7"
                        viewBox="0 0 7 7"
                        fill="none"
                        className="text-icon"
                      >
                        <path
                          d="M5.95079 2.84905L1.71079 0.185798C1.61783 0.126925 1.50723 0.0801961 1.38537 0.048307C1.26351 0.0164179 1.1328 0 1.00079 0C0.868781 0 0.738075 0.0164179 0.616216 0.048307C0.494356 0.0801961 0.383755 0.126925 0.290792 0.185798C0.104542 0.303485 0 0.462685 0 0.628626C0 0.794568 0.104542 0.953767 0.290792 1.07145L3.83079 3.29502L0.290792 5.51858C0.104542 5.63627 0 5.79547 0 5.96141C0 6.12735 0.104542 6.28655 0.290792 6.40424C0.384233 6.46245 0.495049 6.50851 0.616886 6.53977C0.738724 6.57103 0.869186 6.58687 1.00079 6.58639C1.1324 6.58687 1.26286 6.57103 1.3847 6.53977C1.50654 6.50851 1.61735 6.46245 1.71079 6.40424L5.95079 3.74099C6.04452 3.68259 6.11891 3.61312 6.16968 3.53658C6.22045 3.46004 6.24659 3.37794 6.24659 3.29502C6.24659 3.2121 6.22045 3.13 6.16968 3.05345C6.11891 2.97691 6.04452 2.90744 5.95079 2.84905Z"
                          fill="#0ACF83"
                        />
                      </svg>
                    </div>
                    {/* break time */}
                    <div
                      class="time"
                      style={{
                        width: `calc(${item?.breakTimePercentage}% * 2)`,
                        background: `#6DA4FF`,
                      }}
                    >
                      <div class="time-btn2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="16"
                          viewBox="0 0 15 16"
                          fill="none"
                        >
                          <circle
                            cx="7.5"
                            cy="7.92188"
                            r="5.5"
                            fill="#FFFEFF"
                            stroke="#9EC3FF"
                            stroke-width="4"
                          />
                        </svg>
                        BR
                      </div>
                    </div>
                    {/* checkIn time */}
                    <div
                      class="time"
                      style={{
                        width: `calc(${item?.checkInPercentage}% * 5)`,
                        marginLeft: `calc(${item?.checkInPercentage}% * 0)`,
                        background: `rgba(10, 207, 131, 0.20)`,
                      }}
                    >
                      <div class="time-btn">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                        >
                          <path
                            d="M13 7.5C13 10.5376 10.5376 13 7.5 13C4.46243 13 2 10.5376 2 7.5C2 4.46243 4.46243 2 7.5 2C10.5376 2 13 4.46243 13 7.5Z"
                            fill="#FFFEFF"
                            stroke="#2CDE9A"
                            stroke-width="4"
                          />
                        </svg>
                        CIN
                      </div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="7"
                        height="7"
                        viewBox="0 0 7 7"
                        fill="none"
                        class="text-icon"
                      >
                        <path
                          d="M5.95079 2.84905L1.71079 0.185798C1.61783 0.126925 1.50723 0.0801961 1.38537 0.048307C1.26351 0.0164179 1.1328 0 1.00079 0C0.868781 0 0.738075 0.0164179 0.616216 0.048307C0.494356 0.0801961 0.383755 0.126925 0.290792 0.185798C0.104542 0.303485 0 0.462685 0 0.628626C0 0.794568 0.104542 0.953767 0.290792 1.07145L3.83079 3.29502L0.290792 5.51858C0.104542 5.63627 0 5.79547 0 5.96141C0 6.12735 0.104542 6.28655 0.290792 6.40424C0.384233 6.46245 0.495049 6.50851 0.616886 6.53977C0.738724 6.57103 0.869186 6.58687 1.00079 6.58639C1.1324 6.58687 1.26286 6.57103 1.3847 6.53977C1.50654 6.50851 1.61735 6.46245 1.71079 6.40424L5.95079 3.74099C6.04452 3.68259 6.11891 3.61312 6.16968 3.53658C6.22045 3.46004 6.24659 3.37794 6.24659 3.29502C6.24659 3.2121 6.22045 3.13 6.16968 3.05345C6.11891 2.97691 6.04452 2.90744 5.95079 2.84905Z"
                          fill="#0ACF83"
                        />
                      </svg>
                    </div>
                    {/* Overtime time */}
                    <div
                      class="time"
                      style={{
                        width: `calc(${item?.overTimePercentage}% * 3)`,
                        minWidth: "100%",
                        marginLeft: `calc(${item?.overTimePercentage}% * 0)`,
                        background: `rgba(255, 153, 109, 0.20)`,
                      }}
                    >
                      <div class="time-btn3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="16"
                          viewBox="0 0 15 16"
                          fill="none"
                        >
                          <path
                            d="M15 8.30664C15 12.4488 11.6421 15.8066 7.5 15.8066C3.35786 15.8066 0 12.4488 0 8.30664C0 4.16451 3.35786 0.806641 7.5 0.806641C11.6421 0.806641 15 4.16451 15 8.30664Z"
                            fill="#FFFEFF"
                          />
                          <path
                            d="M13 8.30664C13 11.3442 10.5376 13.8066 7.5 13.8066C4.46243 13.8066 2 11.3442 2 8.30664C2 5.26907 4.46243 2.80664 7.5 2.80664C10.5376 2.80664 13 5.26907 13 8.30664Z"
                            stroke="#FF996D"
                            stroke-opacity="0.73"
                            stroke-width="4"
                          />
                        </svg>
                        OVT
                      </div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="7"
                        height="7"
                        viewBox="0 0 7 7"
                        fill="none"
                        class="text-icon"
                      >
                        <path
                          d="M5.95079 2.84905L1.71079 0.185798C1.61783 0.126925 1.50723 0.0801961 1.38537 0.048307C1.26351 0.0164179 1.1328 0 1.00079 0C0.868781 0 0.738075 0.0164179 0.616216 0.048307C0.494356 0.0801961 0.383755 0.126925 0.290792 0.185798C0.104542 0.303485 0 0.462685 0 0.628626C0 0.794568 0.104542 0.953767 0.290792 1.07145L3.83079 3.29502L0.290792 5.51858C0.104542 5.63627 0 5.79547 0 5.96141C0 6.12735 0.104542 6.28655 0.290792 6.40424C0.384233 6.46245 0.495049 6.50851 0.616886 6.53977C0.738724 6.57103 0.869186 6.58687 1.00079 6.58639C1.1324 6.58687 1.26286 6.57103 1.3847 6.53977C1.50654 6.50851 1.61735 6.46245 1.71079 6.40424L5.95079 3.74099C6.04452 3.68259 6.11891 3.61312 6.16968 3.53658C6.22045 3.46004 6.24659 3.37794 6.24659 3.29502C6.24659 3.2121 6.22045 3.13 6.16968 3.05345C6.11891 2.97691 6.04452 2.90744 5.95079 2.84905Z"
                          fill="#FF996D"
                        />
                      </svg>
                    </div>
                    {/* Checkout time */}
                    <div
                      class="time"
                      style={{
                        width: `calc(${item?.checkOutPercentage}% * 3)`,
                        marginLeft: `calc(${item?.checkOutPercentage}% * 0)`,
                        borderRadius: `22.5px`,
                        background: `rgba(255, 153, 109, 0.20)`,
                      }}
                    >
                      <div class="time-btn4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="16"
                          viewBox="0 0 15 16"
                          fill="none"
                        >
                          <circle
                            cx="7.5"
                            cy="7.92188"
                            r="5.5"
                            fill="#FFFEFF"
                            stroke="#FF9E9E"
                            stroke-width="4"
                          />
                        </svg>
                        COUT
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </div>
      <Modal
        show={showModal}
        size="lg"
        aria-labelledby="contained-modal-vcenter"
        centered
        style={{
          width: "60%",
          marginLeft: "20rem",
        }}
      >
        <Modal.Header
          className="text-center"
          onHide={handleModalClose}
          closeButton
        >
          <Modal.Title className="d-flex justify-content-center">
            {flag === "CHECK-OUT"
              ? "All Check-OUT Requests"
              : "All Check-IN Requests"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {flag === "CHECK-OUT" &&
          checkOutRequests &&
          checkOutRequests.length > 0 ? (
            checkOutRequests.map((request, index) => (
              <div className="row align-items-center">
                <div className="col-md-8">
                  <ul>
                    <li>
                      <p style={{ fontSize: "0.9rem" }}>
                        {request.name} request to{" "}
                        <span style={{ color: "red" }}>{request.flag}</span>{" "}
                        <span style={{ color: "#1E73FF" }}>AT</span>{" "}
                        <span style={{ color: "#1E73FF" }}>
                          {formatDateTime(request.requestTime)}
                        </span>
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="col-md-4 text-right">
                  <Button
                    variant="success"
                    style={{
                      width: "78px",
                      height: "39px",
                      borderRadius: "4px",
                      padding: "14px 20px",
                      opacity: 1,
                      color: "#008000",
                      backgroundColor: "#CBFFC8",
                      marginBottom: "10px",
                      marginRight: "10px",
                      textTransform: "capitalize",
                    }}
                    onClick={() => handleApprove(request, "CHECK-OUT")}
                  >
                    {t("Approve")}
                  </Button>
                  <Button
                    variant="danger"
                    style={{
                      opacity: 1,
                      width: "78px",
                      height: "39px",
                      color: "#FF0000",
                      backgroundColor: "#FFC8C8",
                      marginBottom: "10px",
                      marginRight: "10px",
                      textTransform: "capitalize",
                    }}
                    onClick={() => handleReject(request, "CHECK-OUT")}
                  >
                    {t("Reject")}
                  </Button>
                </div>
              </div>
            ))
          ) : flag === "CHECK-IN" &&
            checkInRequests &&
            checkInRequests.length > 0 ? (
            checkInRequests.map((request, index) => (
              <div className="row align-items-center">
                <div className="col-md-8">
                  <ul>
                    <li>
                      <p style={{ fontSize: "0.9rem" }}>
                        {request.name} request to{" "}
                        <span
                          style={{
                            color:
                              request.flag === "CHECK-IN" ? "green" : "red",
                          }}
                        >
                          {request.flag}
                        </span>{" "}
                        <span style={{ color: "#1E73FF" }}>AT</span>{" "}
                        <span style={{ color: "#1E73FF" }}>
                          {formatDateTime(request.requestTime)}
                        </span>
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="col-md-4 text-right">
                  {" "}
                  <Button
                    variant="success"
                    style={{
                      width: "78px",
                      height: "39px",
                      borderRadius: "4px",
                      padding: "14px 20px",
                      opacity: 1,
                      color: "#008000",
                      backgroundColor: "#CBFFC8",
                      marginBottom: "10px",
                      marginRight: "10px",
                      textTransform: "capitalize",
                    }}
                    onClick={() => handleApprove(request, "CHECK-IN")}
                  >
                    {t("Approve")}
                  </Button>
                  <Button
                    variant="danger"
                    style={{
                      opacity: 1,
                      width: "78px",
                      height: "39px",
                      color: "#FF0000",
                      backgroundColor: "#FFC8C8",
                      marginBottom: "10px",
                      marginRight: "10px",
                      textTransform: "capitalize",
                    }}
                    onClick={() => handleApprove(request, "CHECK-IN")}
                  >
                    {t("Reject")}
                  </Button>
                </div>
              </div>
              // <div
              //   key={request.requestId}
              //   className="d-flex justify-content-left"
              // >
              //   <div>
              //     <p style={{ fontSize: "0.9rem" }}>
              //       {request.name} request to{" "}
              //       <span
              //         style={{
              //           color: request.flag === "CHECK-IN" ? "green" : "red",
              //         }}
              //       >
              //         {request.flag}
              //       </span>{" "}
              //       <span style={{ color: "#1E73FF" }}>AT</span>{" "}
              //       <span style={{ color: "#1E73FF" }}>
              //         {formatDateTime(request.requestTime)}
              //       </span>
              //     </p>
              //   </div>
              //   <div className="ml-auto">
              //     <Button
              //       variant="success"
              //       style={{
              //         width: "78px",
              //         height: "39px",
              //         borderRadius: "4px",
              //         padding: "14px 20px",
              //         opacity: 1,
              //         color: "#008000",
              //         backgroundColor: "#CBFFC8",
              //         marginLeft: "1.3rem",
              //         marginBottom: "7px",
              //         marginTop: "-5px",
              //       }}
              //       onClick={() => handleApprove(request, "CHECK-IN")}
              //     >
              //       Approve
              //     </Button>
              //     <Button
              //       variant="danger"
              //       style={{
              //         opacity: 1,
              //         width: "78px",
              //         height: "39px",
              //         color: "#FF0000",
              //         backgroundColor: "#FFC8C8",
              //         marginLeft: "5px",
              //         marginBottom: "7px",
              //         marginTop: "-5px",
              //       }}
              //       onClick={() => handleApprove(request, "CHECK-IN")}
              //     >
              //       Reject
              //     </Button>
              //   </div>
              //   {index < checkInRequests.length - 1 && (
              //     <div style={{ margin: "16px 0" }}></div>
              //   )}
              // </div>
            ))
          ) : (
            <div className="text align-center">{t("No Request Available")}</div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={onHide}>{t("Close")}</Button>
        </Modal.Footer>
      </Modal>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default SupervisorHome;
