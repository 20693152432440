import { useContext, useEffect, useState } from "react";
import { Useprovider } from "../../../stateManagementAPi";
import DataTable from "../../../common components/dataTable";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { jwtDecode } from "jwt-decode";
import ViewDetails from "../companies/viewDetails";
import AdminApi from "./services";
import { CircularProgress } from "@mui/material";

const AdminHome = () => {
  const jwt = jwtDecode(localStorage.getItem("token"));
  const role = jwt.userType;
  const companyId = jwt.companyId;
  const [totalOrganisations, setTotalOrganisation] = useState("");
  const [totalWorkers, setTotalWorkers] = useState("");
  const [totalSupervisors, setTotalSupervisors] = useState("");

  const [freePlans, setFreePlans] = useState("");
  const [startupPlans, setStartupPlans] = useState("");
  const [professionalPlan, setProfessionalPlan] = useState("");
  const [enterprisePlan, setEnterprisePlan] = useState("");

  const [requests, setRequests] = useState([]);
  const [load, setLoad] = useState(false);
  const [show, setShow] = useState(false);
  const [count, setCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");

  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (role === "ADMIN") {
      navigate("/dashboard/company/home");
    } else if (role === "SUPERVISOR") {
      navigate("/dashboard/supervisor/home");
    }
    // else {
    //   navigate("/dashboard/admin/home")
    // }
    console.log("Role", role);
  }, []);

  useEffect(() => {
    getDashboardData();
    getStaticPlans();
    getConstructionRequests();
  }, []);

  //GET DASHBORAD DATA
  const getDashboardData = () => {
    setLoad(true);
    AdminApi.getAdminDashboard(companyId)
      .then((respons) => {
        if (Response.status === 200) {
          console.log("getCompanyStatics", respons.data.data);
          setTotalSupervisors(respons.data.data.countTotalOrganisations);
          setTotalWorkers(respons.data.data.countTotalWorkers);
          setTotalOrganisation(respons.data.data.countTotalSupervisor);
          setCount(1);
          setLoad(false);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 404) {
            console.log("404 - " + error.response.message);
          } else if (error.response.status === 400) {
            console.log("400 - " + error.response.message);
          } else if (error.response.status >= 500) {
            console.log("500 - " + error.response.message);
          }
        } else {
          console.log("Error", error.message);
        }
        setLoad(false);
      });
  };
  const getStaticPlans = () => {
    setLoad(true);
    AdminApi.getStaticPlans(companyId)
      .then((response) => {
        if (Response.status === 200) {
          console.log("getCompanyStatics", response.data.data);
          setFreePlans(response.data.data.countFreePlans);
          setStartupPlans(response.data.data.countStartupPlans);
          setProfessionalPlan(response.data.data.countProfessionalPlan);
          setEnterprisePlan(response.data.data.countEnterpricePlans);
          setCount(1);
          setLoad(false);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 404) {
            console.log("404 - " + error.response.message);
          } else if (error.response.status === 400) {
            console.log("400 - " + error.response.message);
          } else if (error.response.status >= 500) {
            console.log("500 - " + error.response.message);
          }
        } else {
          console.log("Error", error.message);
        }
        setLoad(false);
      });
  };

  //get construction request
  const getConstructionRequests = () => {
    debugger;
    setLoad(true);
    AdminApi.getConstructionRequests()
      .then((response) => {
        if (response.status === 200) {
          debugger;
          const data = response.data.data;
          console.log("getCompanyRequest", response.data.data);
          setRequests(data);
          console.log("request_data", data);
          setLoad(false);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 404) {
            console.log("404 - " + error.response.message);
          } else if (error.response.status === 400) {
            console.log("400 - " + error.response.message);
          } else if (error.response.status >= 500) {
            console.log("500 - " + error.response.message);
          }
        } else {
          console.log("Error", error.message);
        }
        setLoad(false);
      });
  };

  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-12 col-xl-12">
          <h3 className="text-blue mb-4">
            {t("Hey")}, TAPP ME{" "}
            <span className="font-18 text-gray">
              {t("Welcome To The Portal!")}
            </span>
          </h3>
          <div className="row row-cols-1 row-cols-md-3 row-cols-lg-3 row-cols-xl-3 home-box mt-4">
            <div className="col">
              <div className="card radius-10">
                <div className="card-body py-4">
                  <div className="align-items-center">
                    <div>
                      <p className="mb-3 text-white font-18">
                        {t("Total Organizations")}
                      </p>
                      <h2 className="my-1 text-white">
                        {count > 0 ? (
                          load ? (
                            <CircularProgress color="inherit" />
                          ) : (
                            { totalOrganisations }
                          )
                        ) : (
                          0
                        )}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card radius-10">
                <div className="card-body py-4">
                  <div className="align-items-center">
                    <div>
                      <p className="mb-3 text-white font-18">
                        {t("Total Supervisors")}
                      </p>
                      <h2 className="my-1 text-white">
                        {count > 0 ? (
                          load ? (
                            <CircularProgress color="inherit" />
                          ) : (
                            { totalSupervisors }
                          )
                        ) : (
                          0
                        )}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card radius-10">
                <div className="card-body py-4">
                  <div className="align-items-center">
                    <div>
                      <p className="mb-3 text-white font-18">
                        {t("Total Workers")}
                      </p>
                      <h2 className="my-1 text-white">
                        {count > 0 ? (
                          load ? (
                            <CircularProgress color="inherit" />
                          ) : (
                            { totalWorkers }
                          )
                        ) : (
                          0
                        )}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-12 col-xl-12 mt-4">
          <div className="card radius-10 w-100">
            <div className="card-body px-4">
              <label className="font-20">{t("Total Plans Sold")}</label>
              <div className="row row-cols-1 row-cols-md-4 row-cols-lg-4 row-cols-xl-4 mt-4 admin">
                <div className="col">
                  <div className="card radius-10">
                    <div className="card-body py-4">
                      <div className="align-items-center text-center">
                        <div>
                          <p className="mb-3 font-20">Free Plan</p>
                          <p className="mb-0 font-24 text-blue">
                            {count > 0 ? (
                              load ? (
                                <CircularProgress color="inherit" />
                              ) : (
                                { freePlans }
                              )
                            ) : (
                              0
                            )}
                          </p>

                          <p className="my-1 font-14 text-gray">plans sold</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card radius-10">
                    <div className="card-body py-4">
                      <div className="align-items-center text-center">
                        <div>
                          <p className="mb-3 font-20">Startup Plan</p>
                          <p className="mb-0 font-24 text-blue">
                            {count > 0 ? (
                              load ? (
                                <CircularProgress color="inherit" />
                              ) : (
                                { startupPlans }
                              )
                            ) : (
                              0
                            )}
                          </p>
                          <p className="my-1 font-14 text-gray">plans sold</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card radius-10">
                    <div className="card-body py-4">
                      <div className="align-items-center text-center">
                        <div>
                          <p className="mb-3 font-20">Professional Plan</p>
                          <p className="mb-0 font-24 text-blue">
                            {count > 0 ? (
                              load ? (
                                <CircularProgress color="inherit" />
                              ) : (
                                { professionalPlan }
                              )
                            ) : (
                              0
                            )}
                          </p>
                          <p className="my-1 font-14 text-gray">plans sold</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card radius-10">
                    <div className="card-body py-4">
                      <div className="align-items-center text-center">
                        <div>
                          <p className="mb-3 font-20">Enterprise Plan</p>
                          <p className="mb-0 font-24 text-blue">
                            {count > 0 ? (
                              load ? (
                                <CircularProgress color="inherit" />
                              ) : (
                                { enterprisePlan }
                              )
                            ) : (
                              0
                            )}
                          </p>
                          <p className="my-1 font-14 text-gray">plans sold</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg-12 col-xl-12">
          <div className="card radius-10 w-100">
            <div className="card-body px-lg-5">
              <div className="row row-cols-1 row-cols-lg-2 g-3 align-items-center pb-3 mb-3">
                <div className="col">
                  <h5 className="mb-0 font-20 mt-4">
                    {t("Organizations Registration Requests")}
                  </h5>
                </div>
              </div>
              <div className="card radius-15">
                <div className="card-body border radius-10">
                  <div className="ml-3">
                    {requests.slice(-3).map((item, index) => (
                      <div className="row mb-3" key={index}>
                        <div className="col-12 col-lg-6 col-xl-6 d-flex align-items-center">
                          {/* <img src="assets/images/imgred.png" alt="images" /> */}
                          <div className="ms-5">
                            <p className="mb-0 font-20 font-weight-500 text-dark">
                              {item.companyName}
                            </p>
                          </div>
                        </div>
                        <div className="col-12 col-lg-6 col-xl-6 px-2 mr-1 text-right justify-content-end">
                          <ViewDetails data={item} />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="row">
                {" "}
                <div className="col-12 col-lg-12 col-xl-12 px-2 text-center mt-4">
                  <a
                    href="/dashboard/admin/companies/viewrequests"
                    className="text-blue font-16 ms-2 mb-0"
                  >
                    {/* {t("View all")} */}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Company Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12 col-lg-12 col-xl-12 mb-3">
              {/* <div className="px-4 pt-4 text-center">
                <img src="/assets/img/avatardefault_92824.png" style={{ width: "100px", height: "auro" }} />
              </div> */}
              <div className="px-lg-4 pt-5 px-3">
                <div className="row">
                  <div className="col-md-6 col-12  mb-3">
                    <div className="border-bottom2 height-50">
                      <span className="font-14 text-dark w_50">
                        {" "}
                        Company name
                      </span>
                      <span className="font-14 text-gray text-right w_50">
                        {" "}
                        {requests.name}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 col-12  mb-3">
                    <div className="border-bottom2 height-50">
                      <span className="font-14 text-dark w_50">
                        {" "}
                        Phone number
                      </span>
                      <span className="font-14 text-gray text-right w_50">
                        {" "}
                        {requests.number}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-12  mb-3">
                    <div className="border-bottom2 height-50">
                      <span className="font-14 text-dark w_50"> Email</span>
                      <span className="font-14 text-gray text-right w_50">
                        {" "}
                        {requests.email}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 col-12  mb-3">
                    <div className="border-bottom2 height-50">
                      <span className="font-14 text-dark w_50">
                        {" "}
                        Personal/Social security number
                      </span>
                      <span className="font-14 text-gray text-right w_50">
                        {" "}
                        {requests.ssn}
                      </span>
                    </div>
                  </div>
                </div>
                {/* {location.pathname === "/dashboard/admin/companies" ? (
                  <div className="row">
                    <div className="col-md-6 col-12  mb-3">
                      <div className="border-bottom2 height-50">
                        <span className="font-14 text-dark w_50">
                          {" "}
                          Corporate Reg no.
                        </span>
                        <span className="font-14 text-gray text-right w_50">
                          {" "}
                          {requests.registrationNo}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6 col-12  mb-3">
                      <div className="border-bottom2 height-50">
                        <span className="font-14 text-dark w_50">
                          {" "}
                          Tax Agency no.
                        </span>
                        <span className="font-14 text-gray text-right w_50">
                          {" "}
                          {requests.taxAgencyno}
                        </span>
                      </div>
                    </div>
                  </div>
                ) : null} */}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AdminHome;
