import http from "../../../../baseURL/httpcommon";

//------------------ Chekin Approve/Reject-----------

const ApproveRejectRequest = (requestType, requestId, status) => {
  debugger;
  console.log("requestType", requestType);
  console.log("requestId", requestId);
  console.log("status", status);
  return http.put(
    `/api/checkinout/${requestType}/${requestId}/status?status=${status}`
  );
};

//------- fetch all checkin/Checkout requests ---------------

const AllCheckINOUTRequests = (supervisorId) => {
  debugger;
  return http.get(`api/checkinout/checkin/list/${supervisorId}`);
};

const ApproveAllCheckOutRequests = (supervisorId) => {
  const data = "testdata";
  return http.get("API URL", data);
};
//count records
const getDashboardCount = (workforceId) => {
  debugger;
  return http.get(`dashboard/supervisor/${workforceId}`);
};
//timesheet
const getHomeTimeSheet = (
  supervisorId,
  name,
  formattedDate,
  requestTypeEnum,
  pageNumber,
  pageSize
) => {
  debugger;
  return http.get(
    `/timesheet/list/supervisor?supervisorId=${supervisorId}&Name=${name}&date=${formattedDate}&requestTypeEnum=${requestTypeEnum}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=timesheetId&sortDir=desc`
  );
};
const HomeServices = {
  ApproveRejectRequest,
  ApproveAllCheckOutRequests,
  AllCheckINOUTRequests,
  getDashboardCount,
  getHomeTimeSheet,
};

export default HomeServices;
