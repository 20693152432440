import { Form } from "react-bootstrap";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import SettingApi from "./services";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const DevloperSetting = () => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");
  const [changesMade, setChangesMade] = useState(true);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const dataJson = {
    id: 1,
    webMasterMail: "",
    developerEmail: "",
    copyToWebMaster: "",
    testMode: "",
    copyToDeveloper: "",
  };
  const [data, setData] = useState(dataJson);

  function setValue(e) {
    const { name, value, checked } = e.target;
    if (
      name == "copyToWebMaster" ||
      name == "copyToDeveloper" ||
      name == "testMode"
    ) {
      setData({
        ...data,
        [name]: checked,
      });
      setChangesMade(false);
    } else {
      setData({
        ...data,
        [name]: value,
      });
      setChangesMade(false);
    }

    console.log(data);
  }
  function devSetGet() {
    SettingApi.devSetGet().then((respons) => {
      const responsData = respons.data.data;
      console.log(responsData);
      setData({
        ...data,
        webMasterMail: responsData.webMasterMail,
        developerEmail: responsData.developerEmail,
        copyToWebMaster: responsData.copyToWebMaster,
        testMode: responsData.testMode,
        copyToDeveloper: responsData.copyToDeveloper,
      });
    });
  }
  function devSetUpdate() {
    if (
      data?.developerEmail?.trim() === "" ||
      data?.webMasterMail?.trim() === ""
    ) {
      // If any required field is empty, show error message
      setMessage("Fill all required fields.");
      setSeverity("error");
      setOpen(true);
      return;
    }
    SettingApi.devSetUpdate(data)
      .then((respons) => {
        console.log(respons);
        if (respons.data.status == true) {
          setOpen(true);
          setMessage(respons.data.message);
          setSeverity("success");
          setChangesMade(true);
        }
      })
      .catch((e) => {
        if (e.response.status == 404) {
          setOpen(true);
          setMessage(e.response.data.message);
          setSeverity("error");
        }
      });
  }

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      devSetUpdate();
    }

    setValidated(true);
  };
  useEffect(() => {
    devSetGet();
  }, []);
  return (
    <>
      <div className="card radius-10 p-4 px-3">
        <div className="card-header bg-transparent border-0">
          <div className="row g-3 align-items-center">
            <div className="col-12 col-md-12 col-lg-12">
              <h5 className="mb-0">{t("Developer Settings")}</h5>
            </div>
          </div>
        </div>
        <div className="card-body profile">
          <Form noValidate validated={validated}>
            <div className="row">
              <div className="col-12 col-md-4 col-lg-4 mb-4">
                <Form.Group controlId="validationCustom01">
                  <Form.Label>{t("Web Master Email")}</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Web Master Email"
                    value={data.webMasterMail}
                    name="webMasterMail"
                    onChange={setValue}
                  />
                  <Form.Control.Feedback></Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-12 col-md-8 col-lg-8 mb-4">
                <div className="form-check pt-4 mt-2">
                  <Form.Check
                    type="switch"
                    id="copyToWebMaster"
                    label={t("Send Copy to web Master")}
                    checked={data.copyToWebMaster}
                    name="copyToWebMaster"
                    onChange={setValue}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-4 col-lg-4 mb-4">
                <Form.Group controlId="validationCustom01">
                  <Form.Label>{t("Developer Email")}</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Developer Email"
                    value={data.developerEmail}
                    name="developerEmail"
                    onChange={setValue}
                  />
                  <Form.Control.Feedback></Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-12 col-md-8 col-lg-8 mb-4 d-flex">
                <div className="form-check pt-4 mt-2 me-4">
                  <Form.Check
                    type="switch"
                    id="testMode"
                    label={t("Test Mode")}
                    checked={data.testMode}
                    name="testMode"
                    onChange={setValue}
                  />
                </div>

                <div className="form-check pt-4 mt-2">
                  <Form.Check
                    type="switch"
                    id="copyToDeveloper"
                    label={t("Send Copy to Developer")}
                    checked={data.copyToDeveloper}
                    name="copyToDeveloper"
                    onChange={setValue}
                  />
                </div>
              </div>
            </div>
          </Form>

          <div className="row">
            <div className="col-12 col-md-4 col-lg-4 mb-4">
              <button
                disabled={changesMade}
                type="button"
                class="btn btn-primary mt-4 px-4"
                onClick={handleSubmit}
              >
                {t("Save")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default DevloperSetting;
