import { Navigate, createBrowserRouter, useLocation } from "react-router-dom";
import LandingPage from "./landingPage";
import SupervisorLogin from "./authentication/supervisor/login";
import AdminLogin from "./authentication/admin/login";
import AdminHome from "./dashboard/module/admin/home";
import CompanyHome from "./dashboard/module/company/home";
import SupervisorHome from "./dashboard/module/supervisor/home";
import AdminCompanies from "./dashboard/module/admin/companies";
import Dashboard from "./dashboard";
import CompanyLogin from "./authentication/company/login";
import AdminPlans from "./dashboard/module/admin/plans";
import CompanySite from "./dashboard/module/company/sites";
import CompanyRequest from "./dashboard/module/company/requests";
import CompanyMessage from "./dashboard/module/company/messages";
import CompanySubcription from "./dashboard/module/company/subscriptions";
import CompanySetting from "./dashboard/module/company/settings";
import CompanyProfile from "./dashboard/module/company/myProfile";
import ForgetPassword from "./authentication/forgetpassword";
import SupervisorForgotPassword from "./authentication/supervisor/forgotPassword";

import ChangePassword from "./dashboard/module/admin/settings/changePassword";
import SettingCompany from "./dashboard/module/admin/settings/companySetting";
import AdminSmtp from "./dashboard/module/admin/settings/smtp";
import DevloperSetting from "./dashboard/module/admin/settings/devloperSetting";
import ApplicationSetting from "./dashboard/module/admin/settings/applicationSetting";
import ViewRequests from "./dashboard/module/admin/companies/viewRequests";
import LanguagePreference from "./dashboard/module/admin/settings/languagePreference";
import AddWorker from "./dashboard/module/company/workers/addWorker";
import EditWorker from "./dashboard/module/company/workers/editWorker";
import MyProfile from "./dashboard/module/supervisor/myProfile";
import TermCondition from "./dashboard/module/company/settings/termConditions";
import PrivacyPolicies from "./dashboard/module/company/settings/privacyPolicies";
import CheckInOutRequest from "./dashboard/module/company/home/checkInOutRequest";

import TaskReport from "./dashboard/module/supervisor/reports/taskReport";
import GeneralReport from "./dashboard/module/supervisor/reports/generalReport";
import Request from "./dashboard/module/supervisor/requests";
import Settings from "./dashboard/module/supervisor/settings";
import Tasks from "./dashboard/module/supervisor/tasks";
import TimeSheet from "./dashboard/module/supervisor/timesheet";
import WorkersScreen from "./dashboard/module/supervisor/workers";
import Workers from "./dashboard/module/company/workers";
import Payment from "./payment";
import WorkFroce from "./dashboard/module/company/workFroce";
import AddWorkFroce from "./dashboard/module/company/workFroce/addWorkFroce";
import PrivateRoute from "./PrivateRoute";
import SupervisorChangePassword from "./dashboard/module/supervisor/settings/changePassword";
import CompanyChangePassword from "./dashboard/module/company/settings/changePassword";
import ViewWokers from "./dashboard/module/company/workers/viewWokers";
import WorkerSkills from "./dashboard/module/admin/settings/workerSkills";
import AssignTask from "./dashboard/module/supervisor/tasks/assignTask";
import SetNewPassword from "./authentication/supervisor/setPassword";
import SupervisorMessage from "./dashboard/module/supervisor/messages";
import WorkerTask from "./dashboard/module/supervisor/workers/workertask";
import TimeLine from "./dashboard/module/supervisor/timesheet/timeLine";
import TermConditions from "./dashboard/module/supervisor/settings/termConditions";
import PrivacyPolicy from "./dashboard/module/supervisor/settings/privacyPolicies";
import NotFoundPage from "./pageNotFound";
// const token = localStorage.getItem("token");
// console.log("token", token);

// const isValidToken = () => {
//   const token = localStorage.getItem("token");
//   console.log("Toek", token)
//   // Perform token validation here, e.g., check token expiration, etc.
//   return token !== null; // Example: Just checking if the token exists
// };

// const PrivateRoute = ({ element }) => {
//   console.log("isValidToken()", isValidToken())
//   return isValidToken() ? element : <Navigate to="/" replace={true} />;
// };

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/supervisor",
    element: <SupervisorLogin />,
  },
  {
    path: "/company",
    element: <CompanyLogin />,
  },
  {
    path: "/admin",
    element: <AdminLogin />,
  },
  {
    path: "/forgetPassword",
    element: <ForgetPassword />,
  },
  {
    path: "/dashboard/supervisor/forgotpassword",
    element: <SupervisorForgotPassword />,
  },
  //setNewPassword
  {
    path: "/dashboard/supervisor/setnewpassword",
    element: <SetNewPassword />,
  },
  {
    path: "/payment",
    element: <Payment />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
    children: [
      {
        path: "/dashboard/admin/home",
        element: <PrivateRoute element={<AdminHome />} fallbackPath="/admin" />,
      },
      {
        path: "/dashboard/company/home",
        element: (
          <PrivateRoute element={<CompanyHome />} fallbackPath="/company" />
        ),
      },
      {
        path: "/dashboard/supervisor/home",
        element: (
          <PrivateRoute
            element={<SupervisorHome />}
            fallbackPath="/supervisor"
          />
        ),
      },

      // {
      //   path: "/dashboard/admin/settings/changePassword",
      //   element: <PrivateRoute element={<SupervisorChangePassword />} />,
      // },
      {
        path: "/dashboard/admin/companies",
        element: (
          <PrivateRoute element={<AdminCompanies />} fallbackPath="/admin" />
        ),
      },
      {
        path: "/dashboard/admin/companies/viewrequests",
        element: (
          <PrivateRoute element={<ViewRequests />} fallbackPath="/admin" />
        ),
      },
      {
        path: "/dashboard/admin/plans",
        element: (
          <PrivateRoute element={<AdminPlans />} fallbackPath="/admin" />
        ),
      },
      {
        path: "/dashboard/admin/changepassword",
        element: (
          <PrivateRoute element={<ChangePassword />} fallbackPath="/admin" />
        ),
      },

      //-------------company----------------
      {
        path: "/dashboard/company/requests",
        element: (
          <PrivateRoute element={<CompanyRequest />} fallbackPath="/company" />
        ),
      },
      {
        path: "/dashboard/company/workforce",
        element: (
          <PrivateRoute element={<WorkFroce />} fallbackPath="/company" />
        ),
      },
      {
        path: "/dashboard/company/addworkforce",
        element: (
          <PrivateRoute element={<AddWorkFroce />} fallbackPath="/company" />
        ),
      },
      {
        path: "/dashboard/company/messages",
        element: (
          <PrivateRoute element={<CompanyMessage />} fallbackPath="/company" />
        ),
      },
      {
        path: "/dashboard/company/subscriptions",
        element: (
          <PrivateRoute
            element={<CompanySubcription />}
            fallbackPath="/company"
          />
        ),
      },
      {
        path: "/dashboard/company/sites",
        element: (
          <PrivateRoute element={<CompanySite />} fallbackPath="/company" />
        ),
      },
      {
        path: "/dashboard/company/settings",
        element: (
          <PrivateRoute element={<CompanySetting />} fallbackPath="/company" />
        ),
      },
      {
        path: "/dashboard/company/myProfile",
        element: (
          <PrivateRoute element={<CompanyProfile />} fallbackPath="/company" />
        ),
      },
      {
        path: "/dashboard/company/wokers",
        element: <PrivateRoute element={<Workers />} fallbackPath="/company" />,
      },
      {
        path: "/dashboard/company/viewWokers",
        element: (
          <PrivateRoute element={<ViewWokers />} fallbackPath="/company" />
        ),
      },
      {
        path: "/dashboard/company/addwoker",
        element: (
          <PrivateRoute element={<AddWorker />} fallbackPath="/company" />
        ),
      },
      {
        path: "/dashboard/company/editworker",
        element: (
          <PrivateRoute element={<EditWorker />} fallbackPath="/company" />
        ),
      },
      {
        path: "/dashboard/company/termconditions",
        element: (
          <PrivateRoute element={<TermCondition />} fallbackPath="/company" />
        ),
      },
      {
        path: "/dashboard/company/checkinoutrequest",
        element: (
          <PrivateRoute
            element={<CheckInOutRequest />}
            fallbackPath="/company"
          />
        ),
      },

      {
        path: "/dashboard/company/privacypolicy",
        element: (
          <PrivateRoute
            element={<PrivacyPolicies />}
            fallbackPath="/companay"
          />
        ),
      },

      //-------------company----------------
      {
        path: "/dashboard/admin/settings/SettingCompany",
        element: (
          <PrivateRoute element={<SettingCompany />} fallbackPath="/admin" />
        ),
      },
      {
        path: "/dashboard/admin/settings/smtp",
        element: <PrivateRoute element={<AdminSmtp />} fallbackPath="/admin" />,
      },
      {
        path: "/dashboard/admin/settings/devloperSetting",
        element: (
          <PrivateRoute element={<DevloperSetting />} fallbackPath="/admin" />
        ),
      },
      {
        path: "/dashboard/admin/settings/devloperSetting",
        element: (
          <PrivateRoute element={<DevloperSetting />} fallbackPath="/admin" />
        ),
      },

      {
        path: "/dashboard/admin/settings/language",
        element: (
          <PrivateRoute
            element={<LanguagePreference />}
            fallbackPath="/admin"
          />
        ),
      },
      {
        path: "/dashboard/admin/settings/appSetting",
        element: (
          <PrivateRoute
            element={<ApplicationSetting />}
            fallbackPath="/admin"
          />
        ),
      },
      {
        path: "/dashboard/admin/settings/workerskill",
        element: (
          <PrivateRoute element={<WorkerSkills />} fallbackPath="/admin" />
        ),
      },
      {
        path: "/dashboard/company/changepassword",
        element: (
          <PrivateRoute
            element={<CompanyChangePassword />}
            fallbackPath="/company"
          />
        ),
      },

      // ---------------------worker/supervisor----------------------------

      {
        path: "/dashboard/supervisor/myprofile",
        element: (
          <PrivateRoute element={<MyProfile />} fallbackPath="/supervisor" />
        ),
      },
      // {
      //   path: "/dashboard/supervisor/reports",
      //   element: (
      //     <PrivateRoute element={<Reports />} fallbackPath="/supervisor" />
      //   ),
      // },
      {
        path: "/dashboard/supervisor/taskreport",
        element: (
          <PrivateRoute element={<TaskReport />} fallbackPath="/supervisor" />
        ),
      },
      {
        path: "/dashboard/supervisor/generalreport",
        element: (
          <PrivateRoute
            element={<GeneralReport />}
            fallbackPath="/supervisor"
          />
        ),
      },
      {
        path: "/dashboard/supervisor/workertask",
        element: (
          <PrivateRoute element={<WorkerTask />} fallbackPath="/supervisor" />
        ),
      },
      {
        path: "/dashboard/supervisor/request",
        element: (
          <PrivateRoute element={<Request />} fallbackPath="/supervisor" />
        ),
      },
      {
        path: "/dashboard/supervisor/settings",
        element: (
          <PrivateRoute element={<Settings />} fallbackPath="/supervisor" />
        ),
      },
      {
        path: "/dashboard/supervisor/messages",
        element: (
          <PrivateRoute
            element={<SupervisorMessage />}
            fallbackPath="/supervisor"
          />
        ),
      },
      {
        path: "/dashboard/supervisor/changepassword",
        element: (
          <PrivateRoute
            element={<SupervisorChangePassword />}
            fallbackPath="/supervisor"
          />
        ),
      },

      {
        path: "/dashboard/supervisor/tasks",
        element: (
          <PrivateRoute element={<Tasks />} fallbackPath="/supervisor" />
        ),
      },
      {
        path: "/dashboard/supervisor/assigntask",
        element: (
          <PrivateRoute element={<AssignTask />} fallbackPath="/supervisor" />
        ),
      },
      {
        path: "/dashboard/supervisor/timesheet",
        element: (
          <PrivateRoute element={<TimeSheet />} fallbackPath="/supervisor" />
        ),
      },
      {
        path: "/dashboard/supervisor/timeline",
        element: (
          <PrivateRoute element={<TimeLine />} fallbackPath="/supervisor" />
        ),
      },
      {
        path: "/dashboard/supervisor/termconditions",
        element: (
          <PrivateRoute
            element={<TermConditions />}
            fallbackPath="/supervisor"
          />
        ),
      },
      {
        path: "/dashboard/supervisor/privacypolicy",
        element: (
          <PrivateRoute
            element={<PrivacyPolicies />}
            fallbackPath="/supervisor"
          />
        ),
      },
      {
        path: "/dashboard/supervisor/workers",
        element: (
          <PrivateRoute
            element={<WorkersScreen />}
            fallbackPath="/supervisor"
          />
        ),
      },
    ],
  },
  {
    path: "*",
    element: <NotFoundPage />,
    fallbackPath: "/",
  },
]);

export default router;
