import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import RequestServices from "./services";
import { jwtDecode } from "jwt-decode";
import moment from "moment";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import MuiAlert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";

const Request = () => {
  const [showModal, setShowModal] = useState(false);
  const [requests, setRequests] = useState([]);
  const [time, setTime] = useState("");
  const [open, setOpen] = useState();
  const [severity, setSeverity] = useState();
  const [message, setMessage] = useState();
  const [flag, setFlag] = useState();
  const [showRequestModal, setShowRequestModal] = useState();
  const { t } = useTranslation();
  const [data, setData] = useState({
    employeeId: "",
    name: "",
    time: "",
    reason: "",
  });

  const [approve, setApprove] = useState({}); //to hold the request data

  console.log("data_state", data);
  const token = localStorage.getItem("token");
  const decodedToken = jwtDecode(token);
  const supervisorId = decodedToken.id;

  useEffect(() => {
    if (showModal) {
      // Update 'data' state when the modal is opened
      setData({
        ...data,
        employeeId: data.name,
        name: data.name,
        reason: data.reason,
        time: data.time,
      });
    }
  }, []);

  //request reject
  const handleRejectRequest = (requestFlag) => {
    debugger;
    const requestId = approve.requestId;
    const requestType = approve.flag;

    // Declare status outside the if-else blocks
    let status = null;

    if (requestType === "CHECK-IN" || requestType === "CHECK-OUT") {
      if (requestFlag === "REJECT") {
        status = "DENIED";
      } else {
        status = "APPROVED";
      }
    } else if (requestType === "OUTSIDE_REQUEST") {
      if (requestFlag === "REJECT") {
        status = "DENIED";
      } else {
        status = "APPROVED";
      }
    }

    if (requestId && requestType) {
      RequestServices.RejectCheckInRequest(requestType, requestId, status)
        .then((response) => {
          debugger;
          if (response.status === 200) {
            debugger;
            setMessage(response.data.message);
            console.log("response_data_message", response.data.message);
            setSeverity("success");
            // setOpen(true);
            setShowModal(false);
            setShowRequestModal(false);
            getAllRequests();
          } else {
            console.error("Error in status update");
            setMessage(response.data.message);
            setSeverity("error");
            setOpen(true);
          }
        })
        .catch((error) => {
          console.error("Error while processing request:", error);
          setMessage(error);
          setSeverity("error");
          setOpen(true);
        });
    }
  };

  //request Approve
  const handleApproveRequest = (requestFlag) => {
    debugger;
    const requestId = approve.requestId;
    const requestType = approve.flag;

    // Declare status using let
    let status = null;

    if (requestType === "CHECK-IN" || requestType === "CHECK-OUT") {
      if (requestFlag === "REJECT") {
        status = "DENIED";
      } else {
        status = "APPROVED";
      }
    } else if (requestType === "OUTSIDE-REQUEST") {
      if (requestFlag === "APPROVED") {
        status = "APPROVED";
      } else {
        status = "DENIED";
      }
    }

    if (requestId && requestType) {
      debugger;
      RequestServices.ApproveChekInRequest(requestType, requestId, status)
        .then((response) => {
          debugger;
          if (response.status === 200) {
            debugger;
            setMessage(response.data.message);
            console.log("response_data_message", response.data.message);
            setSeverity("success");
            setOpen(true);
            setShowModal(false);
            setShowRequestModal(false);
            getAllRequests();
          } else {
            console.error("Error in status update");
            setMessage(response.data.message);
            setSeverity("error");
            setOpen(true);
          }
        })
        .catch((error) => {
          console.error("Error while processing request:", error);
          setMessage(error.response.data.message);
          setSeverity("error");
          setOpen(true);
        });
    }
  };

  const handleApproveMessageModal = (request) => {
    debugger;
    console.log("request_data", request);
    setApprove(request);
    setFlag("APPROVE");
    setShowModal(true);
  };

  const handleRejectMessageModal = (request) => {
    debugger;
    setApprove(request);
    setFlag("REJECT");
    console.log("request Reject", request);
    setShowModal(true);
  };

  const getAllRequests = () => {
    debugger;
    RequestServices.AllCheckINOUTRequests(supervisorId)
      .then((response) => {
        if (response.status === 200) {
          const responseData = response.data;
          console.log("respData_request", responseData);
          console.log("flag", responseData.flag);

          if (
            responseData.status &&
            responseData.data &&
            responseData.data.length > 0
          ) {
            const filteredRequests = responseData.data;
            // Update state outside the map function
            setRequests(filteredRequests);
            setData(filteredRequests);
            console.log("filteredRequests", filteredRequests);
          } else {
            console.log("No data received or invalid response");
          }
        } else {
          console.log("Error: Received response with status", response.status);
        }
      })
      .catch((error) => {
        console.error("Error while fetching data:", error);
        if (error.response && error.response.status === 404) {
          setMessage(error.response.data.message);
          setSeverity("error");
          // setOpen(true);
        }
      });
  };

  useEffect(() => {
    getAllRequests();
  }, []);

  function formatDateTime(dateTimeString) {
    const dateTime = new Date(dateTimeString);

    // Check if the date is valid
    if (isNaN(dateTime.getTime())) {
      return "Invalid Date";
    }

    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      hourCycle: "h23",
    };
    let formattedDateTime = dateTime.toLocaleString("en-US", options);
    formattedDateTime = formattedDateTime.replace(/,\s*/, " - ");
    // Convert AM/PM to uppercase
    formattedDateTime = formattedDateTime.replace(/\b(?:am|pm)\b/gi, (match) =>
      match.toUpperCase()
    );

    return formattedDateTime;
    // return dateTime.toLocaleString("en-GB", options);
  }

  //-------------View  Details modal Accept and Reject functions -------------------
  const handleModalAccept = (request) => {
    debugger;
    setApprove(request);
    console.log("approve_data_request", request);
    console.log("approve_data_request", approve);
    handleApproveRequest("APPROVED");
    // setShowRequestModal(false); //close the modal for Accept/Reject
  };

  const handleModalReject = (request) => {
    debugger;
    setApprove(request);
    console.log("requestRejectParam", request);
    console.log("approve_data_request", approve);
    handleApproveRequest("REJECT");
    // setShowRequestModal(false);
  };

  const handleClose = () => {
    debugger;
    setShowModal(false);
    setOpen(false);
  };

  return (
    <>
      <div className="card radius-10 p-4 px-3">
        <div className="row g-3 align-items-center">
          <div className="col-4 col-md-2 col-lg-2">
            <h5 className="mb-5">{t("Requests")}</h5>
          </div>
          <div className="col-8 col-md-10 col-lg-10">
            <div className="text-right">
              {/* <button
                  className="btn btn-outline-secondary dropdown-toggle btn-blue px-3 mb-3"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {t("All")}
                </button>
                <ul className="dropdown-menu dropdown-menu-end border-b">
                  <li>
                    <a className="dropdown-item" href="#">
                      {t("Approved")}
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      {t("Rejected")}
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      {t("Pending")}
                    </a>
                  </li>
                </ul> */}
            </div>
          </div>
        </div>
        {requests && requests.length > 0 ? (
          requests.map((request) => (
            <div key={request.id}>
              {!(request.flag === "OUTSIDE-REQUEST") ? (
                <div className="card radius-15">
                  <div className="card-body card-border align-items-center">
                    <div className="float-end text-dark">
                      <button
                        type="button"
                        className="btn btn-success2 px-3 px-3"
                        data-bs-toggle="modal"
                        data-bs-target="#confirmationModal"
                        style={{ marginRight: "5px" }}
                        onClick={() => handleApproveMessageModal(request)}
                      >
                        Approve
                      </button>
                      <button
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#confirmationModal"
                        className="btn btn-danger2 px-3"
                        onClick={() => handleRejectMessageModal(request)}
                      >
                        Reject
                      </button>
                    </div>

                    <p className="card-text pt-2">
                      <b>{request.name}</b> requests to{" "}
                      <span
                        style={{
                          color:
                            request.flag === "CHECK-IN" ? "#008000" : "#FF1C1C",
                        }}
                      >
                        {request.flag}
                      </span>{" "}
                      <span className="text-primary">
                        AT {formatDateTime(request.requestTime)}
                      </span>
                    </p>
                  </div>
                </div>
              ) : null}

              {request.flag === "OUTSIDE-REQUEST" && (
                <div className="card radius-15">
                  <div className="card-body card-border align-items-center">
                    <div className="float-end text-dark">
                      <button
                        type="button"
                        className="btn btn-primary px-3 font-14 p-2"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal2"
                        // onClick={handleModalPopup}
                      >
                        View Details
                      </button>
                    </div>
                    <p className="card-text pt-2">
                      <b>{request.name}</b> requesting to go outside the
                      Geofence boundary
                    </p>
                  </div>
                </div>
              )}
            </div>
          ))
        ) : (
          <p className="ms-3">No Request Found</p>
        )}
      </div>
      {/* request modal for outside request */}

      {requests && requests.length > 0
        ? requests.map((request) => (
            <div
              className={`modal fade ${showRequestModal ? "show" : ""}`}
              id={`exampleModal2`}
              tabIndex="-1"
              aria-labelledby={`exampleModalLabel_selected`}
              aria-hidden={showModal ? "false" : "true"}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content text-center">
                  <div className="modal-header border-0 mb-0 p-3 pb-0">
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss={`modal`}
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body font-20 text-dark px-4">
                    <p className="font-18 text-dark text-center mb-5">
                      <i className="fadeIn animated bx bx-arrow-back pe-3"></i>{" "}
                      Request for going outside the geofence from{" "}
                      <span className="text-blue">
                        {request.name}(#{request.employeeId})
                      </span>
                    </p>
                    <div className="row">
                      <div className="col-12 mb-3">
                        <label
                          className="form-label"
                          style={{ marginRight: "23rem" }}
                        >
                          Reason
                          {console.log("request-reason", request.reason)}
                        </label>
                        <p>{request.reason}</p>

                        <div className="mt-4">
                          <button
                            type="button"
                            className="btn btn-success2 px-3 py-3 w-100 mb-3 font-15"
                            onClick={() => handleModalAccept(request)}
                          >
                            {t("Accept")}
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger2 px-3 py-3 w-100 mb-3 font-15"
                            onClick={() => handleModalReject(request)}
                          >
                            {t("Reject")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        : null}

      {/* modal for check-IN , CHECK-OUT  Request */}
      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        id="confirmationModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content text-center">
            {flag === "APPROVE" ? (
              <div className="modal-body font-18 p-5 text-dark">
                {t("Are you sure you want to approve the request?")}
              </div>
            ) : (
              <div className="modal-body font-18 p-5 text-dark">
                {t("Are you sure you want to reject the request?")}
              </div>
            )}

            <div className="pb-5 border-0">
              {flag === "APPROVE" ? (
                <button
                  type="button"
                  className="btn btn-primary px-4 py-3 w-25"
                  style={{ marginRight: "1.5rem" }}
                  onClick={() => handleApproveRequest("APPROVE")}
                >
                  {t("Yes")}
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary px-4 py-3 w-25"
                  style={{ marginRight: "1.5rem" }}
                  onClick={() => handleRejectRequest("REJECT")}
                >
                  {t("Yes")}
                </button>
              )}

              <button
                type="button"
                className="btn btn-danger px-4 py-3 w-25 "
                data-bs-dismiss="modal"
              >
                {t("No")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={1000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleClose}
        >
          <MuiAlert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </MuiAlert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default Request;
