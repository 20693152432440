import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Card, Dropdown } from "react-bootstrap";
import SettingServices from "./services";
import { jwtDecode } from "jwt-decode";
import { Label } from "@mui/icons-material";
import { Link, Navigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

//------language change-----------

// import i18n from "i18next";
// import { useTranslation, initReactI18next } from "react-i18next";
// import tEn from "../locales/en/translation.json";
// import tfr from "../locales/fr/translation.json";
// import tse from "../locales/se/translation.json";

import {
  auth,
  providergl,
  providerfb,
} from "../../../../authentication/supervisor/config";
import { signInWithPopup } from "firebase/auth";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
// import { Link } from "react-router-dom";

const SettingsPage = () => {
  // fetch supervisorId from Token then making call to getSettingData function
  const { t } = useTranslation();
  const token = localStorage.getItem("token");
  const decodetoken = jwtDecode(token);
  const jwt = decodetoken;

  console.log("jwt", jwt);
  const supervisorId = decodetoken.id;
  console.log("supervisorId", supervisorId);

  //---------language Change ----------------
  // const lang = localStorage.getItem("lang");
  // if (lang == null) {
  //   localStorage.setItem("lang", "en");
  // }

  // i18n
  //   .use(initReactI18next) // passes i18n down to react-i18next
  //   .init({
  //     resources: {
  //       en: {
  //         translation: tEn,
  //       },
  //       fr: {
  //         translation: tfr,
  //       },
  //       se: {
  //         translation: tse,
  //       },
  //     },
  //     lng: lang,
  //     fallbackLng: "en",

  //     interpolation: {
  //       escapeValue: false,
  //     },
  //   });

  const [isFacebookConnected, setIsFacebookConnected] = useState(false);
  const [isGoogleConnected, setIsGoogleConnected] = useState(false);

  const [notificationStatus, setNotificationStatus] = useState("Enable");
  const [notification, setNotification] = useState("");
  const [message, setMessage] = useState();
  const [severity, setSeverity] = useState("error");
  const [open, setOpen] = useState(false);

  //--- if the status is disable then mail will not bw send to the user else auto mail will be sint to the user
  const handleNotificationChange = (status) => {
    // debugger;
    console.log("status", status);
    if (status === "Enable") {
      handleNotification();
      setNotificationStatus(status);
    } else {
      setNotificationStatus(status);
    }
  };
  const handleGoogleLink = async () => {
    debugger;

    try {
      const prviderTypee = "google";
      const data = await signInWithPopup(auth, providergl);
      console.log("data", data);
      // Assuming data.user.providerData is an array
      const providerID = data.user.providerData[0].uid;
      const providerName = data.user.email;
      console.log("providerID:", providerID);
      const fetchdata = {
        email: providerName,
        userId: supervisorId,
        companyId: decodetoken.companyId,
        providerType: prviderTypee,
        providerId: providerID,
      };
      SettingServices.linkSocialAccount(fetchdata)
        .then((response) => {
          debugger;
          if (response.status === 200) {
            setMessage(response.data.data.message);
            setSeverity("success");
            setOpen(true);
            setIsGoogleConnected(true);
          }
          // if (response.status === 302) {
          //   setMessage(response.data.message);
          //   setSeverity("error");
          //   setOpen(true);
          //   setIsGoogleConnected(false);
          // }
        })
        .catch((error) => {
          console.log("error", error);
          setMessage(error.response.data.message);
          setSeverity("error");
          setOpen(true);
        });
    } catch (error) {
      // Handle errors, including cancelled-popup-request
      if (error.code === "auth/cancelled-popup-request") {
        // User closed the popup
        console.log("Popup closed by the user");
      } else {
        console.error("Authentication error:", error);
      }
    }
  };

  //handle Social Linking facebook
  const handleFacebookLink = async () => {
    debugger;
    try {
      const prviderTypee = "google";
      const data = await signInWithPopup(auth, providerfb);
      console.log("data", data);
      // Assuming data.user.providerData is an array
      const providerID = data.user.providerData[0].uid;
      const providerName = data.user.email;
      console.log("providerID:", providerID);
      const fetchdata = {
        email: providerName,
        userId: supervisorId,
        companyId: decodetoken.companyId,
        providerType: prviderTypee,
        providerId: providerID,
      };
      // const response = SettingServices.linkSocialAccount(fetchdata);
      SettingServices.linkSocialAccount(fetchdata)
        .then((response) => {
          if (response.status === 200) {
            setMessage(response.data.data.message);
            setSeverity("success");
            setOpen(true);
            setIsFacebookConnected(true);
          }
        })
        .catch((error) => {
          console.log("error", error);
          setMessage(error.response.data.message);
          setSeverity("error");
          setOpen(true);
        });
    } catch (error) {
      console.error("Error during social link:", error);
      setMessage("An error occurred while linking social account.");
      setSeverity("error");
      setOpen(true);
    }
  };

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const [selectedLanguage, setSelectedLanguage] = useState("English");

  const handleLanguageChange = (e) => {
    // setSelectedLanguage(e);
    localStorage.setItem("lang", e);
    i18next.changeLanguage(localStorage.getItem("lang"));
  };

  //--- private policies and term and conditions-------------
  const handlePrivacyPolicies = () => {
    alert("private policies");
  };
  const handleTermConditions = () => {
    alert("Term and conditions");
  };

  const handleNotification = () => {
    debugger;
    const email = jwt.email;
    SettingServices.getnotify(email)
      .then((response) => {
        if (response.status === 200) {
          setMessage(response.data.message);
          setSeverity("success");
          setOpen(true);
        }
      })
      .catch((error) => {
        // Handle errors from the request
        setMessage(error.message); // Assuming the error message is accessible in the error object
        setSeverity("error");
        setOpen(true);
      });
  };

  //-------------------API to fetch the user Data------------
  const getSettingData = () => {
    debugger;
    SettingServices.getSettingData(supervisorId).then((response) => {
      const data = response.data;
      console.log("resp_data_setting", data);
      const fbStatus = response.data.data.isFacebookLinked;
      const googleStatus = response.data.data.isGoogleLinked;
      console.log("fbStatus", fbStatus);
      console.log("googleStatus", googleStatus);
      setIsFacebookConnected(fbStatus);
      setIsGoogleConnected(googleStatus);
    });
  };
  useEffect(() => {
    getSettingData();
  }, []);

  //---------------change Password functionlity------------
  // const handleChangePassword = () => {
  //   // Redirect to change password page
  //   window.location.href = "/supervisor/settings/SupChangePassword";
  // };

  return (
    <>
      <Container className="mt-4">
        <Card>
          <div className="card-header bg-transparent border-0">
            <h5 className="mb-4">{t("Settings")}</h5>
          </div>
          <Card.Body>
            <Row>
              <Col md={3}>
                <ul className="list-unstyled">
                  <li className="mr-4">
                    <Form.Label
                      style={{ marginBottom: "40px", marginTop: "10px" }}
                      onChange={handleNotification}
                    >
                      {t("Notification")}
                    </Form.Label>
                  </li>
                  <li className="mb-3">
                    <Form.Label>{t("Language Preference")}</Form.Label>
                  </li>

                  <li className="mb-3 cursor-pointer">
                    {/* <Form.Label
                      style={{ marginBottom: "5px" }}
                      onClick={handleChangePassword}
                    > */}
                    <Link
                      className="cursor-pointer"
                      style={{
                        color: "#646464",
                        textDecoration: "none",
                        font: "14px",
                      }}
                      to="/dashboard/company/changepassword"
                      state={{ userName: jwt?.username }}
                    >
                      {t("Change_Password")}
                    </Link>
                  </li>
                  <li className="mb-3">
                    <Link
                      style={{
                        color: "#646464",
                        textDecoration: "none",
                        font: "14px",
                      }}
                      to="/dashboard/company/privacypolicy"
                    >
                      {t("Terms_and_Conditions")}
                    </Link>
                  </li>
                  <li className="mb-3">
                    <Link
                      style={{
                        color: "#646464",
                        textDecoration: "none",
                        font: "14px",
                      }}
                      to="/dashboard/company/privacypolicy"
                    >
                      {t("Privacy_Policies")}
                    </Link>
                  </li>
                </ul>
              </Col>
              <Col md={9}>
                {/* <Form className="mb-4">
                  <Form.Group controlId="notificationDropdown">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="outline-secondary"
                        id="notificationDropdownToggle"
                        size="sm"
                        style={{ marginTop: "5px" }}
                      >
                        {notificationStatus}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={(e) =>
                            handleNotificationChange(e.target.value)
                          }
                        >
                          Enable
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) =>
                            handleNotificationChange(e.target.value)
                          }
                        >
                          Disable
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Form.Group>
                </Form> */}
                <Form className="mb-4">
                  <Form.Group controlId="notificationDropdown">
                    <Form.Select
                      as="select"
                      value={notificationStatus}
                      onChange={(e) => handleNotificationChange(e.target.value)}
                      style={{
                        width: "12.5%",
                        marginTop: "5px",
                        marginBottom: "20px",
                      }}
                    >
                      <option value="Enable"> {t("Enable")}</option>
                      <option value="Disable"> {t("Disable")}</option>
                    </Form.Select>
                  </Form.Group>
                </Form>

                <Form className="mb-4">
                  <Form.Group controlId="languageDropdown">
                    <Form.Select
                      aria-label="Default select example"
                      //value={}
                      style={{ width: "120px" }}
                      onClick={(e) => handleLanguageChange(e.target.value)}
                    >
                      <option
                        value="en"
                        selected={localStorage.getItem("lang") == "en"}
                      >
                        English
                      </option>
                      <option
                        value="fr"
                        selected={localStorage.getItem("lang") == "fr"}
                      >
                        French
                      </option>
                      {/* <option
                        value="se"
                        selected={localStorage.getItem("lang") == "se"}
                      >
                        Swedish
                      </option> */}
                    </Form.Select>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={handleClose}
      >
        {severity && (
          <Alert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        )}
      </Snackbar>
    </>
  );
};

export default SettingsPage;
