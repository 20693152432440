import http from "../../../../baseURL/httpcommon";

const getCompanies = (page, pageSize) => {
  return http.get(
    `admin/companies?pageNumber=${page}&pageSize=${pageSize}&sortBy=createdAt&sortDir=desc`
  );
};
const viewRequests = () => {
  return http.get("company/new/request");
};
const changeStatus = (email, event) => {
  const data = {
    email: email,
    status: event,
  };
  return http.patch(`admin/company/change/status`, data);
};
const changeCompanyStatus = (email, event) => {
  const data = {
    email: email,
    status: event,
  };
  return http.patch(`company/change/status`, data);
};
const CompaniesApi = {
  getCompanies,
  viewRequests,
  changeStatus,
  changeCompanyStatus,
};

export default CompaniesApi;
