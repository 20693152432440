import React, { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// import ServerSideTable from "../../../common components/serverSideTable";
import { DataGrid, GridPagination } from "@mui/x-data-grid";
import WorkerServices from "./services";
import { CircularProgress } from "@mui/material";

const WorkersScreen = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);

  // Snackbar states
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const { t } = useTranslation();

  const token = localStorage.getItem("token");
  const decodetoken = jwtDecode(token);
  const supervisorId = decodetoken.id;

  const columns = [
    {
      field: "employeeId",
      headerName: t("Worker ID"),
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "name",
      headerName: t("Name"),
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "skills",
      headerName: t("Worker Skills"),
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "phonenumber",
      headerName: t("Phone number"),
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "address",
      headerName: t("Address"),
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (param) => (
        <Link
          className="btn btn-outline-secondary btn-blue font-14 ms-2"
          to="/dashboard/supervisor/workertask"
          state={{ rowData: param.row }}
        >
          {t("View Details")}
        </Link>
      ),
    },
  ];

  const getAllWorkers = async () => {
    debugger;
    try {
      setLoading(true);
      const response = await WorkerServices.getWorkerList(supervisorId);
      const { data } = response.data;

      setData(data);
      console.log("get_all_workers_list", data);
      setLoading(false);
    } catch (error) {
      if (error.response) {
        const { status, data } = error.response;
        if (status === 401) {
          setMessage(data.message);
        } else if (status === 400) {
          setMessage(data.message);
        } else {
          setMessage("An error occurred while logging in");
        }
      } else {
        setMessage("Network Error");
      }
      setSeverity("error");
      setOpen(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllWorkers();
  }, []);

  return (
    <>
      <div class="card radius-10 p-4 px-3">
        <div class="card-header bg-transparent border-0">
          <div class="row g-3 align-items-center">
            <div class="col-4 col-md-2 col-lg-2">
              <h5 class="mb-3">{t("Workers")}</h5>
            </div>
            <div class="col-8 col-md-10 col-lg-10">
              {/* <div class="text-right">
                <button
                  class="btn btn-outline-secondary dropdown-toggle btn-blue px-3"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  All
                </button>
                <ul class="dropdown-menu dropdown-menu-end border-b">
                  <li>
                    <a class="dropdown-item" href="#">
                      All
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      Absent
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      Checked-in
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      Checked-out
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      Break
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      Outside Work
                    </a>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
        <div style={{ height: 400, width: "100%" }}>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 400, // Adjust height as needed
                width: "100%",
              }}
            >
              <CircularProgress />
            </div>
          ) : data.length > 0 ? (
            <DataGrid
              rows={data}
              columns={columns}
              autoHeight
              pagination={false}
              components={{
                pagination: () => null,
              }}
              disableSelectionOnClick
              onSelectionModelChange={() => {}}
            />
          ) : (
            <div
              style={{
                width: "97.3%",
                marginTop: "20px",
                marginLeft: "15px",
                marginRight: "20px",
              }}
            >
              <p>{t("No Worker Found")}</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default WorkersScreen;
