import http from "../../../../../baseURL/httpcommon";
import authHeader from "../../../../../baseURL/authHeader";

//get all reports
const getReportsList = (
  workforceId,
  // reportId,
  eTaskReportStatus,
  pageNumber,
  pageSize
) => {
  debugger;
  return http.get(
    // `worker/report/supervisor/list?workforceId =${workforceId}&reportId=${reportId}&eTaskReportStatus =${eTaskReportStatus}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=id&sortDir=desc`
    `worker/report/supervisor/list?workforceId=${workforceId}&eTaskReportStatus=${eTaskReportStatus}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=id&sortDir=desc`
  );
};

const ReportServices = {
  getReportsList,
};

export default ReportServices;
