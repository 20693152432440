import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import CompaniesApi from "./services";
import { Stack } from "react-bootstrap";
import { Alert, Snackbar } from "@mui/material";

const ViewDetails = (prop) => {
  const { t } = useTranslation();
  const location = useLocation();
  console.log("ViewDetails", prop);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");

  const handleCloses = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const viewDetail = {
    name: "",
    number: "",
    regNo: "",
    email: "",
    taxAgencyno: "",
    registrationNo: "",
    // password: "",
    //package: "",
  };

  const [modelData, setModelData] = useState(viewDetail);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  function setViewDetail(data) {
    setShow(true);
    setModelData({
      ...modelData,
      name: data.companyName,
      number: data.phoneNo,
      regNo: data.registrationNo,
      email: data.email,
      taxAgencyno: data.taxAgencyNo,
      ssn: data.ssn,
      registrationNo: data.registrationNo,
      //password: data,
      // package: data,
    });
    console.log(modelData);
  }

  function approve() {
    CompaniesApi.changeStatus(modelData.email, true).then((respons) => {
      // navigate('/dashboard/admin/companies')
      setShow(false);
      setMessage(respons.data.message);
      setSeverity("success");
      setOpen(true);
    });
  }
  function reject() {
    CompaniesApi.changeStatus(modelData.email, false).then((respons) => {
      // navigate('/dashboard/admin/companies')
      setShow(false);
      setMessage(respons.data.message);
      setSeverity("success");
      setOpen(true);
    });
  }

  return (
    <>
      <button
        type="button"
        className="btn btn-primary px-2 font-14"
        onClick={() => setViewDetail(prop.data)}
      >
        {t("View Details")}
      </button>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Company Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12 col-lg-12 col-xl-12 mb-3">
              {/* <div className="px-4 pt-4 text-center">
                <img src="/assets/img/avatardefault_92824.png" style={{ width: "100px", height: "auro" }} />
              </div> */}
              <div className="px-lg-4 pt-5 px-3">
                <div className="row">
                  <div className="col-md-6 col-12  mb-3">
                    <div className="border-bottom2 height-50">
                      <span className="font-14 text-dark w_50">
                        {" "}
                        Company name
                      </span>
                      <span className="font-14 text-gray text-right w_50">
                        {" "}
                        {modelData.name}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 col-12  mb-3">
                    <div className="border-bottom2 height-50">
                      <span className="font-14 text-dark w_50">
                        {" "}
                        Phone number
                      </span>
                      <span className="font-14 text-gray text-right w_50">
                        {" "}
                        {modelData.number}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {/* <div className="col-md-6 col-12  mb-3">
                    <div className="border-bottom2 height-50">
                      <span className="font-14 text-dark w_50">
                        {" "}
                        Corporate Reg no.
                      </span>
                      <span className="font-14 text-gray text-right w_50">
                        {" "}
                        {modelData.regNo}
                      </span>
                    </div>
                  </div> */}
                  <div className="col-md-6 col-12  mb-3">
                    <div className="border-bottom2 height-50">
                      <span className="font-14 text-dark w_50"> Email</span>
                      <span className="font-14 text-gray text-right w_50">
                        {" "}
                        {modelData.email}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 col-12  mb-3">
                    <div className="border-bottom2 height-50">
                      <span className="font-14 text-dark w_50">
                        {" "}
                        Personal/Social security number
                      </span>
                      <span className="font-14 text-gray text-right w_50">
                        {" "}
                        {modelData.ssn}
                      </span>
                    </div>
                  </div>
                </div>
                {location.pathname === "/dashboard/admin/companies" ? (
                  <div className="row">
                    <div className="col-md-6 col-12  mb-3">
                      <div className="border-bottom2 height-50">
                        <span className="font-14 text-dark w_50">
                          {" "}
                          Corporate Reg no.
                        </span>
                        <span className="font-14 text-gray text-right w_50">
                          {" "}
                          {modelData.registrationNo}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6 col-12  mb-3">
                      <div className="border-bottom2 height-50">
                        <span className="font-14 text-dark w_50">
                          {" "}
                          Tax Agency no.
                        </span>
                        <span className="font-14 text-gray text-right w_50">
                          {" "}
                          {modelData.taxAgencyno}
                        </span>
                      </div>
                    </div>
                  </div>
                ) : null}

                {/* <label for="exampleDataList" className="form-label mb-3">
                  Requested Package
                </label>
                <div className="row m">
                  <div className="col-md-6 col-12  mb-3">
                    <div className="border-bottom2 height-50">
                      <span className="font-14 text-dark w_50">
                        {" "}
                        Startup Plan
                      </span>
                      <span className="font-14 text-gray text-right w_50">
                        {" "}
                        778892
                      </span>
                    </div>
                  </div>
                </div> */}
                {location.pathname ===
                "/dashboard/admin/companies/viewrequests" ? (
                  <div className="row text-center mt-4">
                    <div className="col-md-12 col-12  mb-3">
                      <button
                        type="button"
                        className="btn btn-success2 px-3 px-3"
                        style={{ marginRight: "5px" }}
                        onClick={approve}
                      >
                        Approve
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger2 px-3"
                        onClick={reject}
                      >
                        Reject
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary">Understood</Button>
        </Modal.Footer> */}
      </Modal>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleCloses}
        >
          <Alert
            onClose={handleCloses}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default ViewDetails;
