import React, { useState, useEffect } from "react";
import TimeSheetServices from "./services";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";

const TimeSheet = () => {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [status, setStatus] = useState("All");
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setpageSize] = useState(100);
  const [newData, setNewData] = useState([]);
  const [date, setDate] = useState([]);
  const [message, setMessage] = useState();
  const [severity, setSeverity] = useState("error");
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const token = localStorage.getItem("token");
  const decodedToken = jwtDecode(token);

  //handling the filter state
  const handleStatus = (status) => {
    setStatus(status);
    console.log("status", status);
  };

  //function to get timesheet records
  const getTimeSheet = () => {
    debugger;
    const supervisorId = decodedToken.id;
    let requestTypeEnum = "";
    const name = "";
    // const f_date = filterDate(date);
    const f_date = date;
    console.log("f_date : " + f_date);

    if (status === "Break") {
      requestTypeEnum = "Break";
    } else if (status === "Check-In") {
      requestTypeEnum = "checkIn";
    } else if (status === "Check-Out") {
      requestTypeEnum = "checkOut";
    } else if (status === "OutSide-Work") {
      requestTypeEnum = "goOutsideTime";
    } else if (status === "All") {
      requestTypeEnum = "";
    }
    console.log("status", status);
    TimeSheetServices.getTimeSheetData(
      supervisorId,
      name,
      f_date,
      requestTypeEnum,
      pageNumber,
      pageSize
    )
      .then((res) => {
        const timeSheetResponse = res;
        console.log("timesheet_res", timeSheetResponse);
        if (timeSheetResponse.status === 200) {
          console.log("timesheet_res", timeSheetResponse.data.data);
          setData(timeSheetResponse.data.data);
          convertToPercentage();
          setCount(1);
        }
      })
      .catch((error) => {
        if (error.response) {
          const status = error.response.status;
          if (status === 404) {
            console.error("Error 404: Resource not found");
            setCount(0);
          } else if (status === 500) {
            console.error("Error 500: Internal server error");
          } else if (status === 401) {
            console.error("Error 401: Unauthorized");
          } else {
            console.error("Unexpected error:", error);
          }
        } else if (error.request) {
          console.error("No response received:", error.request);
        } else {
          console.error("Error:", error.message);
        }
      });
  };

  const convertToPercentage = () => {
    debugger;
    console.log("data_map", data);
    const newData = data.map((item) => {
      const checkInDate = new Date(item.checkIn);
      console.log("checkInSeconds", checkInDate);
      const checkOutDate = new Date(item.checkOut);
      console.log("checkOutDate", checkOutDate);

      //////-----------------///////////
      const breakTimes = item.breakTime.map((value) => {
        // Convert start and end time strings to Date objects
        const startTime = new Date(value.startTime);
        const endTime = new Date(value.endTime);

        // Calculate break time in seconds
        const breakTimeSecond =
          startTime.getHours() * 3600 +
          startTime.getMinutes() * 60 +
          startTime.getSeconds() +
          startTime.getMilliseconds() / 1000;

        // Return an object containing the start and end times
        return {
          startTime: startTime.toLocaleTimeString(), // Convert to local time string
          endTime: endTime.toLocaleTimeString(), // Convert to local time string
          breakTimeSecond: breakTimeSecond.toFixed(2), // Convert to seconds
        };
      });

      console.log("breakTimes", breakTimes);

      const overTimeHours = new Date(item.overTimeHours);
      console.log("overTimeHours", overTimeHours);

      // Calculate total seconds since midnight for checkIn and checkOut
      const checkInSeconds =
        checkInDate.getHours() * 3600 +
        checkInDate.getMinutes() * 60 +
        checkInDate.getSeconds() +
        checkInDate.getMilliseconds() / 1000;
      console.log("checkInSeconds", checkInSeconds);

      const checkOutSeconds =
        checkOutDate.getHours() * 3600 +
        checkOutDate.getMinutes() * 60 +
        checkOutDate.getSeconds() +
        checkOutDate.getMilliseconds() / 1000;
      console.log("checkOutSeconds", checkOutSeconds);

      const overTimeSeconds =
        overTimeHours.getHours() * 3600 +
        overTimeHours.getMinutes() * 60 +
        overTimeHours.getSeconds() +
        overTimeHours.getMilliseconds() / 1000;
      console.log("overTimeSeconds", overTimeSeconds);

      // Calculate percentages
      const totalSecondsInDay = 24 * 3600;
      const checkInPercentage = (checkInSeconds / totalSecondsInDay) * 100;
      const checkOutPercentage = (checkOutSeconds / totalSecondsInDay) * 100;
      const overTimePercentage = (overTimeSeconds / totalSecondsInDay) * 100;
      const breakTimePercentage =
        (breakTimes.reduce(
          (acc, breakItem) => acc + parseFloat(breakItem.breakTimeSecond),
          0
        ) /
          totalSecondsInDay) *
        100;
      console.log("checkInPercentage", checkInPercentage);
      console.log("checkOutPercentage", checkOutPercentage);
      console.log("overTimePercentage", overTimePercentage);
      console.log("breakTimePercentage", breakTimePercentage);

      // Assign percentages to item properties
      return {
        ...item,
        checkInPercentage: checkInPercentage.toFixed(2),
        checkOutPercentage: checkOutPercentage.toFixed(2),
        overTimePercentage: overTimePercentage.toFixed(2),
        breakTimePercentage: breakTimePercentage.toFixed(2),
      };
    });

    setNewData(newData);
    console.log(newData); // Check the updated data array with percentages
  };

  //download functionlity
  // const handleDownloadTimeline = async () => {
  //   try {
  //     const supervisorId = decodedToken.id;
  //     const date = new Date();
  //     const yyyy = date.getFullYear();
  //     const mm = String(date.getMonth() + 1).padStart(2, "0");
  //     const dd = String(date.getDate()).padStart(2, "0");
  //     const formattedDate = `${yyyy}-${mm}-${dd}`;

  //     // Construct the URL for downloading the report
  //     const downloadUrl = `http://62.171.153.83:8080/tappme-api-development/timesheet/export-to-excel?supervisorId=${supervisorId}&date=${formattedDate}`;

  //     // Create an anchor element
  //     const link = document.createElement("a");

  //     // Set the href attribute to the download URL
  //     link.href = downloadUrl;

  //     // Set the download attribute to specify the filename with .xlsx extension
  //     link.download = "case_report.xlsx";

  //     // Simulate a click on the anchor element
  //     link.click();
  //   } catch (error) {
  //     console.error("Error downloading report:", error);
  //   }
  // };
  const handleDownloadTimeline = async () => {
    debugger;
    try {
      const supervisorId = decodedToken.id;
      const date = new Date();
      const yyyy = date.getFullYear();
      const mm = String(date.getMonth() + 1).padStart(2, "0");
      const dd = String(date.getDate()).padStart(2, "0");
      const formattedDate = `${yyyy}-${mm}-${dd}`;

      // Construct the URL for downloading the report
      const downloadUrl = `http://62.171.153.83:8080/tappme-api-development/timesheet/export-to-excel?supervisorId=${supervisorId}&date=${formattedDate}`;

      // Fetch the file
      const response = await fetch(downloadUrl);

      // Check if the response status is 200 (OK)
      if (response.status === 200) {
        // Create an anchor element
        const link = document.createElement("a");

        // Set the href attribute to the download URL
        link.href = downloadUrl;

        // Set the download attribute to specify the filename with .xlsx extension
        link.download = "case_report.xlsx";

        // Simulate a click on the anchor element
        link.click();
      } else if (response.status === 404) {
        // Display a notification to the user if file not found
        setMessage(response.data.message);
        setSeverity("error");
        setOpen(true);
      } else if (response.status === 500) {
        // Display a notification to the user if file not found
        setMessage(response.data.message);
        setSeverity("error");
        setOpen(true);
      } else {
        // Handle other status codes if needed
        console.error("Unexpected response status:", response.status);
      }
    } catch (error) {
      console.error("Error downloading report:", error);
      setMessage("Error Downloading Report");
      setSeverity("error");
      setOpen(true);
    }
  };

  useEffect(() => {
    debugger;
    getTimeSheet();
  }, [status, date]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  return (
    <>
      <div class="card radius-10 p-4 px-3">
        <div class="card-header bg-transparent border-0">
          <div class="row g-3 align-items-center">
            <div class="col-12 col-md-2 col-lg-2">
              <h5 class="mb-0">{t("Timesheet")}</h5>
            </div>
            <div class="col-6 col-md-3 col-lg-3 icon-blue">
              <input
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                class="form-control blue-border"
              />
            </div>
            {/* <div class="col-6 col-md-2 col-lg-2 icon-blue"></div> */}
            <div class="col-6 col-md-4 col-lg-4 icon-blue">
              <span class="dropdown1">
                {/* <a href="" class="btn btn-primary font-14" type="button">
                  View My Timeline{" "}
                </a> */}

                <button
                  class="btn btn-outlineStyle-secondary dropdown-toggle btn-blue px-3 me-5"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {status}
                </button>
                <ul class="dropdown-menu dropdown-menu-end border-b">
                  <li>
                    <a
                      class="dropdown-item"
                      onClick={() => handleStatus("All")}
                    >
                      {t("All")}
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      onClick={() => handleStatus("Check-In")}
                    >
                      Check-In
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      onClick={() => handleStatus("Check-Out")}
                    >
                      Check-Out
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      onClick={() => handleStatus("Break")}
                    >
                      Break
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      onClick={() => handleStatus("OutSide-Work")}
                    >
                      OutSide-Work
                    </a>
                  </li>
                </ul>
              </span>
            </div>
            <div class="col-12 col-md-3 col-lg-3">
              <div class="text-right">
                <Link
                  className="btn btn-primary font-14"
                  to={{
                    pathname: "/dashboard/supervisor/timeline",
                    // state: {
                    //   data: param.row,
                    //   view: true,
                    // },
                  }}
                >
                  {t("View My Timeline")}
                </Link>
                <span class="dropdown1">
                  <button
                    class="btn btn-primary font-14 ms-3"
                    type="button"
                    onClick={handleDownloadTimeline}
                  >
                    Export data{" "}
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body height60">
          <div class="row">
            <div class="col-3 col-md-3 pt-2">
              <div class="d-flex">
                <label class="pe-3 width-40 text-gray">
                  {t("Employee ID")}
                </label>
                <label class="width-30 text-gray">{t("Name")}</label>
              </div>
            </div>

            <div class="col-9 col-md-9">
              <div class="bar">
                <div class="child">8am</div>
                <div class="child">9am</div>
                <div class="child">10am</div>
                <div class="child">11am</div>
                <div class="child">12Pm</div>
                <div class="child">1pm</div>
                <div class="child">2pm</div>
                <div class="child">3pm</div>
                <div class="child">4pm</div>
                <div class="child">5pm</div>
                <div class="child">6pm</div>
                <div class="child">7pm</div>
                <div class="child">8pm</div>
                <div class="child">9pm</div>
                <div class="child">10pm</div>
                <div class="child">11pm</div>
                <div class="child">12am</div>
                <div class="child">1am</div>
                <div class="child">2am</div>
                <div class="child">3am</div>
                <div class="child">4am</div>
                <div class="child">5am</div>
                <div class="child">6am</div>
                <div class="child">7am</div>
              </div>
            </div>
          </div>

          {count > 0
            ? newData.map((item, index) => (
                <div key={index} className="row">
                  <div className="col-3 col-md-3 py-2 border-bottom">
                    <div className="d-flex">
                      <label className="pe-2 width-33 ms-3">
                        {item?.userId}
                      </label>
                      <label
                        className="width-0"
                        style={{ marginLeft: "3.8rem" }}
                      >
                        {" "}
                        {item?.name}
                      </label>
                    </div>
                  </div>
                  <div className="col-9 col-md-9 py-2">
                    <div className="lable">
                      {/* Checked-in */}
                      <div
                        className="time"
                        style={{
                          width: `calc(${item?.checkInPercentage}% * 4)`,
                          marginLeft: `calc(${item?.checkInPercentage}% * 0)`,
                          background: "rgba(10, 207, 131, 0.20)",
                        }}
                      >
                        <div className="time-btn">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                          >
                            <path
                              d="M13 7.5C13 10.5376 10.5376 13 7.5 13C4.46243 13 2 10.5376 2 7.5C2 4.46243 4.46243 2 7.5 2C10.5376 2 13 4.46243 13 7.5Z"
                              fill="#FFFEFF"
                              stroke="#2CDE9A"
                              strokeWidth="4"
                            />
                          </svg>
                          CIT
                        </div>
                      </div>

                      {/* Break time */}

                      <div
                        className="time"
                        style={{
                          width: `calc(${item?.breakTimePercentage}% * 2)`,
                          background: "#6DA4FF",
                        }}
                      >
                        <div className="time-btn2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="16"
                            viewBox="0 0 15 16"
                            fill="none"
                          >
                            <circle
                              cx="7.5"
                              cy="7.92188"
                              r="5.5"
                              fill="#FFFEFF"
                              stroke="#9EC3FF"
                              strokeWidth="4"
                            />
                          </svg>
                          BR
                        </div>
                      </div>

                      {/* Checked time */}

                      <div
                        class="time"
                        style={{
                          width: `calc(${item?.checkInPercentage}% * 5)`,
                          marginLeft: `calc(${item?.checkInPercentage}% * 0)`,
                          background: `rgba(10, 207, 131, 0.20)`,
                        }}
                      >
                        <div class="time-btn">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                          >
                            <path
                              d="M13 7.5C13 10.5376 10.5376 13 7.5 13C4.46243 13 2 10.5376 2 7.5C2 4.46243 4.46243 2 7.5 2C10.5376 2 13 4.46243 13 7.5Z"
                              fill="#FFFEFF"
                              stroke="#2CDE9A"
                              stroke-width="4"
                            />
                          </svg>
                          CIT
                        </div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="7"
                          height="7"
                          viewBox="0 0 7 7"
                          fill="none"
                          class="text-icon"
                        >
                          <path
                            d="M5.95079 2.84905L1.71079 0.185798C1.61783 0.126925 1.50723 0.0801961 1.38537 0.048307C1.26351 0.0164179 1.1328 0 1.00079 0C0.868781 0 0.738075 0.0164179 0.616216 0.048307C0.494356 0.0801961 0.383755 0.126925 0.290792 0.185798C0.104542 0.303485 0 0.462685 0 0.628626C0 0.794568 0.104542 0.953767 0.290792 1.07145L3.83079 3.29502L0.290792 5.51858C0.104542 5.63627 0 5.79547 0 5.96141C0 6.12735 0.104542 6.28655 0.290792 6.40424C0.384233 6.46245 0.495049 6.50851 0.616886 6.53977C0.738724 6.57103 0.869186 6.58687 1.00079 6.58639C1.1324 6.58687 1.26286 6.57103 1.3847 6.53977C1.50654 6.50851 1.61735 6.46245 1.71079 6.40424L5.95079 3.74099C6.04452 3.68259 6.11891 3.61312 6.16968 3.53658C6.22045 3.46004 6.24659 3.37794 6.24659 3.29502C6.24659 3.2121 6.22045 3.13 6.16968 3.05345C6.11891 2.97691 6.04452 2.90744 5.95079 2.84905Z"
                            fill="#0ACF83"
                          />
                        </svg>
                      </div>

                      {/* Overtime */}
                      <div
                        className="time"
                        style={{
                          width: `calc(${item?.overTimePercentage}% * 4)`,
                          marginLeft: `calc(${item?.overTimePercentage}% * 0)`,
                          background: "rgba(255, 153, 109, 0.20)",
                        }}
                      >
                        <div className="time-btn3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="16"
                            viewBox="0 0 15 16"
                            fill="none"
                          >
                            <path
                              d="M15 8.30664C15 12.4488 11.6421 15.8066 7.5 15.8066C3.35786 15.8066 0 12.4488 0 8.30664C0 4.16451 3.35786 0.806641 7.5 0.806641C11.6421 0.806641 15 4.16451 15 8.30664Z"
                              fill="#FFFEFF"
                            />
                            <path
                              d="M13 8.30664C13 11.3442 10.5376 13.8066 7.5 13.8066C4.46243 13.8066 2 11.3442 2 8.30664C2 5.26907 4.46243 2.80664 7.5 2.80664C10.5376 2.80664 13 5.26907 13 8.30664Z"
                              stroke="#FF996D"
                              strokeOpacity="0.73"
                              strokeWidth="4"
                            />
                          </svg>
                          OVT
                        </div>
                      </div>

                      {/* Checkout time */}
                      <div
                        className="time"
                        style={{
                          width: `calc(${item?.checkOutPercentage}% * 3)`,
                          marginLeft: `calc(${item?.checkOutPercentage}% * 0)`,
                          borderRadius: "22.5px",
                          background: "#FF6D6D",
                        }}
                      >
                        <div className="time-btn4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="16"
                            viewBox="0 0 15 16"
                            fill="none"
                          >
                            <circle
                              cx="7.5"
                              cy="7.92188"
                              r="5.5"
                              fill="#FFFEFF"
                              stroke="#FF9E9E"
                              strokeWidth="4"
                            />
                          </svg>
                          COT
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default TimeSheet;
