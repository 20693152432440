import http from "../../../../../baseURL/httpcommon";
// import authHeader from "../../../../../baseURL/authHeader";

//get all reports
const getReportsList = (
  workforceId,
  // reportId,
  eTaskReportStatus,
  pageNumber,
  pageSize
) => {
  debugger;
  return http.get(
    // `worker/report/supervisor/list?workforceId =${workforceId}&reportId=${reportId}&eTaskReportStatus =${eTaskReportStatus}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=id&sortDir=desc`
    `general/report/supervisor?workforceId=${workforceId}&eTaskReportStatus=${eTaskReportStatus}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=id&sortDir=desc`
  );
};
// const getDownloadReport = (reportID) => {
//   return http.get(`/general/report/download/pdf/${reportID}`);
//   // {
//   //   headers: {
//   //     "Content-Type": "application/octet-stream",
//   //     "Content-Disposition": "attachment; filename=picture.png",
//   //   },
//   // }
// };
const ReportServices = {
  getReportsList,
  // getDownloadReport,
};

export default ReportServices;
