import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import TimeSheetServices from "./services";
import ProgressBar from "react-customizable-progressbar";
import { jwtDecode } from "jwt-decode";
import { Link } from "react-router-dom";
// import * as d3 from "d3";
import { use } from "i18next";

const TimeLine = () => {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [status, setStatus] = useState("All");
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setpageSize] = useState(100);
  const [newData, setNewData] = useState([]);
  const [filter, setFilter] = useState("ThisMonth");
  const [hours, setHours] = useState([]);
  const [checkInTime, setCheckInTime] = useState(0);
  const [shiftEndHours, setShiftEndHours] = useState(0);
  const [chartData, setChartData] = useState();
  const [normalHours, setNoramlHours] = useState(0);
  const [overTimeHours, setOverTimeHours] = useState(0);
  const { t } = useTranslation();

  const token = localStorage.getItem("token");

  const decodedToken = jwtDecode(token);
  const chartRef = useRef(null);
  //handling the filter state

  const handleStatus = (status) => {
    setStatus(status);
    console.log("status", status);
  };
  const handlefilter = (filter) => {
    setFilter(filter);
  };

  //get timeline piChart making functional call of TimelineChart into getTimeLinechartData
  const getTimeLinechartData = () => {
    debugger;
    // const workforceId = 190; //for testing
    const workforceId = decodedToken.workforceId;
    console.log("workforceId: " + workforceId);
    TimeSheetServices.getTimeLineChart(workforceId, filter)
      .then((res) => {
        const timeLineResponse = res.data.data;
        if (timeLineResponse) {
          setHours(timeLineResponse.totalHours.hours);
          TimeLineChart(timeLineResponse); // Pass the data to TimeLineChart

          console.log("timeline_response", timeLineResponse);
        }
      })
      .catch((error) => {
        if (error.response) {
          const status = error.response.status;
          if (status === 404) {
            console.error("Error 404: Resource not found");
          } else if (status === 500) {
            console.error("Error 500: Internal server error");
          } else if (status === 401) {
            console.error("Error 401: Unauthorized");
          } else {
            console.error("Unexpected error:", error);
          }
        } else if (error.request) {
          console.error("No response received:", error.request);
        } else {
          console.error("Error:", error.message);
        }
      });
  };

  const TimeLineChart = (timeLineData) => {
    const normalHoursPercentage =
      parseFloat(timeLineData.normalHours.percentage) || 0;
    const overTimePercentage =
      parseFloat(timeLineData.overtimeHours.percentage) || 0;

    // Update the state with the calculated percentages
    setNoramlHours(normalHoursPercentage);
    setOverTimeHours(overTimePercentage);
    // setChartData({
    //   normalHoursPercentage: normalHoursPercentage,
    //   overTimePercentage: overTimePercentage,
    // });

    console.log("chartData", chartData);
  };

  //function to get timeline records
  const getTimeLineData = () => {
    debugger;
    console.log("decoded_token", decodedToken);
    const userId = decodedToken.id; //setting supervisorId
    let requestTypeEnum = "";
    const fromDate = "";
    const toDate = "";

    // if (status === "Break") {
    //   requestTypeEnum = "Break";
    // } else if (status === "CheckIn") {
    //   requestTypeEnum = "checkIn";
    // } else if (status === "CheckOut") {
    //   requestTypeEnum = "checkOut";
    // } else if (status === "OutSideWork") {
    //   requestTypeEnum = "goOutsideTime";
    // } else if (status === "All") {
    //   requestTypeEnum = "";
    // }
    console.log("status", status);
    TimeSheetServices.getTimeLineData(
      userId,
      fromDate,
      toDate,
      requestTypeEnum,
      pageNumber,
      pageSize
    )
      .then((res) => {
        const timeLineResponse = res;
        console.log("timesheet_res", timeLineResponse);
        if (timeLineResponse.status === 200) {
          const data = timeLineResponse.data.data;
          setData(data);
          console.log("checkin_time", data);
          convertToPercentage();
          if (data.length > 0) {
            setCount(1);
          } else {
            setCount(0);
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          const status = error.response.status;
          if (status === 404) {
            console.error("Error 404: Resource not found");
            setCount(0);
          } else if (status === 500) {
            console.error("Error 500: Internal server error");
          } else if (status === 401) {
            console.error("Error 401: Unauthorized");
          } else {
            console.error("Unexpected error:", error);
          }
        } else if (error.request) {
          console.error("No response received:", error.request);
        } else {
          console.error("Error:", error.message);
        }
      });
  };

  //get the percentages from the reponse data
  const convertToPercentage = () => {
    console.log("data_map", data);
    const newData = data.map((item) => {
      const checkInDate = new Date(item.checkIn);
      console.log("checkInSeconds", checkInDate);
      const checkOutDate = new Date(item.checkOut);
      console.log("checkOutDate", checkOutDate);

      //////-----------------///////////
      const breakTimes = item.breakTime.map((value) => {
        // Convert start and end time strings to Date objects
        const startTime = new Date(value.startTime);
        const endTime = new Date(value.endTime);

        // Calculate break time in seconds
        const breakTimeSecond =
          startTime.getHours() * 3600 +
          startTime.getMinutes() * 60 +
          startTime.getSeconds() +
          startTime.getMilliseconds() / 1000;

        // Return an object containing the start and end times
        return {
          startTime: startTime.toLocaleTimeString(), // Convert to local time string
          endTime: endTime.toLocaleTimeString(), // Convert to local time string
          breakTimeSecond: breakTimeSecond.toFixed(2), // Convert to seconds
        };
      });

      console.log("breakTimes", breakTimes);

      const overTimeHours = new Date(item.overTimeHours);
      console.log("overTimeHours", overTimeHours);

      // Calculate total seconds since midnight for checkIn and checkOut
      const checkInSeconds =
        checkInDate.getHours() * 3600 +
        checkInDate.getMinutes() * 60 +
        checkInDate.getSeconds() +
        checkInDate.getMilliseconds() / 1000;
      console.log("checkInSeconds", checkInSeconds);

      const checkOutSeconds =
        checkOutDate.getHours() * 3600 +
        checkOutDate.getMinutes() * 60 +
        checkOutDate.getSeconds() +
        checkOutDate.getMilliseconds() / 1000;
      console.log("checkOutSeconds", checkOutSeconds);

      const overTimeSeconds =
        overTimeHours.getHours() * 3600 +
        overTimeHours.getMinutes() * 60 +
        overTimeHours.getSeconds() +
        overTimeHours.getMilliseconds() / 1000;
      console.log("overTimeSeconds", overTimeSeconds);

      // Calculate percentages
      const totalSecondsInDay = 24 * 3600;
      const checkInPercentage = (checkInSeconds / totalSecondsInDay) * 100;
      const checkOutPercentage = (checkOutSeconds / totalSecondsInDay) * 100;
      const overTimePercentage = (overTimeSeconds / totalSecondsInDay) * 100;
      const breakTimePercentage =
        (breakTimes.reduce(
          (acc, breakItem) => acc + parseFloat(breakItem.breakTimeSecond),
          0
        ) /
          totalSecondsInDay) *
        100;
      console.log("checkInPercentage", checkInPercentage);
      console.log("checkOutPercentage", checkOutPercentage);
      console.log("overTimePercentage", overTimePercentage);
      console.log("breakTimePercentage", breakTimePercentage);

      // Assign percentages to item properties
      return {
        ...item,
        checkInPercentage: checkInPercentage.toFixed(2),
        checkOutPercentage: checkOutPercentage.toFixed(2),
        overTimePercentage: overTimePercentage.toFixed(2),
        breakTimePercentage: breakTimePercentage.toFixed(2),
      };
    });

    setNewData(newData);
    console.log(newData); // Check the updated data array with percentages
  };

  const getShiftHours = () => {
    const userId = decodedToken.id;
    // const userId = 190; //for testing
    const date = new Date();
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const dd = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${yyyy}-${mm}-${dd}`;

    TimeSheetServices.getShiftHoursData(userId, formattedDate)
      .then((res) => {
        if (res.status === 200) {
          const data = res.data.data;
          const datetimeString = data.checkIn;
          const timePart = datetimeString.split("T")[1]; // Extracting the time part after "T"
          const hours = timePart.split(":")[0];
          setCheckInTime(timePart);
          setShiftEndHours(hours);
        } else {
          // Handle other successful responses
          console.log("Unexpected response status:", res.status);
        }
      })
      .catch((error) => {
        if (error.response) {
          const status = error.response.status;
          if (status === 404) {
            console.error("Error 404: Resource not found");
          } else if (status === 400) {
            console.error("Error 400: Bad request");
          } else if (status === 500) {
            console.error("Error 500: Internal server error");
          } else {
            console.error("Unexpected error:", error);
          }
        } else if (error.request) {
          console.error("No response received:", error.request);
        } else {
          console.error("Error:", error.message);
        }
      });
  };

  //download functionlity
  // const handleDownloadTimeline = async () => {
  //   try {
  //     const supervisorId = decodedToken.id;
  //     const date = new Date();
  //     const yyyy = date.getFullYear();
  //     const mm = String(date.getMonth() + 1).padStart(2, "0");
  //     const dd = String(date.getDate()).padStart(2, "0");
  //     const formattedDate = `${yyyy}-${mm}-${dd}`;
  //     // const date = "2024-05-07";

  //     // Fetch the report directly
  //     const res = await TimeSheetServices.getDownloadExcel(
  //       supervisorId,
  //       formattedDate
  //     );

  //     if (res.status === 200) {
  //       // Create a Blob from the response data
  //       const blob = new Blob([res.data], { type: "application/pdf" });

  //       // Create a temporary anchor element
  //       const a = document.createElement("a");
  //       a.style.display = "none";

  //       // Set the URL to the blob
  //       a.href = URL.createObjectURL(blob);

  //       // Set the filename with report ID
  //       a.download = `${supervisorId}.pdf`;

  //       // Append the anchor to the body and click it
  //       document.body.appendChild(a);
  //       a.click();

  //       // Clean up
  //       document.body.removeChild(a);
  //     } else {
  //       console.error("Failed to fetch report:", res.statusText);
  //     }
  //   } catch (error) {
  //     console.error("Error downloading report:", error);
  //   }
  // };

  //default call
  useEffect(() => {
    getTimeLineData();
  }, [status]);

  useEffect(() => {
    getShiftHours();
  }, []);

  useEffect(() => {
    getTimeLinechartData();
  }, [filter]);

  return (
    <>
      <div class="card radius-10 p-4 px-3">
        <div class="card-header bg-transparent border-0">
          <div class="row g-3 align-items-center">
            <div class="col-12 col-md-2 col-lg-2">
              <h5 class="mb-0">Timesheet</h5>
            </div>
            {/* <div class="col-6 col-md-3 col-lg-3 icon-blue">
                        <input type="date" class="form-control blue-border">
                     </div> */}
            {/* <div class="col-6 col-md-2 col-lg-2 icon-blue">
                        <input type="time" class="form-control blue-border">
                     </div> */}

            <div class="col-12 col-md-5 col-lg-5">
              {/* <div class="text-right">
                <button
                  class="btn btn-outlineStyle-secondary dropdown-toggle btn-blue px-3 me-5"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {status}
                </button>
                <ul class="dropdown-menu dropdown-menu-end border-b">
                  <li>
                    <a
                      class="dropdown-item"
                      onClick={() => handleStatus("All")}
                    >
                      {t("All")}
                    </a>
                  </li>

                  <li>
                    <a
                      class="dropdown-item"
                      onClick={() => handleStatus("CheckIn")}
                    >
                      Check-In
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      onClick={() => handleStatus("CheckOut")}
                    >
                      Check-Out
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      onClick={() => handleStatus("Break")}
                    >
                      Break
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      onClick={() => handleStatus("OutSideWork")}
                    >
                      OutSide Work
                    </a>
                  </li>
                </ul>

                <span class="dropdown1">
                  <button
                    class="btn btn-primary font-14"
                    type="button"
                    onClick={handleDownloadTimeline}
                  >
                    Export data{" "}
                  </button>
                </span>
              </div> */}
            </div>
            <div class="col-12 col-md-5 col-lg-5 text-end">
              <Link
                to="/dashboard/supervisor/timesheet"
                className="btn btn-outline-secondary btn-blue font-14 ms-5"
              >
                {t("Back")}
              </Link>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="border radius-10 p-4 p-lg-4 mt-4">
            <h5>Today Working Hours</h5>
            <div class="row align-items-center d-flex">
              <div class="col-12 col-md-6 col-lg-6">
                <div class="border radius-10 p-4 p-lg-4 mt-4 text-center">
                  <p class="text-blue">Logged in today at {checkInTime}</p>
                  <div className="text-bold" style={{ fontSize: "20px" }}>
                    <span
                      style={{
                        fontWeight: "bold",
                        margin: "14px",
                        border: "1px solid #0000001A",
                        borderRadius: "4px",
                        width: "50px",
                        height: "50px",
                        display: "inline-block",
                        textAlign: "center",
                        paddingTop: "9px",
                      }}
                    >
                      {new Date().getHours()}
                    </span>
                    <span>:</span>
                    <span
                      style={{
                        fontWeight: "bold",
                        margin: "14px",
                        border: "1px solid #0000001A",
                        borderRadius: "4px",
                        width: "50px",
                        height: "50px",
                        display: "inline-block",
                        textAlign: "center",
                        paddingTop: "9px",
                      }}
                    >
                      {new Date().getMinutes()}
                    </span>
                    <span>:</span>
                    <span
                      style={{
                        fontWeight: "bold",
                        margin: "14px",
                        border: "1px solid #0000001A",
                        borderRadius: "4px",
                        width: "50px",
                        height: "50px",
                        display: "inline-block",
                        textAlign: "center",
                        paddingTop: "9px",
                      }}
                    >
                      {new Date().getSeconds()}
                    </span>
                  </div>

                  <p class="text-blue mt-3">
                    Shift end in {24 - parseFloat(shiftEndHours)} hours
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    marginTop: "2rem",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#2CDE9A",
                      borderRadius: "50%",
                      width: "14px",
                      height: "14px",
                      marginTop: "1px",
                      marginRight: "5px",
                    }}
                  ></div>
                  <span style={{ fontSize: "16px", marginRight: "5px" }}>
                    Checked-In
                  </span>
                  <div
                    style={{
                      backgroundColor: "#FF9E9E",
                      borderRadius: "50%",
                      width: "14px",
                      height: "14px",
                      marginTop: "1px",
                      marginLeft: "20px",
                      marginRight: "5px",
                    }}
                  ></div>
                  <span style={{ fontSize: "16px", marginRight: "5px" }}>
                    Checked-Out
                  </span>
                  <div
                    style={{
                      backgroundColor: "#FF996D",
                      borderRadius: "50%",
                      width: "14px",
                      height: "14px",
                      marginTop: "1px",
                      marginLeft: "20px",
                      marginRight: "5px",
                    }}
                  ></div>
                  <span style={{ fontSize: "16px", marginRight: "5px" }}>
                    OverTime
                  </span>
                  <div
                    style={{
                      backgroundColor: "#9EC3FF",
                      borderRadius: "50%",
                      width: "14px",
                      height: "14px",
                      marginTop: "1px",
                      marginLeft: "20px",
                      marginRight: "5px",
                    }}
                  ></div>
                  <span style={{ fontSize: "16px", marginRight: "5px" }}>
                    Break
                  </span>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-6 pt-2">
                <div class="border radius-10 p-4 p-lg-4 mt-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <p style={{ marginBottom: "10px", fontWeight: 900 }}>
                      Hours History
                    </p>

                    <div class="d-flex justify-content-between align-items-center mb-3">
                      <div className="">
                        <button
                          class="btn btn-outlineStyle-secondary dropdown-toggle btn-blue px-3 me-5"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {filter}
                        </button>
                        <ul class="dropdown-menu dropdown-menu-end border-b">
                          <li>
                            <a
                              class="dropdown-item"
                              onClick={() => handlefilter("ThisMonth")}
                            >
                              {t("This Month")}
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              onClick={() => handlefilter("LastWeek")}
                            >
                              {t("Last Week")}
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              onClick={() => handlefilter("LastMonth")}
                            >
                              {t("Last Month")}
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              onClick={() => handlefilter("ThisWeek")}
                            >
                              {t("This Week")}
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              onClick={() => handlefilter("Yesterday")}
                            >
                              {t("Yesterday")}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <div className="d-flex align-items-center">
                        <div className="d-flex me-3">
                          <div
                            className="indicator-circle mt-1"
                            style={{
                              backgroundColor: "#1E73FF",
                              borderRadius: "50%",
                              width: "10px",
                              height: "10px",
                            }}
                          ></div>
                          <span className="ms-2">Normal Working hrs</span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="d-flex me-3">
                          <div
                            className="indicator-circle overtime mt-1"
                            style={{
                              backgroundColor: "#FF996D",
                              borderRadius: "50%",
                              width: "10px",
                              height: "10px",
                            }}
                          ></div>
                          <span className="ms-2">Overtime Working hrs</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <ProgressBar
                      className="first-circle mx-auto"
                      radius={100}
                      progress={normalHours}
                      strokeColor="#1E73FF"
                      pointerRadius={3}
                      pointerStrokeWidth={0}
                      strokeWidth={20}
                      trackStrokeWidth={20}
                      pointerStrokeColor="#7bcd5c"
                      initialAnimationDelay={1000}
                      initialAnimation={true}
                      trackTransition=".1s ease"
                      transition="1s ease"
                    >
                      <div className="indicator second-circle1">
                        <ProgressBar
                          radius={60}
                          className="second-circle"
                          progress={overTimeHours}
                          strokeWidth={10}
                          trackStrokeWidth={10}
                          strokeColor="#FF996D"
                          pointerRadius={3}
                          pointerStrokeWidth={0}
                          pointerStrokeColor="#7bcd5c"
                          initialAnimationDelay={1000}
                          initialAnimation={true}
                          trackTransition=".1s ease"
                          transition="1s ease"
                        />
                        <span className="text-a">
                          Working <br />
                          Hours
                        </span>
                      </div>
                    </ProgressBar>
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    Total Working Hours : {hours}
                  </div>
                </div>
              </div>

              <div class="row mt-4 mb-5 vh-25">
                <div class="col-3 col-md-3 pt-2">
                  <div class="d-flex">
                    <label class="pe-3 width-40 text-gray">
                      {" "}
                      {t("Employee ID")}
                    </label>
                    <label class="width-60 text-gray">{t("Name")}</label>
                  </div>
                </div>

                <div class="col-9 col-md-9">
                  <div class="bar">
                    <div class="child">8am</div>
                    <div class="child">9am</div>
                    <div class="child">10am</div>
                    <div class="child">11am</div>
                    <div class="child">12Pm</div>
                    <div class="child">1pm</div>
                    <div class="child">2pm</div>
                    <div class="child">3pm</div>
                    <div class="child">4pm</div>
                    <div class="child">5pm</div>
                    <div class="child">6pm</div>
                    <div class="child">7pm</div>
                    <div class="child">8pm</div>
                    <div class="child">9pm</div>
                    <div class="child">10pm</div>
                    <div class="child">11pm</div>
                    <div class="child">12pm</div>
                    <div class="child">1am</div>
                    <div class="child">2am</div>
                    <div class="child">3am</div>
                    <div class="child">4am</div>
                    <div class="child">5am</div>
                    <div class="child">6am</div>
                    <div class="child">7am</div>
                  </div>
                </div>
              </div>
              {count > 0
                ? newData.map((item, index) => (
                    <div class="height-100">
                      <div class="row pt-2">
                        <div class="col-3 col-md-3 py-2 border-bottom">
                          <div class="d-flex">
                            <label
                              class="pe-3 width-40"
                              style={{ marginLeft: "5px" }}
                            >
                              {" "}
                              {item?.userId}
                            </label>
                            <label
                              class="width-60"
                              style={{ marginLeft: "10px" }}
                            >
                              {" "}
                              {item?.name}
                            </label>
                          </div>
                        </div>

                        <div class="col-9 col-md-9 py-2">
                          <div class="lable">
                            {/* Checked-in */}
                            <div
                              class="time"
                              style={{
                                width: `calc(${item?.checkInPercentage}% * 4)`,
                                marginLeft: `calc(${item?.checkInPercentage}% * 0)`,
                                background: "rgba(10, 207, 131, 0.20)",
                              }}
                            >
                              <div class="time-btn">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15"
                                  height="15"
                                  viewBox="0 0 15 15"
                                  fill="none"
                                >
                                  <path
                                    d="M13 7.5C13 10.5376 10.5376 13 7.5 13C4.46243 13 2 10.5376 2 7.5C2 4.46243 4.46243 2 7.5 2C10.5376 2 13 4.46243 13 7.5Z"
                                    fill="#FFFEFF"
                                    stroke="#2CDE9A"
                                    stroke-width="4"
                                  />
                                </svg>
                                CIT
                              </div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="7"
                                height="7"
                                viewBox="0 0 7 7"
                                fill="none"
                                class="text-icon"
                              >
                                <path
                                  d="M5.95079 2.84905L1.71079 0.185798C1.61783 0.126925 1.50723 0.0801961 1.38537 0.048307C1.26351 0.0164179 1.1328 0 1.00079 0C0.868781 0 0.738075 0.0164179 0.616216 0.048307C0.494356 0.0801961 0.383755 0.126925 0.290792 0.185798C0.104542 0.303485 0 0.462685 0 0.628626C0 0.794568 0.104542 0.953767 0.290792 1.07145L3.83079 3.29502L0.290792 5.51858C0.104542 5.63627 0 5.79547 0 5.96141C0 6.12735 0.104542 6.28655 0.290792 6.40424C0.384233 6.46245 0.495049 6.50851 0.616886 6.53977C0.738724 6.57103 0.869186 6.58687 1.00079 6.58639C1.1324 6.58687 1.26286 6.57103 1.3847 6.53977C1.50654 6.50851 1.61735 6.46245 1.71079 6.40424L5.95079 3.74099C6.04452 3.68259 6.11891 3.61312 6.16968 3.53658C6.22045 3.46004 6.24659 3.37794 6.24659 3.29502C6.24659 3.2121 6.22045 3.13 6.16968 3.05345C6.11891 2.97691 6.04452 2.90744 5.95079 2.84905Z"
                                  fill="#0ACF83"
                                />
                              </svg>
                            </div>
                            {/* Break time */}
                            <div
                              class="time"
                              style={{
                                width: `calc(${item?.breakTimePercentage}% * 2)`,
                                background: "#6DA4FF",
                              }}
                            >
                              <div class="time-btn2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15"
                                  height="16"
                                  viewBox="0 0 15 16"
                                  fill="none"
                                >
                                  <circle
                                    cx="7.5"
                                    cy="7.92188"
                                    r="5.5"
                                    fill="#FFFEFF"
                                    stroke="#9EC3FF"
                                    stroke-width="4"
                                  />
                                </svg>
                                BR
                              </div>
                            </div>
                            {/* Checkin */}
                            <div
                              class="time"
                              style={{
                                width: `${item?.checkInPercentage}% * 6)`,
                                marginLeft: `calc(${item?.checkInPercentage}% * 0)`,
                                background: `rgba(10, 207, 131, 0.20)`,
                              }}
                            >
                              <div class="time-btn">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15"
                                  height="15"
                                  viewBox="0 0 15 15"
                                  fill="none"
                                >
                                  <path
                                    d="M13 7.5C13 10.5376 10.5376 13 7.5 13C4.46243 13 2 10.5376 2 7.5C2 4.46243 4.46243 2 7.5 2C10.5376 2 13 4.46243 13 7.5Z"
                                    fill="#FFFEFF"
                                    stroke="#2CDE9A"
                                    stroke-width="4"
                                  />
                                </svg>
                                CIT
                              </div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="7"
                                height="7"
                                viewBox="0 0 7 7"
                                fill="none"
                                class="text-icon"
                              >
                                <path
                                  d="M5.95079 2.84905L1.71079 0.185798C1.61783 0.126925 1.50723 0.0801961 1.38537 0.048307C1.26351 0.0164179 1.1328 0 1.00079 0C0.868781 0 0.738075 0.0164179 0.616216 0.048307C0.494356 0.0801961 0.383755 0.126925 0.290792 0.185798C0.104542 0.303485 0 0.462685 0 0.628626C0 0.794568 0.104542 0.953767 0.290792 1.07145L3.83079 3.29502L0.290792 5.51858C0.104542 5.63627 0 5.79547 0 5.96141C0 6.12735 0.104542 6.28655 0.290792 6.40424C0.384233 6.46245 0.495049 6.50851 0.616886 6.53977C0.738724 6.57103 0.869186 6.58687 1.00079 6.58639C1.1324 6.58687 1.26286 6.57103 1.3847 6.53977C1.50654 6.50851 1.61735 6.46245 1.71079 6.40424L5.95079 3.74099C6.04452 3.68259 6.11891 3.61312 6.16968 3.53658C6.22045 3.46004 6.24659 3.37794 6.24659 3.29502C6.24659 3.2121 6.22045 3.13 6.16968 3.05345C6.11891 2.97691 6.04452 2.90744 5.95079 2.84905Z"
                                  fill="#0ACF83"
                                />
                              </svg>
                            </div>

                            {/* Overtime */}
                            <div
                              class="time"
                              style={{
                                width: `calc(${item?.overTimePercentage}% * 4)`,
                                marginLeft: `calc(${item?.overTimePercentage}% * 0)`,
                                background: `rgba(255, 153, 109, 0.20)`,
                              }}
                            >
                              <div class="time-btn3">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15"
                                  height="16"
                                  viewBox="0 0 15 16"
                                  fill="none"
                                >
                                  <path
                                    d="M15 8.30664C15 12.4488 11.6421 15.8066 7.5 15.8066C3.35786 15.8066 0 12.4488 0 8.30664C0 4.16451 3.35786 0.806641 7.5 0.806641C11.6421 0.806641 15 4.16451 15 8.30664Z"
                                    fill="#FFFEFF"
                                  />
                                  <path
                                    d="M13 8.30664C13 11.3442 10.5376 13.8066 7.5 13.8066C4.46243 13.8066 2 11.3442 2 8.30664C2 5.26907 4.46243 2.80664 7.5 2.80664C10.5376 2.80664 13 5.26907 13 8.30664Z"
                                    stroke="#FF996D"
                                    stroke-opacity="0.73"
                                    stroke-width="4"
                                  />
                                </svg>
                                OVT
                              </div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="7"
                                height="7"
                                viewBox="0 0 7 7"
                                fill="none"
                                class="text-icon"
                              >
                                <path
                                  d="M5.95079 2.84905L1.71079 0.185798C1.61783 0.126925 1.50723 0.0801961 1.38537 0.048307C1.26351 0.0164179 1.1328 0 1.00079 0C0.868781 0 0.738075 0.0164179 0.616216 0.048307C0.494356 0.0801961 0.383755 0.126925 0.290792 0.185798C0.104542 0.303485 0 0.462685 0 0.628626C0 0.794568 0.104542 0.953767 0.290792 1.07145L3.83079 3.29502L0.290792 5.51858C0.104542 5.63627 0 5.79547 0 5.96141C0 6.12735 0.104542 6.28655 0.290792 6.40424C0.384233 6.46245 0.495049 6.50851 0.616886 6.53977C0.738724 6.57103 0.869186 6.58687 1.00079 6.58639C1.1324 6.58687 1.26286 6.57103 1.3847 6.53977C1.50654 6.50851 1.61735 6.46245 1.71079 6.40424L5.95079 3.74099C6.04452 3.68259 6.11891 3.61312 6.16968 3.53658C6.22045 3.46004 6.24659 3.37794 6.24659 3.29502C6.24659 3.2121 6.22045 3.13 6.16968 3.05345C6.11891 2.97691 6.04452 2.90744 5.95079 2.84905Z"
                                  fill="#FF996D"
                                />
                              </svg>
                            </div>
                            {/* Checkout time */}
                            <div
                              className="time"
                              style={{
                                width: `calc(${item?.checkOutPercentage}% * 3)`,
                                marginLeft: `calc(${item?.checkOutPercentage}% * 0)`,
                                borderRadius: "22.5px",
                                background: "#FF6D6D",
                              }}
                            >
                              <div className="time-btn4">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15"
                                  height="16"
                                  viewBox="0 0 15 16"
                                  fill="none"
                                >
                                  <circle
                                    cx="7.5"
                                    cy="7.92188"
                                    r="5.5"
                                    fill="#FFFEFF"
                                    stroke="#FF9E9E"
                                    strokeWidth="4"
                                  />
                                </svg>
                                COT
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="exampleModal2"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
          <div class="modal-content">
            <div class="modal-header border-0 mb-0 p-3 pb-0">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body font-20 text-dark px-4">
              <p class="font-18 text-dark  text-center mb-5">
                <i class="fadeIn animated bx bx-arrow-back pe-3"></i> Request
                for going outside the geofence from{" "}
                <span class="text-blue">David (#LA0568)</span>
              </p>
              <div class="row">
                <div class="col-12 mb-3">
                  <label class="form-label">Reason</label>
                  <textarea
                    class="form-control"
                    rows="4"
                    cols="4"
                    placeholder="Need to get glue to fix the roof ceiling of the new construction site ASAP "
                  ></textarea>
                  <div class="mt-4">
                    <button
                      type="button"
                      class="btn btn-success2 px-3 py-3 w-100 mb-3 font-15"
                    >
                      Approve
                    </button>
                    <button
                      type="button"
                      class="btn btn-danger2 px-3 py-3 w-100 mb-3 font-15"
                    >
                      Reject
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TimeLine;
