import http from "../../../../baseURL/httpcommon";

const getTimeSheetData = (
  supervisorId,
  name,
  f_date,
  requestTypeEnum,
  pageNumber,
  pageSize
) => {
  debugger;
  return http.get(
    `/timesheet/list/supervisor?supervisorId=${supervisorId}&Name=${name}&date=${f_date}&requestTypeEnum=${requestTypeEnum}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=timesheetId&sortDir=desc`
  );
};
const getTimeLineData = (
  userId,
  fromDate,
  toDate,
  requestTypeEnum,
  pageNumber,
  pageSize
) => {
  debugger;
  return http.get(
    `/timesheet/worker/timesheet?userId=${userId}&fromDate=${fromDate}&toDate=${toDate}&requestTypeEnum=${requestTypeEnum}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=timesheetId&sortDir=desc`
  );
};
const getTimeLineChart = (workerId, filter) => {
  debugger;
  return http.get(`/api/checkinout/monthly-hours/${workerId}/${filter} `);
};
const getShiftHoursData = (userId, date) => {
  debugger;
  return http.get(
    `/api/checkinout/daily-hours/{workerId}/{date}?userId=${userId}&date=${date}`
  );
};
const getDownloadExcel = (supervisorId, formattedDate) => {
  return http.get(
    `/timesheet/export-to-excel?supervisorId=${supervisorId}&date=${formattedDate}`
  );
};
const TimeSheetServices = {
  getTimeSheetData,
  getTimeLineData,
  getTimeLineChart,
  getShiftHoursData,
  getDownloadExcel,
};

export default TimeSheetServices;
