import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ViewDetails from "./viewDetails";
import { useTranslation } from "react-i18next";
const ViewRequests = () => {
  const location = useLocation();
  const data = location.state.data;
  const { t } = useTranslation();

  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-12 col-xl-12">
          <div className="card radius-10 w-100">
            <div className="card-body px-4">
              <div className="row row-cols-1 row-cols-lg-2 g-3 align-items-center pb-3 mb-3">
                <div className="col-12 col-lg-6 col-xl-6">
                  <h5 className="mb-0 font-18">{t("View Requests")}</h5>
                </div>
                <div className="col-12 col-lg-6 col-xl-6 text-right">
                  <Link
                    to="/dashboard/admin/companies"
                    className="btn btn-outline-secondary btn-blue font-14 ms-2"
                  >
                    {t("Back")}
                  </Link>
                </div>
              </div>
              {data.map((item) => (
                <div className="card radius-15">
                  <div className="card-body border radius-10">
                    <div className="row align-items-center d-flex">
                      <div className="col-12 col-lg-6 col-xl-6">
                        <img src="/assets/images/imgred.png" />
                        <span className="mb-0 font-20 font-weight-500 text-dark px-lg-4 d-xs-block">
                          {item.companyName}
                        </span>
                      </div>
                      <div className="col-12 col-lg-6 col-xl-6 px-2 text-right">
                        <ViewDetails data={item} />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* <div className="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body font-20 p-0 text-dark">
                            
                        </div>
                    </div>
                </div>
            </div> */}
    </>
  );
};

export default ViewRequests;
