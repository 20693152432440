import http from "../../../../baseURL/httpcommon";
import authHeader from "../../../../baseURL/authHeader";

const getAdminDashboard = (id) => {
  console.log("jwt.id", id);
  return http.get(`/dashboard/site/${id}`);
};
const getStaticPlans = (id) => {
  return http.get(`/dashboard/site/${id}`);
};

const getConstructionRequests = () => {
  return http.get(`company/new/request`);
};

const AdminApi = {
  getAdminDashboard,
  getStaticPlans,
  getConstructionRequests,
};

export default AdminApi;
