import http from "../baseURL/httpcommon";
import authHeader from "../baseURL/authHeader";

const signUp = (data) => {
  return http.post("auth/company/signup", data);
};
const pricePlann = (data) => {
  return http.get("subscription/allSubscriptionPlan");
};
const sendMessageApi = (data)=>{
  return http.post("contact/save", data);
}
const companySignUp = {
  signUp,
  pricePlann,
  sendMessageApi
};

export default companySignUp;
