const CompanySubcription = () => {
  return (
    <>
      <div className="card radius-10 p-4 px-3">
        <div className="card-header bg-transparent border-0">
          <div className="row g-3 align-items-center">
            <div className="col-12 col-md-6 col-lg-6">
              <h5 className="mb-0">Subscriptions</h5>
            </div>
            <div className="col-6 col-md-6 col-lg-6 text-right"></div>
          </div>
        </div>
        <div className="card-body">
          <div className="row g-3 align-items-center">
            <div className="col-12 col-md-7 col-lg-7">
              <div className="border p-4 mb-4 border-20">
                <div className="d-flex mb-3 xs-block">
                  <label className="form-label text-dark font-18 xs-block">
                    Startup Plan
                  </label>{" "}
                  <span className="text-gray font-14 ps-lg-3">$8/month</span>
                  <div className="ms-auto xs-block">
                    <span className="mb-0 font-18">$240.00 </span>
                    <span className="text-gray font-14">/month</span>
                  </div>
                </div>
                <div className="d-flex mb-3 xs-block">
                  <label className="text-gray font-14 xs-block">
                    Active Licenses: 5
                  </label>{" "}
                  <span className="text-gray font-14 ms-lg-4">
                    Remaining Licenses: 3
                  </span>
                  <div className="ms-auto xs-block">
                    <span className="text-gray font-14">
                      Remaining Licenses: 3
                    </span>
                  </div>
                </div>
                <div className="d-flex mb-3 mt-lg-5 xs-block">
                  <button
                    type="button"
                    className="btn btn-primary py-1 font-14"
                  >
                    Upgrade Plan
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger py-1 font-14 ms-lg-4"
                  >
                    Cancel Subscription
                  </button>
                  <div className="ms-auto">
                    <span className="text-blue font-14">
                      Monthly renewal: 12 Dec, 2023
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h5 className="mb-0 mt-lg-5">Invoices</h5>
          <div className="table-responsive">
            <table className="table align-middle mb-0">
              <thead className="table-light">
                <tr>
                  <th>Date</th>
                  <th>Description</th>
                  <th>Licenses</th>
                  <th>Invoice Total</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>November 12, 2023</td>
                  <td>Startup Plan</td>
                  <td>17</td>
                  <td>$8.00</td>
                  <td>Paid</td>
                  <td>
                    <a href="#">View Invoice</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanySubcription;
