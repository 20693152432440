import http from "../../../../baseURL/httpcommon";
import authHeader from "../../../../baseURL/authHeader";
import { jwtDecode } from "jwt-decode";

const getCompanyStatics = (id) => {
  console.log("jwt.id", id);
  return http.get(`/dashboard/company/${id}`, {
    headers: {
      ...authHeader(),
    },
  });
};
const getCompanySite = (id) => {
  console.log("jwt.id", id);
  return http.get(`/dashboard/site/${id}`, {
    headers: {
      ...authHeader(),
    },
  });
};
const AllCheckINOUTRequests = (companyId) => {
  return http.get(`api/checkinout/checkin/list/${companyId}`);
};
const getAllWorkforces = (companyId) => {
  return http.get(
    `/workforce/allWorkforce?companyId=${companyId}&pageNumber=0&pageSize=10&sortBy=name&sortDir=asc`
  );
};

const HomeApi = {
  getCompanyStatics,
  getCompanySite,
  AllCheckINOUTRequests,
  getAllWorkforces,
};

export default HomeApi;
