import http from "../../../../baseURL/httpcommon";
import authHeader from "../../../../baseURL/authHeader";

const getTasksList = (supervisorId, status, pageNumber, pageSize) => {
  return http.get(
    `/task/allTask?supervisorId=${supervisorId}&status=${status}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortDir=desc`
  );
};

//create task
const postTaskCreate = (taskData) => {
  return http.post(`/task/create`, taskData, {
    headers: {
      ...authHeader(),
    },
  });
};

const handleDeleteTask = (taskId) => {
  return http.delete(`/task/${taskId}`, {
    headers: {
      ...authHeader(),
    },
  });
};
const handleEditTask = (taskId, editData) => {
  return http.put(`/task/update/${taskId}`, editData, {
    headers: {
      ...authHeader(),
    },
  });
};
const getAssignTaskList = (supervisorId, status) => {
  return http.get(
    `/task/allTask?supervisorId=${supervisorId}&status=${status}`
  );
};
const postUnassignTask = (taskId, userId, status) => {
  return http.post(`/task/assign/unassign/${taskId}/${userId}/${status}`, {
    headers: {
      ...authHeader(),
    },
  });
};
const getAllTaskWorkers = (supervisorId) => {
  return http.get(`/workforce/worker/list/${supervisorId}`);
};
const postAssignTask = (taskId, userId, status) => {
  debugger;
  return http.post(`/task/assign/unassign/${taskId}/${userId}/${status}`, {
    headers: {
      ...authHeader(),
    },
  });
};
const TasksServices = {
  getTasksList,
  getAssignTaskList,
  postTaskCreate,
  handleDeleteTask,
  handleEditTask,
  postUnassignTask,
  getAllTaskWorkers,
  postAssignTask,
};

export default TasksServices;
